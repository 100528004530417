import React,{useContext,useEffect, useState, useRef} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest ,deleteRequest} from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon,EditRowIcon,DeleteIcon, EditIcon, PaperClipIcon, ActivateIcon, DeactivateIcon, CollapseTreeIcon, ExpandTreeIcon, BranchTreeIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import AuthContext from '../../components/AuthContext';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DateTime } from "luxon";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css';
import UtilContext from '../../components/UtilContext';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import ReactDOMServer from 'react-dom/server';
import { MultiSelect } from 'primereact/multiselect';

export default function TrainingRecordDetail({employee, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);
    const {user} = useContext(AuthContext);

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const submitRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const [markUnmark, setMarkUnmarked] = useState(true);
    const [lecturers, setLecturers] = useState(state.lecturers);
    const [companyContacts, setCompanyContacts] = useState(state.company_contacts);
    const [machines, setMachines] = useState(state.machines);
    const [selectedRecord,setSelectedRecord] = useState(null);

    const [columnsVisible, setColumnsVisible] = useState({'training.department.name':true,custom_training_name:true,custom_last_training_date:true,custom_next_training_date:true,
        custom_state:true,custom_deactivation_date:true,description:true,protocol:true, custom_company_name:true,custom_machine_name:true,custom_lecturer_name:true,custom_company_contact_name:true
    });
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [formEmployee,setFormEmployee] = useState(employee);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit training"))?false:true);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);


    const [visibleRecord,setVisibleRecord] = useState(false);
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [images,setImages] = useState('');
    const [updatefileList, setUpdateFileList] = useState([]);
    const updateFileListRef = useRef(null);
    const [viewedImage,setViewedImage] = useState('');

    const handleUpdateFileChange = (event) => {
        setUpdateFileList(event.target.files);
    };

    const onSelectRow = (data, rows) => {
        setDeleteIcon(true);
        setEditIcon(true);
        formikUpdate.resetForm();
        if(data.length>0){
            let children = 0;
            let parent = 0;
            data.forEach(obj => {
                if(obj?.training_card_id>0){
                    children++;
                }
                else parent++;
            });
            if(children>0 && parent==0){
                if(user.permissions.includes("delete training"))setDeleteIcon(false);
                if(data?.length==1 && user.permissions.includes("edit training")){
                    setSelectedRecord(data[0]);
                    console.log(rows[0].getTreeParent().getData()?.training_id);
                    formikUpdate.setFieldValue("id",data[0]?.id);
                    //formikUpdate.setFieldValue("training_id",state.training_employees.filter(card => card.id==data[0]?.training_card_id)[0]?.training_id);
                    formikUpdate.setFieldValue("training_id",rows[0].getTreeParent().getData()?.training_id);
                    formikUpdate.setFieldValue("last_training_date",data[0]?.last_training_date);
                    formikUpdate.setFieldValue("description",data[0]?.description);
                    formikUpdate.setFieldValue("company_id",data[0]?.company_id);
                    formikUpdate.setFieldValue("machines",data[0]?.machines.map((machine) => ({name:machine.name,id:machine.id})));
                    formikUpdate.setFieldValue("lecturer_id",data[0]?.company_contact?.lecturer_id);
                    formikUpdate.setFieldValue("company_contact_id",data[0]?.company_contact?.id);
                    const my_machines = [];
                    state.company_contacts.filter(contact => contact.company_id == data[0]?.company_id)?.forEach(contact => {
                        contact?.machines?.map(machine => my_machines.push(machine.id));
                    });        
                    setMachines(state.machines.filter(machine => my_machines.includes(machine.id)));
                    let my_contacts = state.company_contacts.filter(contact => contact.company_id == data[0]?.company_id && contact.machines.filter(machine => data[0]?.machines.map(mach => mach.id).includes(machine.id))?.length > 0)?.map(contact => contact.lecturer_id);
                    setLecturers(state.lecturers.filter(lecturer => my_contacts.includes(lecturer.id)));
                    setCompanyContacts(state.company_contacts.filter(contact => contact.company_id == data[0]?.company_id && contact.machines.filter(machine => data[0]?.machines.map(mach => mach.id).includes(machine.id))?.length > 0 && contact.lecturer_id == data[0]?.company_contact?.lecturer_id));
                    setEditIcon(false);
                }
            }
        }
    };

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    function changeCompany(company_id){
        const machines = [];
        state.company_contacts.filter(contact => contact.company_id == company_id)?.forEach(contact => {
            contact?.machines?.map(machine => machines.push(machine.id));
        });
        setMachines(state.machines.filter(machine => machines.includes(machine.id)));
        formikUpdate.setFieldValue('company_id',company_id);
        formikUpdate.setFieldValue('machines',[]);
        formikUpdate.setFieldValue('lecturer_id','');
        formikUpdate.setFieldValue('company_contact_id','');
        formikUpdate.setFieldValue("employees",[]);
    }

    function changeMachine(machines){
        let machines_ids = machines.map(mach => mach.id);
        let contacts = state.company_contacts.filter(contact => contact.company_id == formikUpdate.values?.company_id && machines_ids.every(obj => contact.machines?.map(machin => machin.id).includes(obj)))?.map(contact => contact.lecturer_id);
        setLecturers(state.lecturers.filter(lecturer => contacts.includes(lecturer.id)));
        formikUpdate.setFieldValue("machines",machines);
        formikUpdate.setFieldValue('lecturer_id','');
        formikUpdate.setFieldValue('company_contact_id','');
        formikUpdate.setFieldValue("employees",[]);
    }
        
    function changeLecturer(lecturer_id){
        setCompanyContacts(state.company_contacts.filter(contact => contact.company_id == formikUpdate.values?.company_id && formikUpdate.values?.machines.map(machin => machin.id).every(obj => contact.machines?.map(machin => machin.id).includes(obj)) && contact.lecturer_id == lecturer_id));
        formikUpdate.setFieldValue('lecturer_id',lecturer_id);
        formikUpdate.setFieldValue('company_contact_id','');
        formikUpdate.setFieldValue("employees",[]); 
    }

    function getRecordFiles(id){
        getRequest(`/api/training_records/protocols/${id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

    useEffect(() => {
            // Update the document title using the browser API
        if(selectedRecord!=null){
            console.log(selectedRecord);
            console.log(formikUpdate.values);
            //getRecordFiles(selectedRecord.id);
        }
    },[selectedRecord]);

    const formikUpdate = useFormik({
        initialValues: selectedRecord ? 
            {id: selectedRecord?.id,training_id:state.training_employees.filter(card => card.id==selectedRecord?.training_card_id)[0]?.training_id,
            last_training_date:selectedRecord?.last_training_date,description:selectedRecord?.description,
            company_id:selectedRecord?.company_id,machines:selectedRecord?.machines.map((machine) => ({name:machine.name,id:machine.id})),
            lecturer_id:selectedRecord?.company_contact?.lecturer_id,company_contact_id:selectedRecord?.company_contact_id} : 
            {training_id: '',last_training_date: '',description:'',company_id:'',machines:[],lecturer_id:'',company_contact_id:''},
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                if(value=="machines"){
                    formData.append("machines", values.machines.map(machine => machine.id));
                }
                else formData.append(value, values[value] != null ? values[value] : "");
            }
            for(let i = 0;i<updatefileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',updatefileList[i]);
            }
            postRequest(`/api/training_records/update/${formikUpdate.values.id}`, formData,true)
            .then((response) => {
                getRequest("/api/training_cards").then((response) => {
                    dispatch({type:'load_training_cards',newTrainingCards:response.data});
                    setVisibleRecord(false);
                    formikUpdate.resetForm();
                    if(updatefileList.length){
                        setUpdateFileList([]);
                        updateFileListRef.current.value = null;
                    }
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                getRequest("/api/training_employees").then((response) => {
                    dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    function hideEditRecord(){
        formikUpdate.resetForm();
        setVisibleRecord(false);
    }

    function getIndex(employee_id) {
        return state.training_cards.findIndex(obj => obj.id === employee_id);
    }

    async function scrollToFirst(){
        let my_training_card = state.training_cards[0];
        setFormEmployee(my_training_card);
    }
    
    async function scrollUp(){
        let id = getIndex(formEmployee.id);
        if(id-1>=0){
            setFormEmployee(state.training_cards[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formEmployee.id);
        if(id+1<state.training_cards.length){
            setFormEmployee(state.training_cards[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.training_cards.length-1;
        let my_error_record = state.training_cards[last];
        setFormEmployee(my_error_record);
    }

    function TrainingName(props) {
        const rowData = props.cell.getRow().getData();
        var parent = props.cell.getRow()?.getTreeParent();
        if(rowData?._children) return <div className='training_name'>{rowData.training.name}</div>;
        if(parent){
            let parent_array = parent._row.data._children;
            for(let i = 0;i<parent_array.length;i++){
                if(parent_array[i].id == rowData.id){
                    if(i==parent_array.length-1)return <div className='training_name'>Prvotné školenie</div>;
                    else return <div className='training_name'>{parent_array.length-1-i}. Opakované školenie</div>;
                }
            }
            return <div className='training_name'>{parent._row.data.training.name}</div>;
        }
        return <span className='text-red-500'>Neškolený</span>;
    }  

    function DeactivationDate(props) {
        const rowData = props.cell.getRow().getData();
        return rowData?.deactivation_date ? DateTime.fromISO(rowData.deactivation_date).toFormat("dd.MM.yyyy") : "";
      }

      function LastTrainingDate(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?.last_training_date) return DateTime.fromISO(rowData.last_training_date).toFormat("dd.MM.yyyy");
        if(rowData?._children.length > 0) return DateTime.fromISO(rowData._children[0].last_training_date).toFormat("dd.MM.yyyy");
        if(new Date(rowData?.initial_date) > new Date())return <span className='text-blue-600'>Naplánované</span>;
        return <span className='text-red-500'>Neškolený</span>;
      }

      function NextTrainingDate(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?.next_training_date) return DateTime.fromISO(rowData.next_training_date).toFormat("dd.MM.yyyy");
        if(rowData?._children.length > 0) return DateTime.fromISO(rowData._children[0].next_training_date).toFormat("dd.MM.yyyy");
        if(new Date(rowData?.initial_date) > new Date())return <span className='text-blue-600'>Naplánované</span>;
        return <span className='text-red-500'>Neškolený</span>;
      }

    function dateHeaderFilter(headerValue, rowValue, rowData, filterParams){
          if(rowData?._children?.length==0){
            if(headerValue!=""){
              if(new Date(rowData?.initial_date) > new Date())return "Naplánované".toLowerCase().includes(headerValue.toLowerCase());
              return "Neškolený".toLowerCase().includes(headerValue.toLowerCase());
            }
          }
          if(filterParams.name=="last_training_date"){
            return DateTime.fromISO(rowData?._children[0]?.last_training_date).toFormat("dd.MM.yyyy").includes(headerValue);
          }
          else if(filterParams.name=="next_training_date"){
            return DateTime.fromISO(rowData?._children[0]?.next_training_date).toFormat("dd.MM.yyyy").includes(headerValue);
          }
        }

      function StateFormatter(props){
        const rowData = props.cell.getRow().getData();
        var parent = props.cell.getRow()?.getTreeParent();
        if(parent){
            let parent_array = parent._row.data._children;
            for(let i = 0;i<parent_array.length;i++){
                if(parent_array[i].id == rowData.id){
                    if(i==0){
                        if(parent._row.data?.deactivation_date!=null && parent._row.data?.deactivation_date!=""){
                            return "DEA";
                        }
                        else if(new Date(parent_array[i]?.next_training_date) < new Date()){
                            return "NOK";
                        }
                        else return "OK";
                    }
                    else return "OK"; 
                }
            }
        }
        if(rowData?.deactivation_date!=null && rowData?.deactivation_date!="")return "DEA";
        if(rowData?._children?.length > 0){           
            if(new Date(rowData?._children[0]?.next_training_date) < new Date()){
                return "NOK";
            }
            return "OK"
        }
        else if(new Date(rowData?.initial_date) > new Date()){
            return "OK";
        }
        else return "NOK";
      }

    const accept = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
            deleteRequest(`/api/training_records/${element.id}`)
            .then((response) => {
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
            }).catch((reason) => {
                alert(reason);
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
            });
        });
        getRequest("/api/training_cards").then((response) => {
            dispatch({type:'load_training_cards',newTrainingCards:response.data});
        });
        getRequest("/api/training_employees").then((response) => {
            dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
        });
    }
        
        const reject = () => {
            let mytoast = toast.current ? toast : ownToast;
            mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
        }
    
        const confirm = () => {
            confirmDialog({
                message: 'Prajete si vymazať tento záznam?',
                header: 'Potvrdenie vymazania',
                icon: 'pi pi-info-circle',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Áno',
                rejectLabel: 'Nie',
                accept,
                reject
            });
        };

    const acceptFile = (id) => {
        getRequest(`/api/training_records/deleteProtocol/${id}`)
        .then((response) => {
            toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
            getRecordFiles(selectedRecord?.id);
        }).catch((reason) => {
            alert(reason);
            toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
        })
    };

    function deleteFiles(img){
        confirmDialog({
            message: 'Prajete si vymazať tento súbor?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => acceptFile(img.id)
        });
    }

    function stateHeaderFilter(headerValue, rowValue, rowData, filterParams){
        let state = "";
        if(rowData?._children?.length > 0){           
            if(new Date(rowData?._children[0]?.next_training_date) < new Date()){
                state="NOK";
            }
            else state="OK"
        }
        else if(new Date(rowData?.initial_date) > new Date()){
            state="OK";
        }
        else state="NOK"
        if(rowData?.deactivation_date!=null && rowData?.deactivation_date!="")state="DEA";
        return headerValue.toUpperCase() == state.toUpperCase();
      }

    function viewFile(file_id){
        getRequest(`/api/training_records/protocol/${file_id}`).then((response) => {
            console.log(response);
            setVisibleFiles(true);
            setViewedImage(response.data);
        });
    }

    function Protocol(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?._children?.length > 0){   
            if(rowData?._children[0]?.files)return <div className='flex flex-row'>{rowData?._children[0]?.files.map((img) => (<a style={{color:'blue',cursor:'pointer',marginBottom:'2px',width: '24px',display: 'block'}} onClick={() => {viewFile(img.id);}}><PaperClipIcon/></a>))}</div>
        }
        return rowData.files ? <div className='flex flex-row'>{rowData.files.map((img) => (<a style={{color:'blue',cursor:'pointer',marginBottom:'2px',width: '24px',display: 'block'}} onClick={() => {viewFile(img.id);}}><PaperClipIcon/></a>))}</div> : null;
    }

    function CompanyName(props) {
        const rowData = props.cell.getRow().getData();
        /*if(rowData?._children?.length > 0){
            return rowData?._children[0]?.company?.name;
        }*/
        return rowData?.company?.name;
      }

      function MachineName(props) {
        const rowData = props.cell.getRow().getData();
        /*if(rowData?._children?.length > 0){
            return rowData?._children[0]?.machines?.map(machine => machine?.name).join('/');
        }*/
        return rowData?.machines?.map(machine => machine?.name).join('/');
      }

      function LecturerName(props) {
        const rowData = props.cell.getRow().getData();
        /*if(rowData?._children?.length > 0){
            return rowData?._children[0]?.company_contact?.lecturer?.name;
        }*/
        return rowData?.company_contact?.lecturer?.name;
      }

      function CompanyContactName(props) {
        const rowData = props.cell.getRow().getData();
        /*if(rowData?._children?.length > 0){
            if(rowData?._children[0]?.company_contact?.employee_id) return rowData?._children[0]?.company_contact?.employee?.name;
            return rowData?._children[0]?.company_contact?.name;
        }*/
        if(rowData?.company_contact?.employee_id)return rowData?.company_contact?.employee?.name;
        return rowData?.company_contact?.name;
      }
      

    const [columns,setColumns] = useState([
        { title: '', field: 'id',visible:false},
        {
            formatter:"rowSelection", titleFormatter:"rowSelection",titleFormatterParams:{rowRange:"active"},hozAlign:"center",width: 20,headerSort:false,resizable:false,
            cellClick: function(e, cell) {
              cell.getRow().toggleSelect();
              console.log(cell.getRow(), cell.getRow().getTreeParent());
            }
        },
        { title: 'Názov školenia', field: 'custom_training_name',minWidth: 200,widthGrow:3,headerFilter:"input",formatter: reactFormatter(<TrainingName/>),resizable:false},
        { title: 'Oddelenie školenia', field: 'training.department.name',headerFilter:"input",minWidth: 200,widthGrow:2,resizable:false},
        { title: 'Posledné školenie', field: 'custom_last_training_date',headerFilter:"input",headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"last_training_date"},minWidth: 200,widthGrow:1,formatter: reactFormatter(<LastTrainingDate/>),resizable:false},
        { title: 'Nasled. školenie', field: 'custom_next_training_date',headerFilter:"input",headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"next_training_date"},headerFilter:"input",minWidth: 200,widthGrow:1,formatter: reactFormatter(<NextTrainingDate/>),resizable:false},
        { title: 'Stav', field: 'custom_state',headerFilter:"list", headerFilterParams:{values:{"OK":"OK","NOK":"NOK","DEA":"DEA"}, clearable:true},headerFilterFunc:stateHeaderFilter,minWidth: 200,widthGrow:1,formatter: reactFormatter(<StateFormatter/>),resizable:false},
        { title: 'Deaktivácia', field: 'custom_deactivation_date',headerFilter:"input",headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"deactivation_date"},headerFilter:"input",minWidth: 150,widthGrow:1,formatter: reactFormatter(<DeactivationDate/>),resizable:false},
        { title: 'Popis', field: 'description',headerFilter:"input",minWidth: 200,widthGrow:2,resizable:false},
        { title: 'Protokol', field: 'protocol',headerFilter:"input",width: 200,widthGrow:2,resizable:false,formatter: reactFormatter(<Protocol/>)},
        { title: 'Škol. firma', field: 'custom_company_name',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<CompanyName/>),},
        { title: 'Stroj', field: 'custom_machine_name',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<MachineName/>),},
        { title: 'Lektor', field: 'custom_lecturer_name',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<LecturerName/>),},
        { title: 'Školiteľ', field: 'custom_company_contact_name',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<CompanyContactName/>),},
    ]);

    function setMask(item,value){
        const columnVisible = {...columnsVisible};
       columnVisible[item] = value;
       console.log(columnVisible);
        for(const val in columnVisible) {
          if(columnVisible[val]==false){
            ref.current.hideColumn(`${val}`);
          }
          else{
            ref.current.showColumn(`${val}`);
          }
        }
        setColumnsVisible(columnVisible);
        setTimeout(() => {
            ref.current.redraw(true);
        }, 0);
      }

      const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
            {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("TrainingRecordDetail","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("TrainingRecordDetail","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nast. triedenie',
                icon: 'pi pi-sort-alt',
                command: () => {setUserMaskFilter("TrainingRecordDetail","set_sorters",null,null,null,ref.current.getSorters());OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
                key: '0_4',
                label: 'Zrušiť triedenie',
                icon: 'pi pi-sort-alt-slash',
                command: () => {setUserMaskFilter("TrainingRecordDetail","set_sorters",null,null,null,[]);ref.current.setSort([]);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
                key: '0_5',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_6',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];

    function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","training_cards");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }
    
      function changeMarkings(value){
        const columnVisible = {...columnsVisible};
        for(let val in columnVisible) {
        if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor"|| columnVisible[val]!=="end_of_validity"){
            columnVisible[val]=value;
            ref.current.hideColumn(`${val}`);
        }
        };
        setColumnsVisible(columnVisible);
        setTimeout(() => {
            ref.current.redraw(true);
        }, 0);
      }


    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <IntersectionObserverWrapper>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => {getRecordFiles(selectedRecord?.id);setVisibleRecord(true);}}><EditIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" disabled onClick={() => openFavoriteDialog("Upraviť kalibráciu meradla","TrainingRecordDetail")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="price"><button className="disabled:opacity-25" disabled onClick={() => setFastView("TrainingRecordDetail",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
        </div>
        <Splitter className='h-[calc(100vh_-_177px)]' layout="vertical" pt={{ gutter: { className: 'bg-[#333] h-8' },gutterHandler: { className: 'bg-secondary ' } }}>
    <SplitterPanel className="flex align-items-center justify-content-center overflow-y-auto " minSize={10} size={10}>
        <div className="w-full relative flex flex-col overflow-x">
          <div className='xl:grid grid-cols-2 gap-1 mb-2'>
          <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ŠKOLENIA ZAMESTNANCA
                </h1>
                <label className="block text-md font-semibold text-gray-800">Por.č.: {formEmployee.id}</label>
                <div className="mt-6">
                    <div className="md:grid grid-cols-2 gap-2 mb-2">
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Meno zamestnanca
                        </label>
                        <input
                            name='name'
                            type="text"
                            readOnly value={formEmployee.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="defined_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Priradené ID
                        </label>
                        <input
                            name='defined_id'
                            type="number"
                            readOnly value={formEmployee.defined_id}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                </div>  
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <div>
                    <div className="md:grid grid-cols-2 gap-2 mb-2">
                      <div>
                        <label
                            htmlFor="date_of_entry"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum príchodu
                        </label>
                        <input
                            name='date_of_entry'
                            type="text"
                            readonly value={formEmployee.date_of_entry ? DateTime.fromISO(formEmployee.date_of_entry).toFormat("dd.MM.yyyy") : ""}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="date_of_departure"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum odchodu
                        </label>
                        <input
                            name='date_of_departure'
                            type="text"
                            readonly value={formEmployee.date_of_departure ? DateTime.fromISO(formEmployee.date_of_departure).toFormat("dd.MM.yyyy") : ""}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-2 mb-2">
                        <div>
                        <label
                            htmlFor="department_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie
                        </label>
                        <select 
                        name='department_id'
                        disabled value={formEmployee.department_id}
                        className='block w-full h-[42px] px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                          ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Popis zamestnanca
                        </label>
                        <input
                            name='description'
                            type="text"
                            readOnly value={formEmployee.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                </div>  
            </div>
            </div> 
            </div>
             </SplitterPanel>
    <SplitterPanel className="flex flex-col align-items-center justify-content-center w-full overflow-y-auto" size={40} style={{minHeight: '110px'}}>
    <ReactTabulator
      data={state.training_cards.filter((card) => card.id == formEmployee.id)[0].training_card} 
      onRef={(r) => (ref.current = r.current)}
      className='h-full w-full' //176px h-[calc(100vh_-_176px)]
      columns={columns}
      events={{
        rowSelectionChanged: onSelectRow,
        tableBuilt: () => {
            let mask = state.user_mask_filters.filter(mask => mask.page=="TrainingRecordList");
              if(mask.length>0){
                let attributes = [];
                try {
                  attributes = JSON.parse(mask[0].attributes)
                } catch (e) {
                  attributes = []
                }
                if(attributes?.length>1)ref.current.setColumnLayout(attributes);
                let filters = [];
                try {
                  filters = JSON.parse(mask[0].filters);
                } catch (e) {
                  filters = []
                }
                filters.forEach(filter => {
                  ref.current.setHeaderFilterValue(filter.field,filter.value);
                });
                let sorters = [];
                  try {
                    sorters = JSON.parse(mask[0]?.sorters);
                  } catch (e) {
                    sorters = []
                  }
                  if(sorters?.length>0)ref.current.setSort(sorters);
                let json_columns;
                try {
                  json_columns = JSON.parse(mask[0].columns);
                  setColumnsVisible(json_columns);
                } catch (e) {
                  json_columns = []
                }
                for(let column in json_columns){
                  if(json_columns[column]==false)ref.current.hideColumn(column);
                  else ref.current.showColumn(column);
                }
              }
          },
          columnMoved:() => {
            setUserMaskFilter("TrainingRecordDetail","column_move",null,null,ref.current.getColumnLayout());
          }
      }}
        options={{renderHorizontal:"virtual",rowHeight:30,movableColumns: true,
        dataTree:true,dataTreeStartExpanded:false,dataTreeFilter:false,dataTreeElementColumn: "custom_training_name",
        dataTreeCollapseElement:ReactDOMServer.renderToStaticMarkup(<CollapseTreeIcon/>),
        dataTreeExpandElement:ReactDOMServer.renderToStaticMarkup(<ExpandTreeIcon/>),
        dataTreeBranchElement:ReactDOMServer.renderToStaticMarkup(<BranchTreeIcon/>),
        printAsHtml:true,printRowRange:"active",printCopyStyle:true,
        /*groupBy:"training.department.name",
        groupHeader:function(value, count, data, group){
            //value - the value all members of this group share
            //count - the number of rows in this group
            //data - an array of all the row data objects in this group
            //group - the group component for the group
            if(value=="----------")return "Všeobecné <span style='color:#666; margin-left:10px;'>(" + count + ")</span>";
            return value + "<span style='color:#666; margin-left:10px;'>(" + count + ")</span>";
        },*/
        printHeader:function(){
            return ReactDOMServer.renderToStaticMarkup(<div className="grid grid-cols-2 gap-1">
                <div className="flex justify-start items-right">
                    <img src={require('./../../assets/img/logo-legro.png')} style={{ height: '5rem', width: 'auto'}} alt="Legro Logo"/>
                </div>
                <div className="mt-[10px] flex justify-end items-center">
                <h1 style={{fontSize: '1.875rem',lineHeight: '2.25rem',textAlign: 'left'}}>
                    ZOZNAM ŠKOLENÍ
                </h1>
              </div>
              <div className="mt-6">
                  <div className="md:grid grid-cols-2 gap-1 mb-2">
                    <div className='mb-2'>
                      <label
                          htmlFor="name"
                          className="block text-left text-lg font-semibold text-gray-800"
                      >
                          Meno zamestnanca
                      </label>
                      <input
                          name='name'
                          type="text"
                          value={formEmployee.name}
                          style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                          className="border rounded-md"
                      />
                      </div>
                      <div className='mb-2'>
                      <label
                          htmlFor="defined_id"
                          className="block text-left text-lg font-semibold text-gray-800"
                      >
                          Priradené ID
                      </label>
                      <input
                          name='defined_id'
                          type="number"
                          value={formEmployee.defined_id}
                          style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                          className="border rounded-md"                  />
                      </div>
                      <div>
                      <label
                          htmlFor="department_id"
                          className="block text-left text-lg font-semibold text-gray-800"
                      >
                          Oddelenie
                      </label>
                      <select 
                      name='department_id'
                      value={formEmployee.department_id}
                      style={{fontSize: '1rem',width:'100%',height:'42px',padding:'4px 4px 4px 4px',}}
                       className="border rounded-md">
                        <option value="">Vyber oddelenie</option>
                        {state.departments.map((department) => (
                          <option key={department.id} value={department.id}>{department.name}</option>
                        ))}
                      </select>
                      </div>
                  </div>
              </div>
              <div className="mt-6">
                  <div className="md:grid grid-cols-2 gap-4 mb-2">
                    <div className='mb-2'>
                      <label
                          htmlFor="date_of_entry"
                          className="block text-left text-lg font-semibold text-gray-800"
                      >
                          Dátum príchodu
                      </label>
                      <input
                          name='date_of_entry'
                          type="text"
                          value={formEmployee.date_of_entry ? DateTime.fromISO(formEmployee.date_of_entry).toFormat("dd.MM.yyyy") : ""}
                          style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                          className="border rounded-md"
                      />
                      </div>
                      <div>
                      <label
                          htmlFor="date_of_departure"
                          className="block text-left text-lg font-semibold text-gray-800"
                      >
                          Dátum odchodu
                      </label>
                      <input
                          name='date_of_departure'
                          type="text"
                          value={formEmployee.date_of_departure ? DateTime.fromISO(formEmployee.date_of_departure).toFormat("dd.MM.yyyy") : ""}
                          style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                          className="border rounded-md"                  />
                      </div>
                  </div>
                  <div className="md:grid grid-cols-2 gap-4 mb-2">
                      <div>
                      <label
                          htmlFor="description"
                          className="block text-left text-lg font-semibold text-gray-800"
                      >
                          Popis zamestnanca
                      </label>
                      <input
                          name='description'
                          type="text"
                          readOnly value={formEmployee.description}
                          style={{fontSize: '1rem',width:'100%',padding:'2px 4px 2px 4px',}}
                          className="border rounded-md"
                      />
                      </div>
                  </div>
              </div>
              </div>);
        }     
      }}/>
    </SplitterPanel>
    </Splitter> 
    <Dialog header="Upraviť záznam školenia" visible={visibleRecord} style={{ width: '40vw' }} onHide={() => hideEditRecord()}>
            <form onSubmit={formikUpdate.handleSubmit}>
                    <div>
                        <label
                            htmlFor="training_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Názov školenia
                        </label>
                        <select
                        name='training_id'
                        disabled value={formikUpdate.values?.training_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber školenie</option>
                        {state.training_types.map(training => (
                            <option key={training.id} value={training.id}>{training.name}</option>
                        ))}
                        </select>
                    </div>   
                    <div>
                        <label
                            htmlFor="last_training_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum školenia
                        </label>
                        <input
                            name='last_training_date'
                            type="date"
                            required
                            onChange={formikUpdate.handleChange} value={formikUpdate.values?.last_training_date}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div>
                    <label
                        htmlFor="description"
                        className="block text-md font-semibold text-gray-800"
                    >
                        POZNÁMKA
                    </label>
                    <input
                        name='description'
                        type="text"
                        onChange={formikUpdate.handleChange} value={formikUpdate.values?.description}
                        className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    </div>
                    <div>
                        <label
                            htmlFor="company_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Škol. firma
                        </label>
                        <select required
                        name='company_id'
                        onChange={(e) => changeCompany(e.target.value)} value={formikUpdate.values?.company_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber škol.firmu</option>
                        {state.training_companies.map((training_company) => (
                            <option key={training_company.id} value={training_company.id}>{training_company.name}</option>
                        ))}
                        </select>
                    </div> 
                    {(formikUpdate.values?.company_id!="" && formikUpdate.values?.company_id!=null) && <><div>
                        <label
                            htmlFor="machines"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Stroj*
                        </label>
                        <MultiSelect value={formikUpdate.values.machines} onChange={(e) => changeMachine(e.value)}
                            options={machines.map((machine) => 
                                ({name:machine.name,id:machine.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber stroj/e" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        {formikUpdate.errors?.machines ? <span style={{color: "red"}}>{formikUpdate.errors?.machines}</span> : ""} 
                    </div>
                    {(formikUpdate.values?.machines?.length > 0) && <><div>
                        <label
                            htmlFor="lecturer_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Lektor
                        </label>
                        <select required
                        name='lecturer_id'
                        onChange={(e) => changeLecturer(e.target.value)} value={formikUpdate.values?.lecturer_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber lektora</option>
                        {lecturers.map((lecturer) => (
                            <option key={lecturer.id} value={lecturer.id}>{lecturer.name}</option>
                        ))}
                        </select>
                    </div>
                    {(formikUpdate.values?.lecturer_id!="" && formikUpdate.values?.lecturer_id!=null) && <div>
                        <label
                            htmlFor="company_contact_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Školiteľ
                        </label>
                        <select
                        name='company_contact_id'
                        onChange={formikUpdate.handleChange} value={formikUpdate.values?.company_contact_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber školiteľa</option>
                        {companyContacts.map((contact) => (
                            <option key={contact.id} value={contact.id}>{contact?.employee?.name || contact?.name}</option>
                        ))}
                        </select>
                    </div>}</>}</>}
                    <div>
                        <label
                            htmlFor="gauge_category"
                            className="block text-md font-semibold text-gray-800"
                        >
                            PROTOKOL
                        </label>
                        <input
                            name='files' 
                            ref={updateFileListRef}
                            onChange={handleUpdateFileChange}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            type='file'
                            multiple="multiple"
                            id='file'/>
                    </div> 
                    {images!=='' && images.map((img) => ( <div className='flex flex-row p-2'>
                        <div onClick={() => setVisibleFiles(true)}>
                            <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                        </div>
                            <div className='flex flex-col ml-4'>
                            <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                            <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                            </div>
                        </div>))}
                    <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                </form>
            </Dialog>
      <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
        <iframe src={viewedImage.url} className='w-full h-full'></iframe>
        </Dialog>
            <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<1) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("TrainingRecordDetail","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                          > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
            </div>
            </> 
            );
} 
