import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon,DeleteIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export default function MachineAdd({machine, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);

    const submitRef = useRef(null);
    const ownToast = useRef(null);

    const [formMachine,setFormMachine] = useState(machine);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add maintenance"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit maintenance"))?false:true);


    function addNewMachine(){
        dispatch({type:'create_new_tab',name:'Nový stroj',page:'MachineAdd',props:{machine: null,type:'add'},mask:'glo014'})
    }

    function copyMachine(){
        dispatch({type:'create_new_tab',name:'Nový stroj',page:'MachineAdd',props:{gauge: formik.values,type:'copy'},mask:'glo014'})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formMachine==null?{
            name: '',machine_type_id:'',type:'',serial_number:'',fabrication_year:'',last_overhaul:'',
            description:'',manufacturer:'',manufacturer_address:'',manufacturer_tel_number:'',manufacturer_fax:'',manufacturer_email:'',manufacturer_web:'',activity:'Aktívny',
        }:formMachine,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]!=null ? values[value] : "");
            }
            if(formType=="add"){
                postRequest('/api/machines', formData,true)
                .then((response_first) => {
                    console.log(response_first);
                    getRequest("/api/machines").then((response) => {
                        dispatch({type:'load_machines',newMachines:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/machines/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/machines").then((response) => {
                        dispatch({type:'load_machines',newMachines:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(machine_id) {
        return state.machines.findIndex(obj => obj.id === machine_id);
    }

    async function scrollToFirst(){
        let my_machine = state.machines[0];
        setFormMachine(my_machine);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormMachine(state.machines[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.machines.length){
            setFormMachine(state.machines[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.machines.length-1;
        let my_machine = state.machines[last];
        setFormMachine(my_machine);
    }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewMachine()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyMachine()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => openFavoriteDialog("Nový stroj","MachineAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><button onClick={() => setFastView("MachineAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col min-h-[calc(100vh-177px)] overflow-x">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   KARTA STROJA
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV STROJA
                        </label>
                        <input
                            name='name' required
                            type="text"
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="machine_type_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            DRUH STROJA
                        </label>
                            <select required
                            name='machine_type_id'
                            onChange={formik.handleChange} value={formik.values.machine_type_id}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="">Vyber druh stroja</option>
                            {state.machine_types.map((machine_type) => (
                            <option key={machine_type.id} value={machine_type.id}>{machine_type.name}</option>
                          ))}
                            </select>
                        </div>
                      <div>
                        <label
                            htmlFor="type"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TYP STROJA
                        </label>
                        <input
                            name='type' required
                            type="text"
                            onChange={formik.handleChange} value={formik.values.type}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="serial_number"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            VÝROBNÉ ČÍSLO
                        </label>
                        <input
                            name='serial_number' required
                            type="text"
                            onChange={formik.handleChange} value={formik.values.serial_number}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="fabrication_year"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ROK VÝROBY
                        </label>
                        <input
                            name='fabrication_year' required
                            type="number"
                            onChange={formik.handleChange} value={formik.values.fabrication_year}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="last_overhaul"
                            className="block text-md font-semibold text-gray-800"
                        >
                             POSLEDNÁ GENERÁLKA
                        </label>
                        <input 
                            name='last_overhaul'
                            type="date"
                            onChange={formik.handleChange} value={formik.values.last_overhaul}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POPIS STROJA
                        </label>
                        <textarea
                        name='description'
                        onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>            
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <div>
                    <label
                        htmlFor="manufacturer"
                        className="block text-md font-semibold text-gray-800 mt-2"
                    >
                        VÝROBCA
                    </label>
                    <input
                        name='manufacturer' required
                        type="text"
                        onChange={formik.handleChange} value={formik.values.manufacturer}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div>
                    <label
                        htmlFor="manufacturer_address"
                        className="block text-md font-semibold text-gray-800 mt-2"
                    >
                        ADRESA
                    </label>
                    <input
                        name='manufacturer_address'
                        type="text"
                        onChange={formik.handleChange} value={formik.values.manufacturer_address}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div>
                    <label
                        htmlFor="manufacturer_tel_number"
                        className="block text-md font-semibold text-gray-800 mt-2"
                    >
                        TEL. ČÍSLO
                    </label>
                    <input
                        name='manufacturer_tel_number'
                        type="text"
                        onChange={formik.handleChange} value={formik.values.manufacturer_tel_number}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div>
                    <label
                        htmlFor="manufacturer_fax"
                        className="block text-md font-semibold text-gray-800 mt-2"
                    >
                        FAX
                    </label>
                    <input
                        name='manufacturer_fax'
                        type="text"
                        onChange={formik.handleChange} value={formik.values.manufacturer_fax}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div>
                    <label
                        htmlFor="manufacturer_email"
                        className="block text-md font-semibold text-gray-800 mt-2"
                    >
                        EMAIL
                    </label>
                    <input
                        name='manufacturer_email'
                        type="email"
                        onChange={formik.handleChange} value={formik.values.manufacturer_email}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div>
                    <label
                        htmlFor="manufacturer_web"
                        className="block text-md font-semibold text-gray-800 mt-2"
                    >
                        WEB
                    </label>
                    <input
                        name='manufacturer_web'
                        type="text"
                        onChange={formik.handleChange} value={formik.values.manufacturer_web}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                </div>
                <div>
                    <label
                        htmlFor="activity"
                        className="block text-md font-semibold text-gray-800 mt-2"
                    >
                        STAV
                    </label>
                    <select 
                        name='activity'
                        onChange={formik.handleChange} value={formik.values.activity}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="Aktívny">Aktívny</option>
                        <option value="Vyradený">Vyradený</option>
                    </select>
                </div>
                <button type="submit" ref={submitRef} className='hidden'></button>
            </div>
            </div>
            </div>
             </form>            
            </div>
            </> 
            );
} 
