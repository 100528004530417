import React,{useContext,useEffect, useState,useRef} from 'react';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon, CollapseTreeIcon, ExpandTreeIcon, BranchTreeIcon, EnvelopeIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { DateTime } from "luxon";
import UtilContext from '../../components/UtilContext';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import ReactDOMServer from 'react-dom/server';
import * as XLSX from 'xlsx';
import { useFormik } from 'formik';
import { MultiSelect } from 'primereact/multiselect';
import AuthContext from '../../components/AuthContext';

export default function TrainingStateList({preview=null}){

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);

    const [invitationIcon,setInvitationIcon] = useState(true);
    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [insertIcon,setInsertIcon] = useState(true);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);
    const [searchIconColor,setSearchIconColor] = useState("");

    const [visibleInvitation,setVisibleInvitation] = useState(false);
    const [lecturers, setLecturers] = useState(state.lecturers);
    const [companyContacts, setCompanyContacts] = useState(state.company_contacts);
    const [machines, setMachines] = useState(state.machines);
    const [selectedRecord,setSelectedRecord] = useState(null);

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [scrollValue,setScrollValue] = useState(1);
    const [printVisible,setPrintVisible] = useState(false);
    const [printValue,setPrintValue] = useState("Zoznam");

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [columnsVisible, setColumnsVisible] = useState({'employee.name':true,'training.department.name':true,custom_training_name:true,custom_last_training_date:true,custom_next_training_date:true,
      custom_expiration:true,custom_state:true,my_status:true,custom_invitation:true,'training.type':true,'training.interval':true,'company.name':true,'machines':true,
      custom_lecturer_name:true,custom_company_contact_name:true,'training.employee.name':true,'training.description':true,description:true,
    });

    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [visibleRecord,setVisibleRecord] = useState(false);
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [images,setImages] = useState('');
    const [viewedImage,setViewedImage] = useState('');
    const [updatefileList, setUpdateFileList] = useState([]);
    const updateFileListRef = useRef(null);


    const ref = useRef(null);
    const elementRef = useRef(null);
    const componentRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });

      function matchAny(data, filterParams) {
        //data - the data for the row being filtered
        //filterParams - params object passed to the filter
        //RegExp - modifier "i" - case insensitve
    
        var match = false;
        const regex = RegExp(filterParams.value, 'i');
    
        for (var key in data) {
            if (regex.test(data[key]) === true) {
                match = true;
            }
        }
        return match;
      }

      function searchTable(){
        ref.current.setFilter(matchAny, { value: searchValue});
        setSearchIconColor("active");
        if (searchValue === " ") {
          ref.current.clearFilter()
        }
        setVisibleSearch(false);
      }
    
        const accept = () => {
            let rows_to_delete = [];
            rows_to_delete = ref.current.getSelectedData();
            rows_to_delete.forEach(element => {
                deleteRequest(`/api/training_records/${element.id}`)
                .then((response) => {
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                }).catch((reason) => {
                    alert(reason);
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
                });
            });
            getRequest("/api/training_cards").then((response) => {
                dispatch({type:'load_training_cards',newTrainingCards:response.data});
            });
            getRequest("/api/training_employees").then((response) => {
                dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
            });
        }

      function TrainingName(props) {
        const rowData = props.cell.getRow().getData();
        var parent = props.cell.getRow()?.getTreeParent();
        if(rowData?._children) return <div className='training_name'>{rowData.training.name}</div>;
        if(parent){
            let parent_array = parent._row.data._children;
            for(let i = 0;i<parent_array.length;i++){
                if(parent_array[i].id == rowData.id){
                    if(i==parent_array.length-1)return <div className='training_name'>Prvotné školenie</div>;
                    else return <div className='training_name'>{parent_array.length-1-i}. Opakované školenie</div>;
                }
            }
            return <div className='training_name'>{parent._row.data.training.name}</div>;
        }
    } 

    function NameFormatter(props) {
      const rowData = props.cell.getRow().getData();
      return <div className='training_name'>{rowData.employee?.name}</div>;
    }

      function LastTrainingDate(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?._children?.length > 0) return DateTime.fromISO(rowData._children[0].last_training_date).toFormat("dd.MM.yyyy");
        if(rowData?.last_training_date) return DateTime.fromISO(rowData?.last_training_date).toFormat("dd.MM.yyyy");
        if(new Date(rowData?.initial_date) > new Date())return <span className='text-blue-600'>Naplánované</span>;
        return <span className='text-red-500'>Neškolený</span>;
      }

      function NextTrainingDate(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?._children?.length > 0) return DateTime.fromISO(rowData._children[0].next_training_date).toFormat("dd.MM.yyyy");
        if(rowData?.next_training_date) return DateTime.fromISO(rowData?.next_training_date).toFormat("dd.MM.yyyy");
        if(new Date(rowData?.initial_date) > new Date())return <span className='text-blue-600'>Naplánované</span>;
        return <span className='text-red-500'>Neškolený</span>;
      }

      function StateFormatter(props) {
        const rowData = props.cell.getRow().getData();
        var parent = props.cell.getRow()?.getTreeParent();
        if(parent){
            let parent_array = parent._row.data._children;
            for(let i = 0;i<parent_array.length;i++){
                if(parent_array[i].id == rowData.id){
                    if(i==0){
                        if(parent._row.data?.deactivation_date!=null && parent._row.data?.deactivation_date!=""){
                            return "DEA";
                        }
                        else if(new Date(parent_array[i]?.next_training_date) < new Date()){
                            return "NOK";
                        }
                        else return "OK";
                    }
                    else return "OK";
                }
            }
        }
        if(rowData?.deactivation_date!=null && rowData?.deactivation_date!="") return "DEA";
        if(rowData?._children?.length > 0){           
            if(new Date(rowData?._children[0]?.next_training_date) < new Date()){
                return "NOK";
            }
            return "OK"
        }
        else if(new Date(rowData?.initial_date) > new Date()){
          return "OK";
        }
        else return "NOK";
      }

    function ExpirationFormatter(props) {
      const rowData = props.cell.getRow().getData();
      var parent = props.cell.getRow()?.getTreeParent();
      if(!parent){
        if(rowData?.deactivation_date!=null && rowData?.deactivation_date!="") return "DEA";
        if(rowData?._children?.length > 0){           
            if(new Date(rowData?._children[0]?.next_training_date) < new Date()){
                return "0-";
            }
            else if(new Date(rowData?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 15))){
              return "15-";
            }
            else if(new Date(rowData?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 30))){
              return "30-";
            }
            else if(new Date(rowData?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 45))){
              return "45-";
            }
            else if(new Date(rowData?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 60))){
              return "60-";
            }
            else return "61+";
        }
        else if(new Date(rowData?.initial_date) > new Date()){
          return "";
        }
        else return "0-";
      }
      else return "";
    }

    function InvitationFormatter(props) {
      const rowData = props.cell.getRow().getData();
      if(rowData?._children){     
          let invitation = state.training_invitations.filter((invitation) => invitation.training_id == rowData.training_id && invitation.employees.filter(emp => emp.id==rowData.employee_id)?.length>0);
          if(invitation?.length > 0){
            if(new Date(invitation[0].invitation_date) < new Date()){
              return "Neaktívna";
            }
            else return "Aktívna";
          }
          else{
            return "Žiadna";
          }
        }
      else return "";
    }

    function nameHeaderFilter(headerValue, rowValue, rowData, filterParams){
      return rowData?.training?.name.toUpperCase().includes(headerValue.toUpperCase());
    }

    function dateHeaderFilter(headerValue, rowValue, rowData, filterParams){
      if(rowData?._children?.length==0){
        if(headerValue!=""){
          if(new Date(rowData?.initial_date) > new Date())return "Naplánované".toLowerCase().includes(headerValue.toLowerCase());
          return "Neškolený".toLowerCase().includes(headerValue.toLowerCase());
        }
      }
      if(filterParams.name=="last_training_date"){
        return DateTime.fromISO(rowData?._children[0]?.last_training_date).toFormat("dd.MM.yyyy").includes(headerValue);
      }
      else if(filterParams.name=="next_training_date"){
        return DateTime.fromISO(rowData?._children[0]?.next_training_date).toFormat("dd.MM.yyyy").includes(headerValue);
      }
    }

    function expirationHeaderFilter(headerValue, rowValue, rowData, filterParams){
        let state = "";
        if(rowData?._children?.length > 0){           
            if(new Date(rowData?._children[0]?.next_training_date) < new Date()){
              state="0-";
            }
            else if(new Date(rowData?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 15))){
              state="15-";
            }
            else if(new Date(rowData?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 30))){
              state="30-";
            }
            else if(new Date(rowData?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 45))){
              state="45-";
            }
            else if(new Date(rowData?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 60))){
              state="60-";
            }
            else state="61+"
        }
        else if(new Date(rowData?.initial_date) > new Date())state="";
        else state="0-";
        if(state=="")return false;
        if((rowData?.deactivation_date!=null && rowData?.deactivation_date!="") || (rowData?.employee?.date_of_departure!=null && rowData?.employee?.date_of_departure!=""))state="DEA";
        if(state=="61+" || state=="DEA")return headerValue.toUpperCase() == state.toUpperCase();
        else return headerValue.toUpperCase().includes(state.toUpperCase());
    }

    function invitationHeaderFilter(headerValue, rowValue, rowData, filterParams){
      let return_state = "";
      if(rowData?._children){     
          let invitation = state.training_invitations.filter((invitation) => invitation.training_id == rowData.training_id && invitation.employees.filter(emp => emp.id==rowData.employee_id)?.length>0);
          if(invitation?.length > 0){
            if(new Date(invitation[0].invitation_date) < new Date()){
              return_state="Neaktívna";
            }
            else return_state="Aktívna";
          }
          else{
            return_state = "Žiadna";
          }
        }
      else return_state="";
      return headerValue.toUpperCase() == return_state.toUpperCase();
  }

    function stateHeaderFilter(headerValue, rowValue, rowData, filterParams){
      let state = "";
      if(rowData?._children?.length > 0){           
          if(new Date(rowData?._children[0]?.next_training_date) < new Date()){
              state="NOK";
          }
          else state="OK";
      }
      else if(new Date(rowData?.initial_date) > new Date()){
        state="OK";
      }
      else state="NOK";
      if((rowData?.deactivation_date!=null && rowData?.deactivation_date!="") || (rowData?.employee?.date_of_departure!=null && rowData?.employee?.date_of_departure!=""))state="DEA";
      if(headerValue=="OK NOK")return headerValue.toUpperCase().includes(state.toUpperCase());
      return headerValue.toUpperCase() == state.toUpperCase();
  }

  function Status(props) {
    const rowData = props.cell.getRow().getData();
    if(rowData?.employee?.date_of_departure=="" || rowData?.employee?.date_of_departure==null) return "Aktívny";
    else return "Neaktívny";
  }

  function statusHeaderFilter(headerValue, rowValue, rowData, filterParams){
    let state = "";
    if(rowData?.employee?.date_of_departure=="" || rowData?.employee?.date_of_departure==null)state="Aktívny";
    else state="Neaktívny";
    return headerValue.toUpperCase() == state.toUpperCase();
  }

  function machineHeaderFilter(headerValue, rowValue, rowData, filterParams){
    if(rowData.machines?.length<0)return false;
    else return rowData.machines.map(machine => machine?.name).join('/').toLowerCase().includes(headerValue.toLowerCase());
  }

  function MachineFormatter(props){
    const rowData = props.cell.getRow().getData();
    console.log(rowData.machines);
    return rowData.machines?.map(machine => machine?.name)?.join('/');
  }

  function lecturerHeaderFilter(headerValue, rowValue, rowData, filterParams){
    return rowData.lecturer?.name.toLowerCase().includes(headerValue.toLowerCase()) || rowData.company_contact?.lecturer?.name.toLowerCase().includes(headerValue.toLowerCase());
  }

  function LecturerFormatter(props){
    const rowData = props.cell.getRow().getData();
    return rowData?.lecturer?.name || rowData?.company_contact?.lecturer?.name;
  }

  function contactHeaderFilter(headerValue, rowValue, rowData, filterParams){
    return rowData?.company_contact?.name.toLowerCase().includes(headerValue.toLowerCase()) || rowData?.company_contact?.employee?.name.toLowerCase().includes(headerValue.toLowerCase());
  }

  function ContactFormatter(props){
    const rowData = props.cell.getRow().getData();
    return rowData?.company_contact?.name || rowData?.company_contact?.employee?.name;
  }

    const [columns,setColumns] = useState([
        { title: '', field: 'id',visible:false},
        { title: '', field: 'editor',visible:false},
        {
          formatter:"rowSelection", titleFormatter:"rowSelection",titleFormatterParams:{rowRange:"active"},hozAlign:"center",width: 20,headerSort:false,resizable:false,
          cellClick: function(e, cell) {
            cell.getRow().toggleSelect();
            console.log(cell.getRow(), cell.getRow().getTreeParent());
          }
        },
        { title: 'Meno', field: 'employee.name',formatter: reactFormatter(<NameFormatter/>),headerFilter:"input",minWidth: 200,widthGrow:2},
        { title: 'Oddelenie školenia', field: 'training.department.name',headerFilter:"input",minWidth: 200,widthGrow:2},
        { title: 'Názov školenia', field: 'custom_training_name',minWidth: 250,widthGrow:3,headerFilter:"input",headerFilterFunc:nameHeaderFilter,formatter: reactFormatter(<TrainingName/>)},
        { title: 'Posledné školenie', field: 'custom_last_training_date',headerFilter:"input",headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"last_training_date"},minWidth: 200,widthGrow:1,formatter: reactFormatter(<LastTrainingDate/>)},
        { title: 'Nasled. školenie', field: 'custom_next_training_date',headerFilter:"input",headerFilterFunc:dateHeaderFilter,headerFilterFuncParams:{name:"next_training_date"},minWidth: 180,widthGrow:1,formatter: reactFormatter(<NextTrainingDate/>)},
        { title: 'Expirácia', field: 'custom_expiration',headerFilter:"list", headerFilterParams:{values:{"60- 45- 30- 15- 0-":"60-","45- 30- 15- 0-":"45-","30- 15- 0-":"30-","15- 0-":"15-","61+":"61+","0-":"0-","DEA":"DEA"}, clearable:true},headerFilterFunc:expirationHeaderFilter,minWidth: 150,widthGrow:1,formatter: reactFormatter(<ExpirationFormatter/>)},
        { title: 'Stav', field: 'custom_state',headerFilter:"list", headerFilterParams:{values:{"OK NOK":"OK NOK","OK":"OK","NOK":"NOK","DEA":"DEA"}, clearable:true},headerFilterFunc:stateHeaderFilter,minWidth: 120,widthGrow:1,formatter: reactFormatter(<StateFormatter/>)},
        { title: 'Status', field: 'my_status',headerFilter:"list", headerFilterParams:{values:{"Aktívny":"Aktívny","Neaktívny":"Neaktívny"}, clearable:true},headerFilterFunc:statusHeaderFilter,formatter: reactFormatter(<Status/>),minWidth:150,widthGrow:1},
        { title: 'Pozvánka', field: 'custom_invitation',headerFilter:"list", headerFilterParams:{values:{"Aktívna":"Aktívna","Neaktívna":"Neaktívna","Žiadna":"Žiadna"}, clearable:true},headerFilterFunc:invitationHeaderFilter,minWidth: 200,widthGrow:1,formatter: reactFormatter(<InvitationFormatter/>)},
        { title: 'Druh školenia', field: 'training.type',headerFilter:"input",minWidth: 200 },
        { title: 'Interval', field: 'training.interval',headerFilter:"input",minWidth: 200 },
        { title: 'Škol. firma', field: 'company.name',headerFilter:"input",minWidth: 200 },
        { title: 'Stroj', field: 'machines',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<MachineFormatter/>),headerFilterFunc:machineHeaderFilter },
        { title: 'Lektor', field: 'custom_lecturer_name',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<LecturerFormatter/>),headerFilterFunc:lecturerHeaderFilter },
        { title: 'Školiteľ', field: 'custom_company_contact_name',headerFilter:"input",minWidth: 200,formatter: reactFormatter(<ContactFormatter/>), headerFilterFunc:contactHeaderFilter},
        { title: 'Zodpovedná osoba', field: 'training.employee.name',headerFilter:"input",minWidth: 200 },
        { title: 'Popis typu školenia', field: 'training.description',headerFilter:"input",minWidth: 200 },
        { title: 'Popis', field: 'description',headerFilter:"input",minWidth: 200,widthGrow:2},
    ]);

    async function scrollToFirst(){
        ref.current.scrollToRow(ref.current.getRows("range")[0].getData().id, "top", true);
    }

    async function scrollUp(){
        let first_visible = ref.current.getRows("visible")[0].getData().id - (ref.current.getRows("visible").length-2);
        ref.current.scrollToRow(first_visible < 1 ? 1 : first_visible, "top", false);
    }

    async function scrollDown(){
        let last_visible = ref.current.getRows("visible")[ref.current.getRows("visible").length-1].getData().id;
        ref.current.scrollToRow(last_visible, "top", true);
    }

    async function scrollToLast(){
        ref.current.scrollToRow(ref.current.getRows("range")[ref.current.getRows("range").length-1].getData().id, "bottom", true);
    }
    
    function setMask(item,value){
      const columnVisible = {...columnsVisible};
        columnVisible[item] = value;
      for(const val in columnVisible) {
        if(columnVisible[val]==false){
          ref.current.hideColumn(`${val}`);
        }
        else{
          ref.current.showColumn(`${val}`);
        }
      }
      setColumnsVisible(columnVisible);
      setTimeout(() => {
        ref.current.redraw(true);
      }, 0);
    }
  
    const itemRenderer = (item, options) => (
      <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
          <span className={`${item.icon} text-primary`} />
          <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
          {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
      </a>
    );
  
    const [expandedKeys, setExpandedKeys] = useState({});
  
    const handleClickOutside = (event) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
          setExpandedKeys({});
      }
    };
  
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
              {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("TrainingStateList","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("TrainingStateList","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nast. triedenie',
                icon: 'pi pi-sort-alt',
                command: () => {setUserMaskFilter("TrainingStateList","set_sorters",null,null,null,ref.current.getSorters());OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
                key: '0_4',
                label: 'Zrušiť triedenie',
                icon: 'pi pi-sort-alt-slash',
                command: () => {setUserMaskFilter("TrainingStateList","set_sorters",null,null,null,[]);ref.current.setSort([]);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
                key: '0_5',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_6',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];
  
    function showChosenNotifications(){
      let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "TrainingStateList"));
      if(db_chosen_notifications?.length>0){
        setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
      }
      else{
        setChosenNotifications([]);
      }
      setNotificationVisible(true);
    }
  
    function saveChosenNotifications(){
        let formData = new FormData();
        formData.append("type","training_types");
        formData.append("data", JSON.stringify(chosenNotifications));
        postRequest('/api/chosen_notifications', formData,true)
        .then((response) => {
            if(response.status == 201){
              dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
              let mytoast = toast.current ? toast : ownToast;
              mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
            }else{
              let mytoast = toast.current ? toast : ownToast;
              mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
            }
        }).catch((reason) => {
              console.log(reason);
              alert("Daný úkon sa nepodaril!");
        });
        setNotificationVisible(false);
    }

    function changeMarkings(value){
        const columnVisible = {...columnsVisible};
        for(let val in columnVisible) {
          if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
            columnVisible[val]=false;
            ref.current.hideColumn(`${val}`);
          }
        };
        setColumnsVisible(columnVisible);
        setTimeout(() => {
            ref.current.redraw(true);
        }, 0);
    }

    const exportToExcel = () => {
        const excel_type = {};
        let error_records = ref.current.getData("active").map((x) => {
        let id = x.id;
        let meno = x?.employee?.name;
        let oddelenie_skolenia = x?.training?.department?.name;
        let nazov_skolenia = x?.training?.name;
        let posledne_skolenie = x?._children?.length > 0 ? DateTime.fromISO(x?._children[0]?.last_training_date).toFormat("dd.MM.yyyy") : "Nevykonané";
        let nasledujuce_skolenie = x?._children?.length > 0 ? DateTime.fromISO(x?._children[0]?.next_training_date).toFormat("dd.MM.yyyy") : "Nevykonané";
        let expiracia = "";
        if(x?.deactivation_date!=null && x?.deactivation_date!="")expiracia = "DEA";
        else{
          if(x?._children?.length > 0){           
              if(new Date(x?._children[0]?.next_training_date) < new Date()){
                  expiracia="NOK";
              }
              else if(new Date(x?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 15))){
                expiracia="15-";
              }
              else if(new Date(x?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 30))){
                expiracia="30-";
              }
              else if(new Date(x?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 45))){
                expiracia="45-";
              }
              else if(new Date(x?._children[0]?.next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 60))){
                expiracia="60-";
              }
              else expiracia="OK";
          }
          else expiracia="NOK";
        }
        let stav = "";
        if(x?.deactivation_date!=null && x?.deactivation_date!="") stav = "DEA";
        else{
          if(x?._children?.length > 0){           
              if(new Date(x?._children[0]?.next_training_date) < new Date()){
                  stav = "NOK";
              }
              else stav = "OK"
          }
          else stav = "NOK";
        }
        let status = "";
        if(x?.employee?.date_of_departure=="" || x?.employee?.date_of_departure==null) status = "Aktívny";
        else status = "Neaktívny";
        let pozvanka = "";
        let invitation = state.training_invitations.filter((invitation) => invitation.training_id == x?.training_id && invitation.employees.filter(emp => emp.id==x?.employee_id)?.length>0);
        if(invitation?.length > 0){
          if(new Date(invitation[0].invitation_date) < new Date()){
            pozvanka = "Neaktívna";
          }
          else pozvanka = "Aktívna";
        }
        else{
          pozvanka = "Žiadna";
        }
        let druh_skolenia = x?.training?.type;
        let interval = x?.training?.interval;
        let skoliaca_firma = x?.training?.training_company?.name;
        let skolitel = x?.training?.training_company_contact?.name;
        let zodpovedna_osoba = x?.training?.employee?.name;
        let popis_typu_skolenia = x?.training?.description;
        let popis = x?.description;
        return {
            id,meno,oddelenie_skolenia,nazov_skolenia,posledne_skolenie,nasledujuce_skolenie,expiracia,stav,status,pozvanka,
            druh_skolenia,interval,skoliaca_firma,skolitel,zodpovedna_osoba,popis_typu_skolenia,popis
    
        };
        }).sort((a, b) => (parseInt(a.specific_id) > parseInt(b.specific_id)) ? -1 : 1);
        const worksheet = XLSX.utils.json_to_sheet(error_records);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Skolenia podla stavu");
        const excelBuffer = XLSX.writeFile(workbook, "skolenia_podla_stavu.xlsx",{bookSST:true});//{ bookType: 'xlsx', type: 'array' });
      };

    const onSelectRow = (data, rows) => {
        setInvitationIcon(true);
        if(data.length>0){
            let children = 0;
            let parent = 0;
            data.forEach(obj => {
                if(obj?.training_card_id>0){
                    children++;
                }
                else parent++;
            });
            if(parent>0 && children==0){
                let training_ids = data.map(obj => obj.training_id);
                training_ids = training_ids.filter((item, index) => training_ids.indexOf(item) === index);
                if(training_ids?.length>1){
                    setInvitationIcon(true);
                    return;
                }
                let company_ids = data.map(obj => obj.company_id);
                company_ids = company_ids.filter((item, index) => company_ids.indexOf(item) === index);
                if(company_ids?.length>1 || (company_ids?.length==1 && company_ids[0]==null)){
                    setInvitationIcon(true);
                    return;
                }
                let machines = data.map(obj => obj.machines.map(machine => machine.id));
                let common_machines = machines.shift().filter(function(v) {
                  return machines.every(function(a) {
                      return a.indexOf(v) !== -1;
                  });
                });
                if(common_machines?.length<1){
                  setInvitationIcon(true);
                  return;
                }
                let lecturer_ids = data.map(obj => obj.lecturer_id);
                lecturer_ids = lecturer_ids.filter((item, index) => lecturer_ids.indexOf(item) === index);
                if(lecturer_ids?.length>1 || (lecturer_ids?.length==1 && lecturer_ids[0]==null)){
                    setInvitationIcon(true);
                    return;
                }
                setInvitationIcon(false);
            }
        }
    };

    const validate = values => {
        const errors = {};
        return errors;
    };

    const formik = useFormik({
        initialValues:{
            training_id:'',company_id:'',machines:[],lecturer_id:'',company_contact_id:'',employees:[]
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
          let invitation = {
            name:'',invitation_date: '',invitation_time:'',location:'',
            training_id:values.training_id,company_id:values.company_id,company_contact_id:values.company_contact_id,
            training:state.training_types.filter(training => training.id == values.training_id)[0],
            lecturer_id:state.company_contacts.filter(contact => contact.id == values.company_contact_id)[0]?.lecturer_id,
            mail:true,sms:false,contact_notify:true,email_content:'',sms_content:'',
            notifications:[],employees:values.employees,machines:values.machines,
          };
          dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationAdd',props:{invitation: invitation,type:'add'},mask:'skol028'});
          setVisibleInvitation(false);
        }
    });

    function addNewInvitation(){
        setVisibleInvitation(true);
        const data = ref?.current?.getSelectedData("active");
        formik.setFieldValue("training_id",data[0]?.training_id);
        formik.setFieldValue("company_id",data[0]?.company_id);
        formik.setFieldValue("machines",data[0]?.machines.map((machine) => ({name:machine.name,id:machine.id})));
        formik.setFieldValue("lecturer_id",data[0]?.lecturer_id);
        if(data[0]?.company_contact_id)formik.setFieldValue("company_contact_id",data[0]?.company_contact_id);
        formik.setFieldValue("employees",data.map(obj => obj.employee).map((employee) => ({name:employee.name,id:employee.id})));
        const my_machines = [];
        state.company_contacts.filter(contact => contact.company_id == data[0]?.company_id)?.forEach(contact => {
            contact?.machines?.map(machine => my_machines.push(machine.id));
        });        
        setMachines(state.machines.filter(machine => my_machines.includes(machine.id)));
        let my_contacts = state.company_contacts.filter(contact => contact.company_id == data[0]?.company_id && contact.machines.filter(machine => data[0]?.machines.map(mach => mach.id).includes(machine.id))?.length > 0)?.map(contact => contact.lecturer_id);
        setLecturers(state.lecturers.filter(lecturer => my_contacts.includes(lecturer.id)));
        setCompanyContacts(state.company_contacts.filter(contact => contact.company_id == data[0]?.company_id && contact.machines.filter(machine => data[0]?.machines.map(mach => mach.id).includes(machine.id))?.length > 0 && (contact.lecturer_id == data[0]?.lecturer_id || contact.lecturer_id == data[0]?.company_contact?.lecturer_id))); 
    }

    function changeCompany(company_id){
        const machines = [];
        state.company_contacts.filter(contact => contact.company_id == company_id)?.forEach(contact => {
            contact?.machines?.map(machine => machines.push(machine.id));
        });
        setMachines(state.machines.filter(machine => machines.includes(machine.id)));
        if(visibleInvitation){
            formik.setFieldValue('company_id',company_id);
            formik.setFieldValue('machines',[]);
            formik.setFieldValue('lecturer_id','');
            formik.setFieldValue('company_contact_id','');
            formik.setFieldValue("employees",[]);
        }
        else{
            formikUpdate.setFieldValue('company_id',company_id);
            formikUpdate.setFieldValue('machines',[]);
            formikUpdate.setFieldValue('lecturer_id','');
            formikUpdate.setFieldValue('company_contact_id','');
            formikUpdate.setFieldValue("employees",[]);
        }
    }

    function changeMachine(machines){
        if(visibleInvitation){
            let machines_ids = machines.map(mach => mach.id);
            let contacts = state.company_contacts.filter(contact => contact.company_id == formik.values?.company_id && machines_ids.every(obj => contact.machines?.map(machin => machin.id).includes(obj)))?.map(contact => contact.lecturer_id);
            setLecturers(state.lecturers.filter(lecturer => contacts.includes(lecturer.id)));
            formik.setFieldValue("machines",machines);
            formik.setFieldValue('lecturer_id','');
            formik.setFieldValue('company_contact_id','');
            formik.setFieldValue("employees",[]);
        }
        else{
            let machines_ids = machines.map(mach => mach.id);
            let contacts = state.company_contacts.filter(contact => contact.company_id == formikUpdate.values?.company_id && machines_ids.every(obj => contact.machines?.map(machin => machin.id).includes(obj)))?.map(contact => contact.lecturer_id);
            setLecturers(state.lecturers.filter(lecturer => contacts.includes(lecturer.id)));
            formikUpdate.setFieldValue("machines",machines);
            formikUpdate.setFieldValue('lecturer_id','');
            formikUpdate.setFieldValue('company_contact_id','');
            formikUpdate.setFieldValue("employees",[]);
        }
    }
        
    function changeLecturer(lecturer_id){
        if(visibleInvitation){
            setCompanyContacts(state.company_contacts.filter(contact => contact.company_id == formik.values?.company_id && formik.values?.machines.map(machin => machin.id).every(obj => contact.machines?.map(machin => machin.id).includes(obj)) && contact.lecturer_id == lecturer_id));
            formik.setFieldValue('lecturer_id',lecturer_id);
            formik.setFieldValue('company_contact_id','');
            formik.setFieldValue("employees",[]);
        }
        else{
            setCompanyContacts(state.company_contacts.filter(contact => contact.company_id == formikUpdate.values?.company_id && formikUpdate.values?.machines.map(machin => machin.id).every(obj => contact.machines?.map(machin => machin.id).includes(obj)) && contact.lecturer_id == lecturer_id));
            formikUpdate.setFieldValue('lecturer_id',lecturer_id);
            formikUpdate.setFieldValue('company_contact_id','');
            formikUpdate.setFieldValue("employees",[]); 
        }
    }

    const formikUpdate = useFormik({
        initialValues: selectedRecord ? {
            id: selectedRecord?.id,training_id:state.training_employees.filter(card => card.id==selectedRecord?.training_card_id)[0]?.training_id,
            last_training_date:selectedRecord?.last_training_date,description:selectedRecord?.description,
            company_id:selectedRecord?.company_id,
            machines:selectedRecord?.machines.map((machine) => ({name:machine.name,id:machine.id})),
            lecturer_id:selectedRecord?.company_contact?.lecturer_id,company_contact_id:selectedRecord?.company_contact_id} : 
            {training_id: '',last_training_date: '',description:'',company_id:'',machines:[],lecturer_id:'',company_contact_id:''},
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                if(value=="machines"){
                    formData.append("machines", values.machines.map(machine => machine.id));
                }
                else formData.append(value, values[value] != null ? values[value] : "");
            }
            for(let i = 0;i<updatefileList.length;i++){
              //console.log("IMAGE :"+fileList[i].name);
              formData.append('files[]',updatefileList[i]);
            }
            postRequest(`/api/training_records/update/${formikUpdate.values.id}`, formData,true)
            .then((response) => {
                getRequest("/api/training_cards").then((response) => {
                    dispatch({type:'load_training_cards',newTrainingCards:response.data});
                    setVisibleRecord(false);
                    formikUpdate.resetForm();
                    if(updatefileList.length){
                      setUpdateFileList([]);
                      updateFileListRef.current.value = null;
                    }
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                getRequest("/api/training_employees").then((response) => {
                    dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });
    
    return(
        <>
        <Toast ref={ownToast}/>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="print"><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="search"><button onClick={searchIconColor!="active"? () => {setVisibleSearch(true);}:() => {setSearchIconColor("");setSearchValue("");ref.current.clearFilter();}}>{searchIconColor == "" ?  <MdSearch fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Hľadať'/> : <MdSearchOff fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Zrušiť hľadanie'/>}</button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="export"><button onClick={() => exportToExcel()}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="invite"><button className="disabled:opacity-25" disabled={invitationIcon} onClick={() => addNewInvitation()}><EnvelopeIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button onClick={() => scrollUp()}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Školenia podľa stavu","TrainingStateList")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="fast"><button onClick={() => setFastView("TrainingStateList",columnsVisible,columns)}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
      </div>
      <div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
<div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
  <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
    
    <div ref={elementRef}>
    <ReactTabulator
      data={state.training_employees} 
      onRef={(r) => (ref.current = r.current)}
      className='h-[calc(100vh_-_193px)]'
      columns={columns}
      events={{
        rowSelectionChanged: onSelectRow,
        tableBuilt: () => {
            if(preview!=null){
              if(preview=="after"){
                ref?.current?.setHeaderFilterValue("custom_state", "OK NOK");
                ref?.current?.setHeaderFilterValue("custom_expiration", "0-");
              }
              else if(preview=="15"){
                ref?.current?.setHeaderFilterValue("custom_state", "OK NOK");
                ref?.current?.setHeaderFilterValue("custom_expiration", "15- 0-");
              }
              else if(preview=="30"){
                ref?.current?.setHeaderFilterValue("custom_state", "OK NOK");
                ref?.current?.setHeaderFilterValue("custom_expiration", "30- 15- 0-");
              }
              else ref?.current?.setHeaderFilterValue("custom_state", "OK");
            }
            let mask = state.user_mask_filters.filter(mask => mask.page=="TrainingStateList");
              if(mask.length>0){
                let attributes = [];
                try {
                  attributes = JSON.parse(mask[0].attributes)
                } catch (e) {
                  attributes = []
                }
                if(attributes?.length>1)ref.current.setColumnLayout(attributes);
                let filters = [];
                try {
                  filters = JSON.parse(mask[0].filters);
                } catch (e) {
                  filters = []
                }
                if(preview==null){
                  filters.forEach(filter => {
                    ref.current.setHeaderFilterValue(filter.field,filter.value);
                  });
                }
                let sorters = [];
                try {
                  sorters = JSON.parse(mask[0]?.sorters);
                } catch (e) {
                  sorters = []
                }
                if(sorters?.length>0)ref.current.setSort(sorters);
                let json_columns;
                try {
                  json_columns = JSON.parse(mask[0].columns);
                  setColumnsVisible(json_columns);
                  setTimeout(() => {
                    ref.current.redraw(true);
                  }, 0);
                } catch (e) {
                  json_columns = []
                }
                for(let column in json_columns){
                  if(json_columns[column]==false)ref.current.hideColumn(column);
                  else ref.current.showColumn(column);
                }
              }
          },
          columnMoved:() => {
            setUserMaskFilter("TrainingStateList","column_move",null,null,ref.current.getColumnLayout());
          }
      }}
      options={{renderHorizontal:"virtual",rowHeight:30,layout:"fitColumns",movableColumns: true,
      dataTree:true,dataTreeStartExpanded:false,dataTreeFilter:false,dataTreeElementColumn: "employee.name",
      dataTreeCollapseElement:ReactDOMServer.renderToStaticMarkup(<CollapseTreeIcon/>),
      dataTreeExpandElement:ReactDOMServer.renderToStaticMarkup(<ExpandTreeIcon/>),
      dataTreeBranchElement:ReactDOMServer.renderToStaticMarkup(<BranchTreeIcon/>),
      printAsHtml:true,printHeader:"<h1>Školenia podľa stavu<h1>",printRowRange:"active", 
      initialSort:[
        {column:"employee.name", dir:"asc"},
      ]
      }}/>
    </div> 
  </div> 
</div> 
</div>
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
          <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<3) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 
                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>    
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("TrainingStateList","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Nový záznam' value='Nový záznam' checked={chosenNotifications.includes('Nový záznam')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nový záznam' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
        </Dialog>
        <Dialog header="Vytvoriť pozvánku" visible={visibleInvitation} style={{ width: '40vw' }} onHide={() => setVisibleInvitation(false)}>
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <label
                        htmlFor="training_id"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Názov školenia
                    </label>
                    <select 
                    required
                    name="training_id"
                    disabled value={formik.values.training_id} 
                    className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                        <option value="">Vyber školenie</option>
                        {state.training_types.map((training) => (
                        <option key={training.id} value={training.id}>{training.name}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label
                        htmlFor="company_id"
                        className="block text-md font-semibold text-gray-800"
                    >
                    Škol. firma
                    </label>
                    <select required
                    name='company_id'
                    disabled onChange={(e) => changeCompany(e.target.value)} value={formik.values?.company_id}
                    className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                    <option value="">Vyber škol.firmu</option>
                    {state.training_companies.map((training_company) => (
                        <option key={training_company.id} value={training_company.id}>{training_company.name}</option>
                    ))}
                    </select>
                </div> 
                {(formik.values?.company_id!="" && formik.values?.company_id!=null) && <><div>
                    <label
                        htmlFor="machines"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Stroj*
                    </label>
                    <MultiSelect value={formik.values.machines} disabled onChange={(e) => changeMachine(e.value)}
                        options={machines.map((machine) => 
                            ({name:machine.name,id:machine.id})
                        )} 
                        optionLabel="name"
                        filter placeholder="Vyber stroj/e" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                    {formik.errors?.machines ? <span style={{color: "red"}}>{formik.errors?.machines}</span> : ""}
                </div>
                {formik.values?.machines?.length > 0 && <><div>
                    <label
                        htmlFor="lecturer_id"
                        className="block text-md font-semibold text-gray-800"
                    >
                    Lektor
                    </label>
                    <select required
                    name='lecturer_id'
                    disabled onChange={(e) => changeLecturer(e.target.value)} value={formik.values?.lecturer_id}
                    className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                    <option value="">Vyber lektora</option>
                    {lecturers.map((lecturer) => (
                        <option key={lecturer.id} value={lecturer.id}>{lecturer.name}</option>
                    ))}
                    </select>
                </div>
                {(formik.values?.lecturer_id!="" && formik.values?.lecturer_id!=null) && <><div>
                    <label
                        htmlFor="company_contact_id"
                        className="block text-md font-semibold text-gray-800"
                    >
                    Školiteľ
                    </label>
                    <select required
                    name='company_contact_id'
                    onChange={formik.handleChange} value={formik.values?.company_contact_id}
                    className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                    <option value="">Vyber školiteľa</option>
                    {companyContacts.map((contact) => (
                        <option key={contact.id} value={contact.id}>{contact?.employee?.name || contact?.name}</option>
                    ))}
                    </select>
                </div>
                <div>
                <label
                    for="employees"
                    className="block text-md font-semibold text-gray-800"
                >
                    Zamestnanci
                </label>
                <MultiSelect required disabled value={formik.values.employees} onChange={(e) => formik.setFieldValue("employees",e.value)} 
                options={state.training_cards.filter(card => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)?.length > 0)
                    .filter(card => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.company_id == formik.values?.company_id && formik.values?.machines.map(machin => machin.id).every(obj => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.machines.map(machin => machin.id).includes(obj)) && formik.values?.lecturer_id == card?.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.lecturer_id ? card?.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.lecturer_id : card?.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.company_contact?.lecturer_id)
                    //.filter(card => card?.date_of_departure=="" || card?.date_of_departure==null)
                    .map((employee) => 
                    ({name:employee.name,id:employee.id})
                )} 
                optionLabel="name" 
                filter placeholder="Vyber zamestnancov" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                {formik.errors.employees ? <div className="whitespace-pre-line" style={{color:'red'}}>{formik.errors.employees}</div> : null}
                <div>
                    <label className="block text-md my-2 font-semibold text-gray-800">POČET: {formik.values.employees.length}</label>
                </div>
                </div></>}</>}</>}                   
                <button type="submit" disabled={!formik.values?.company_contact_id || formik.values.employees.length<1} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>POKRAČOVAŤ</button>         
            </form>
        </Dialog>
      </div> 
        </>
    )
}

/*function previewFilter(data, filterParams){
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter
    if(filterParams.time_period=="after"){

      if((data.employee.date_of_departure==null || data.employee.date_of_departure=="") && (data.deactivation_date==null || data.deactivation_date=="")){
        if((data._children.length == 0 || (data._children.length > 0 && new Date(data._children[0].next_training_date) < new Date()))){
          return true;
        }
      }
      }
      else if(filterParams.time_period=="15"){
        if((data.employee.date_of_departure==null || data.employee.date_of_departure=="") && (data.deactivation_date==null || data.deactivation_date=="")){
          if((data._children.length == 0 || (data._children.length > 0 && new Date(data._children[0].next_training_date) < new Date()))){
            return true;
          }
          if(data._children.length > 0 && ((new Date(data._children[0].next_training_date) > new Date()) && (new Date(data._children[0].next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 15))))){
            return true;
          }
        }
      }
      else if(filterParams.time_period=="30"){
        if((data._children.length == 0 || (data._children.length > 0 && new Date(data._children[0].next_training_date) < new Date()))){
          return true;
        }
        if((data.employee.date_of_departure==null || data.employee.date_of_departure=="") && (data.deactivation_date==null || data.deactivation_date=="")){
          if(data._children.length > 0 && ((new Date(data._children[0].next_training_date) > new Date()) && (new Date(data._children[0].next_training_date) <= new Date(new Date().setDate(new Date().getDate() + 30))))){//&& (new Date(data._children[0].next_training_date) > new Date(new Date().setDate(new Date().getDate() + 15))) 
            return true;
          }
        }  
      }
      else if(filterParams.time_period=="others"){
        if((data.employee.date_of_departure==null || data.employee.date_of_departure=="") && (data.deactivation_date==null || data.deactivation_date=="")){
          if((data._children.length == 0 || (data._children.length > 0 && new Date(data._children[0].next_training_date) < new Date()))){
            return false;
          }
          if(data._children.length > 0 && (new Date(data._children[0].next_training_date) > new Date(new Date().setDate(new Date().getDate() + 30)))){
            return true;
          }
        }    
      }
      return false;
    }*/