import React,{useContext,useEffect, useState, useRef,useReducer,useMemo} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, VerticalLineIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { Checkbox } from "primereact/checkbox";
import { Select } from "antd";
import countryList from 'react-select-country-list';
import countries from "i18n-iso-countries";
import "i18n-iso-countries/langs/sk";
countries.registerLocale(require("i18n-iso-countries/langs/sk"));

export default function BusinessPartnerAdd({business_partner, type="add"}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView,setVisible,setDialogText}= useContext(UtilContext);

    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);

    const [formBusinessPartner,setFormBusinessPartner] = useState(business_partner);
    const [formType, setFormType] = useState(type);
    const countryOptions = useMemo(() => {
        return countryList().getData().map(country => ({
            value: country.value,
            label: countries.getName(country.value, "sk") || country.label
        }));
    }, []);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState(!user.permissions.includes("edit settings"));

    function addNewErrorType(){
      dispatch({type:'create_new_tab',name:'Nový obchodný partner',page:'BusinessPartnerAdd',props:{business_partner: null,type:'add'},mask:'glo008'});
    }
    function copyErrorType(){
      dispatch({type:'create_new_tab',name:'Nový obchodný partner',page:'BusinessPartnerAdd',props:{business_partner: null, type:'add'},mask:'glo008'});
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formBusinessPartner==null?{
            name: '',types: [],defined_id: '',address: '',tel_number: '',fax: '',email: '',
            web_address: '',contact_person: '',description: '',country:''
        }:{
            id: formBusinessPartner?.id, name: formBusinessPartner?.name, types: formBusinessPartner?.types,defined_id: formBusinessPartner?.defined_id,
            address: formBusinessPartner?.address,tel_number: formBusinessPartner?.tel_number,fax: formBusinessPartner?.fax,email: formBusinessPartner?.email,
            web_address: formBusinessPartner?.web_address,contact_person: formBusinessPartner?.contact_person,description: formBusinessPartner?.description,
            country: formBusinessPartner?.country ? formBusinessPartner?.country : "",
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            setDialogText("Ukladám...");
            setVisible(true);
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }
            if(formType==="add"){
                postRequest('/api/companies', formData,true)
                .then((response) => {
                    getRequest("/api/companies").then((response) => {
                        dispatch({type:'load_companies',newCompanies:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        setVisible(false);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                })
            }
            else{
                postRequest(`/api/companies/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/companies").then((response) => {
                        dispatch({type:'load_companies',newCompanies:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        setVisible(false);
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    setVisible(false);
                    alert("Daný úkon sa nepodaril!");
                })
            }
      },
    });

    const onTypesChange = (e) => {
        let _types = [...formik.values.types];
        if (e.checked)
            _types.push(e.value);
        else
            _types.splice(_types.indexOf(e.value), 1);
            formik.setFieldValue('types',_types);
    }

    return (
        <>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
      <IntersectionObserverWrapper>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="saveandclose"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="save"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewErrorType()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyErrorType()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button onClick={() => openFavoriteDialog("Nový druh chyby","ErrorTypeAdd")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("ErrorTypeAdd",null,null)}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="vert"><VerticalLineIcon/></div>
        </IntersectionObserverWrapper>
      </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   OBCHODNÝ PARTNER
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                        <div>
                        <label
                            for="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV OBCHODNÉHO PARTNERA
                        </label>
                        <input
                            name='name'
                            type="text"
                            required
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        <button type="submit" ref={submitRef} className='hidden'></button>          
                        </div>
                        <div className='grid grid-cols-2 mt-4'>
                        <div className='mr-4'>
                        <label
                            htmlFor="defined_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            PRIDELENÉ ID
                        </label>
                        <input
                            name='defined_id'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.defined_id}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="type"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TYP
                        </label>
                        <div>
                            <div className='grid grid-cols-2 py-2'>
                                    <div className="flex align-items-center">
                                        <Checkbox name="types" value={"customer"} onChange={onTypesChange} checked={formik.values.types?.includes("customer")} className='p-button-sm p-button-text'/>
                                        <label htmlFor={"customer"} className="ml-2">Zákazník</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox name="types" value={"supplier"} onChange={onTypesChange} checked={formik.values.types?.includes("supplier")} className='p-button-sm p-button-text'/>
                                        <label htmlFor={"supplier"} className="ml-2">Dodávateľ</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox name="types" value={"calibration_company"} onChange={onTypesChange} checked={formik.values.types?.includes("calibration_company")} className='p-button-sm p-button-text'/>
                                        <label htmlFor={"calibration_company"} className="ml-2">Kalibračná firma</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox name="types" value={"training_company"} onChange={onTypesChange} checked={formik.values.types?.includes("training_company")} className='p-button-sm p-button-text'/>
                                        <label htmlFor={"training_company"} className="ml-2">Školiaca firma</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <Checkbox name="types" value={"service_company"} onChange={onTypesChange} checked={formik.values.types?.includes("service_company")} className='p-button-sm p-button-text'/>
                                        <label htmlFor={"service_company"} className="ml-2">Servisná firma</label>
                                    </div>
                            </div></div>
                        </div>   
                        </div>
                        <div>
                        <label
                            htmlFor="tel_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TELEFÓNNE ČÍSLO
                        </label>
                        <input
                            name='tel_number'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.tel_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            htmlFor="email"
                            className="block text-md font-semibold text-gray-800"
                        >
                            E-MAIL
                        </label>
                        <input
                            name='email'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.email}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>   
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                        <div>
                        <label
                            htmlFor="contact_person"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            KONTAKTNÁ OSOBA
                        </label>
                        <input
                            name='contact_person'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.contact_person}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div> 
                        <div>
                        <label
                            htmlFor="country"
                            className="block text-md font-semibold text-gray-800"
                        >
                            KRAJINA
                        </label>
                        <Select className="block w-full h-10"
                         showSearch={true} optionFilterProp="data-label" value={formik.values.country} onChange={(e) => formik.setFieldValue("country",e)}>
                            <Select.Option value="">Vyber krajinu</Select.Option>
                            {countryOptions.map((country) => (
                            <Select.Option key={country.value} value={country.value} data-label={country.label}>{country.label}</Select.Option>
                            ))}
                        </Select>
                        </div> 
                        <div>
                        <label
                            htmlFor="address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ADRESA
                        </label>
                        <input
                            name='address'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.address}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="fax"
                            className="block text-md font-semibold text-gray-800"
                        >
                             FAX
                        </label>
                        <input
                            name='fax'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.fax}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="web_address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            WEB. ADRESA
                        </label>
                        <input
                            name='web_address'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.web_address}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>
            </div>
            </div>
            </div>
            </form>
            </>
            );
} 
