import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, LoadPrioIcon} from "../../svg/svgIcons";
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import { MultiSelect } from 'primereact/multiselect';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Chips } from 'primereact/chips';
import { Message } from 'primereact/message';


export default function SummaryEmailCalibrationAdd({summary_email, type="add"}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {openFavoriteDialog,toast,setFastView}= useContext(UtilContext);

    addLocale('sk', {
        firstDayOfWeek: 1,
        showMonthAfterYear: false,
        dayNames: ['Nedela','Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'],
        dayNamesShort: ['Ne','Pon','Ut','Str','Štv','Pia','So'],
        dayNamesMin: ['Ne','Po', 'Ut', 'Str', 'Štv', 'Pi', 'So'],
        monthNames: ['Január', 'Február', 'Marec', 'Apríl', 'Máj ', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj ', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
        today: 'Dnes',
        clear: 'Vymazať'
    });

    const [closeTab,setCloseTab] = useState(false);
    const [throwInvalidEmail,setThrowInvalidEmail] = useState(false);
    const submitRef = useRef(null);

    const [formSummaryEmail,setFormSummaryEmail] = useState(summary_email);
    const [formType, setFormType] = useState(type);
    const [types,setTypes] = useState(type == "edit" ? JSON.parse(summary_email.data) : []);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState(!user.permissions.includes("edit otk-section2"));


    function addNewSummaryEmail(){
      dispatch({type:'create_new_tab',name:'Nový email - Kalibrácia',page:'SummaryEmailCalibrationAdd',props:{summary_email: null,type:'add'},mask:'kal015'})
    }
    function copySummaryEmail(){
      dispatch({type:'create_new_tab',name:'Nový email - Kalibrácia',page:'SummaryEmailCalibrationAdd',props:{summary_email: null,type:'add'},mask:'kal015'})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formSummaryEmail==null?{
            type: 'calibration',name: '',dates: '',time_period:[],status:'',interval:'',email_time:'',regularity:'',employees:[],other_emails:[]
        }:{id:formSummaryEmail.id,type: 'calibration',name:formSummaryEmail.name,dates:JSON.parse(formSummaryEmail.data).dates,
            time_period:JSON.parse(formSummaryEmail.data).time_period.map((time_period) => ({name:time_period.name})),
            status:formSummaryEmail.status,interval:formSummaryEmail.interval,email_time:formSummaryEmail.email_time,
            regularity:formSummaryEmail.regularity,employees:formSummaryEmail.employees.map((employee) => ({name:employee.name,id:employee.id})),
            other_emails:formSummaryEmail.other_emails ? formSummaryEmail.other_emails.split(',') : [],
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
          let formData = new FormData();
          for (let value in values) {
                if(value=="employees"){
                    formData.append("employees", values.employees.map(employee => employee.id));
                }
                else if(value !== "dates" && value !== "time_period"){
                    formData.append(value, values[value]);
                } 
          }
          var combined = {
            dates: values.dates,
            time_period: values.time_period
        }        
        formData.append("data", JSON.stringify(combined));
          if(formType==="add"){
              postRequest('/api/summary_emails', formData,true)
              .then((response) => {
                  getRequest("/api/summary_emails").then((response) => {
                      dispatch({type:'load_summary_emails',newSummaryEmails:response.data});
                      if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                      if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                      setFormType("edit");
                      toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                  }); 
              }).catch((reason) => {
                  if(!navigator.online){
                      alert("Ste offline, dáta budu odoslané po znovupripojení!");
                  }
                  else{
                      alert("Daný úkon sa nepodaril!");
                  }
              });
          }
          else{
              postRequest(`/api/summary_emails/update/${formik.values.id}`, formData,true)
              .then((response) => {
                  getRequest("/api/summary_emails").then((response) => {
                      dispatch({type:'load_summary_emails',newSummaryEmails:response.data});
                      if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                      formik.setValues(values);
                      toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                  });
              }).catch((reason) => {
                  alert("Daný úkon sa nepodaril!");
              })
          }
      },
    });


    const weekTimePeriod = [
        {name: 'Pondelok'},
        {name: 'Utorok'},
        {name: 'Streda'},
        {name: 'Štvrtok'},
        {name: 'Piatok'},
        {name: 'Sobota'},
        {name: 'Nedeľa'},
    ];

    const monthTimePeriod = Array.from({length: 31}, (_, i) => i + 1).map((i) =>{
        return {name: ''+i};}
        
    )

    function emailValidation(email){

        // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ( re.test(email) ) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
            setThrowInvalidEmail(false);
            return true;
        }
        else {
            // invalid email, maybe show an error to the user.
            setThrowInvalidEmail(true);
            return false;
        }
    
    }


    return (
        <>
        <div className='w-full h-10 flex flex-row bg-[#333]'>
      <IntersectionObserverWrapper>
      <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="saveandclose"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="save"><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => submitRef.current.click()}><SaveIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewSummaryEmail()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="copy"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copySummaryEmail()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button onClick={() => openFavoriteDialog("Nový súhrnný e-mail","SummaryEmailCalibrationAdd")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="fast"><button onClick={() => setFastView("SummaryEmailCalibrationAdd",null,null)}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
        </IntersectionObserverWrapper>
      </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col justify-center overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   Súhrnný e-mail
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                        <div>
                        <label
                            for="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov
                        </label>
                        <input
                            name='name'
                            type="text"
                            required
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full px-4 py-2 mt-2 mb-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />     
                        </div>
                        <div>
                        <label
                            htmlFor="status"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Typ
                        </label>
                        <select 
                        name='status'
                        required
                        onChange={formik.handleChange} value={formik.values.status}
                        className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber možnosť</option>
                          <option value="Všetky">Všetky</option>
                          <option value="Kalibrované">Kalibrované</option>
                          <option value="Informatívne">Informatívne</option>
                          <option value="Kalibračné firmy">Kalibračné firmy</option>
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="interval"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Interval
                        </label>
                        <select 
                        name='interval' required
                        onChange={formik.handleChange} value={formik.values.interval}
                        className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber možnosť</option>
                          <option value="Všetky">Všetky</option>
                          <option value="30 dní do exp.">30 dní do exp.</option>
                          <option value="15 dní do exp.">15 dní do exp.</option>
                          <option value="Po expirácii">Po expirácii</option>
                        </select>
                        </div>
                        {/*formik.values.interval=="Vlastný" && <div>
                            <label
                                htmlFor="dates"
                                className="block text-md font-semibold text-gray-800"
                            >
                                OD - DO:
                            </label>
                            <Calendar required id="buttondisplay" name="dates" style={{border: '1px solid #e5e7eb',transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',borderRadius: '6px'}} 
                            value={formik.values.dates} onChange={(e) => formik.setFieldValue("dates",e.target.value)} showIcon className="w-full"locale="sk" dateFormat="dd.mm.yy" selectionMode="range" hideOnRangeSelection />
                        </div>*/}
                        <div>
                        <label
                            htmlFor="regularity"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Časový plán
                        </label>
                        <select 
                        name='regularity' required
                        onChange={(e) => {formik.setFieldValue("regularity",e.target.value);formik.setFieldValue("time_period",[]);}} value={formik.values.regularity}
                        className={`bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40`}>
                          <option value="">Vyber možnosť</option>
                          <option value="Denne">Denne</option>
                          <option value="Týždenne">Týždenne</option>
                          <option value="Mesačne">Mesačne</option>
                        </select>
                        </div>
                        {formik.values.regularity=="Mesačne" && <div>
                        <label
                            htmlFor="time_period"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Pravidelnosť
                        </label>
                        <MultiSelect required value={formik.values.time_period} onChange={(e) => formik.setFieldValue("time_period",e.value)} options={monthTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>}
                        {formik.values.regularity=="Týždenne" && <div>
                        <label
                            htmlFor="time_period"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Pravidelnosť
                        </label>
                        <MultiSelect required value={formik.values.time_period} onChange={(e) => formik.setFieldValue("time_period",e.value)} options={weekTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>}
                        <div>
                        <label
                            for="email_time"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ČAS ODOSLANIA E-MAILU
                        </label>
                        <input
                            name='email_time'
                            type="time"
                            min="05:00" max="22:00"
                            required
                            onChange={formik.handleChange} value={formik.values.email_time}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            for="employees"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ZAMESTNANCI
                        </label>
                        <MultiSelect value={formik.values.employees} onChange={(e) => formik.setFieldValue("employees",e.value)} 
                        options={state.employees.filter((employee) => employee.email != null && employee.email != "").map((employee) => 
                            ({name:employee.name,id:employee.id})
                        )} 
                        optionLabel="name" 
                        filter placeholder="Vyber používateľov" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div>
                        <label
                            for="other_emails"
                            className="block text-md font-semibold text-gray-800"
                        >
                            OSTATNÉ E-MAILY
                        </label>
                        <Chips value={formik.values.other_emails} onChange={(e) => formik.setFieldValue("other_emails",e.value)} onAdd={(e) => emailValidation(e.value)} separator="," className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        {throwInvalidEmail && <Message severity="error" text="Neplatný email"/>}
                        </div>
            </div>
            </div>
            </div>
            <button type="submit" ref={submitRef} className='hidden'></button>
            </form>
            </>
            );
} 
