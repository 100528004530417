import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, DeleteIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import "../../assets/css/docviewer.css";
import "@cyntler/react-doc-viewer/dist/index.css";


export default function TrainingTypeAdd({training_type, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const ownToast = useRef(null);

    const [formTrainingType,setformTrainingType] = useState(training_type);
    const [formType, setFormType] = useState(type);
    
    const [images,setImages] = useState('');
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [viewedImage,setViewedImage] = useState('');

    const [templateList,setTemplateList] = useState([]);
    const templateListRef = useRef(null);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit training"))?false:true);

    const [machines, setMachines] = useState(state.machines);

    useEffect(() => {
        // Update the document title using the browser API
        if(formTrainingType!=null){
            getImages();
            if(formTrainingType?.training_company_id && formTrainingType?.training_company_id>0){
                changeCompany(formTrainingType?.training_company_id);
            }
            if(formTrainingType?.machine_id && formTrainingType?.machine_id>0){
                formik.setFieldValue('machine_id',state.machines?.filter(machine => machine.id == formTrainingType?.machine_id )?.map(machine => ({name:machine?.name,id:machine?.id}))[0]);
            }
        }
    },[formTrainingType]);

    function getImages(id=null){
        getRequest(`/api/training_types/protocols/${id ? id : formTrainingType.id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }

    function addNewTrainingType(){
        dispatch({type:'create_new_tab',name:'Nový typ školenia',page:'TrainingTypeAdd',props:{training_type: null,type:'add'},mask:'skol006'})
      }
      function copyTrainingType(){
        dispatch({type:'create_new_tab',name:'Nový typ školenia',page:'TrainingTypeAdd',props:{training_type: formik.values,type:'add'},mask:'skol006'})
      }

    const validate = values => {
        const errors = {};
        if (values?.machines?.length<1) {
            errors.machines = '*Povinné';
        }
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues: formTrainingType==null?{
            name: '',department_id: '',type:'',interval:'',training_company_id:'',machines:[],employee_id:'',description:''}
            :{
                id:formTrainingType?.id,name: formTrainingType?.name,department_id: formTrainingType?.department_id,type:formTrainingType?.type,
                interval:formTrainingType?.interval,training_company_id:formTrainingType?.training_company_id,
                machines:formTrainingType.machines ? formTrainingType.machines.map((machine) => ({name:machine?.name,id:machine?.id})) : [],
                employee_id:formTrainingType?.employee_id ? state.employees?.filter(employee => employee.id == formTrainingType?.employee_id )?.map(employee => ({name:employee?.name,id:employee?.id}))[0] : '',
                description:formTrainingType?.description,
            },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            delete values?.files;
            for (let value in values) {
                if(value=="employee_id"){
                    formData.append(value, values[value]?.id ? values[value]?.id : '');
                }
                else if(value=="machines"){
                    formData.append("machines", values.machines.map(machine => machine.id));
                }
                else formData.append(value, values[value] != null ? values[value] : "");
            }
            for(let i = 0;i<templateList.length;i++){
                formData.append('files[]',templateList[i]);
            }
            if(formType=="add"){
                postRequest('/api/training_types', formData,true)
                .then((response_first) => {
                    console.log(response_first);
                    getRequest("/api/training_types").then((response) => {
                        dispatch({type:'load_training_types',newTrainingTypes:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        setFormType("edit");
                        if(templateList.length){
                            getImages(response_first.data.id);
                            setTemplateList([]);
                            templateListRef.current.value = null;
                        }
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/training_types/update/${formik.values.id}`, formData,true)
                .then((response_first) => {
                    getRequest("/api/training_types").then((response) => {
                        dispatch({type:'load_training_types',newTrainingTypes:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        if(templateList.length){
                            getImages(response_first.data.id);
                            setTemplateList([]);
                            templateListRef.current.value = null;
                        }
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function getIndex(training_company_id) {
        return state.training_types.findIndex(obj => obj.id === training_company_id);
    }

    async function scrollToFirst(){
        let my_training_type = state.training_types[0];
        setformTrainingType(my_training_type);
    }
    
    async function scrollUp(){
        let id = getIndex(formik.values.id);
        if(id-1>=0){
            setformTrainingType(state.training_types[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formik.values.id);
        if(id+1<state.training_types.length){
            setformTrainingType(state.training_types[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.training_types.length-1;
        let my_training_type = state.training_types[last];
        setformTrainingType(my_training_type);
    }

    const handleTemplateChange = (event) => {
        setTemplateList(event.target.files);
    };

    const accept = (id) => {
        getRequest(`/api/training_types/deleteProtocol/${id}`)
        .then((response) => {
              if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
              else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
              getImages();
          }).catch((reason) => {
              alert(reason);
              if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
              else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
          })
      };

      const reject = () => {
          if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
          else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
      }
  

  function deleteFiles(img){
      confirmDialog({
          message: 'Prajete si vymazať tento súbor?',
          header: 'Potvrdenie vymazania',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Nie',
          rejectLabel: 'Áno',
          accept:reject,
          reject:() => accept(img.id)
      });
  }

    function changeCompany(company_id){
        formik.setFieldValue('training_company_id',company_id);
        const machines = [];
        state.company_contacts.filter(contact => contact.company_id == company_id)?.forEach(contact => {
            contact?.machines?.map(machine => machines.push(machine.id));
        });
        setMachines(state.machines.filter(machine => machines.includes(machine.id)));
        formik.setFieldValue('machine_id','');
    }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTrainingType()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyTrainingType()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová typ školenia","TrainingTypeAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingTypeAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col overflow-x min-h-[calc(100vh-177px)]">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   TYP ŠKOLENIA
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formik.values.id}</label>}
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV*
                        </label>
                        <input
                            name='name'
                            type="text" required
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="department_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ODDELENIE
                        </label>
                        <select 
                        name='department_id'
                        onChange={formik.handleChange} value={formik.values.department_id}

                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                          ))}
                        </select>
                        </div>
                    
                      <div>
                        <label
                            htmlFor="type"
                            className="block text-md font-semibold text-gray-800"
                        >
                            DRUH 
                        </label>
                        <input
                            name='type'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.type}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="interval"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            INTERVAL (v mesiacoch)*
                        </label>
                        <input
                            name='interval'
                            type="number" required
                            onChange={formik.handleChange} value={formik.values.interval}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>        
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                       <div>
                        <label
                            htmlFor="training_company_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ŠKOL. FIRMA*
                        </label>
                        <select 
                        name='training_company_id'
                        onChange={(e) => changeCompany(e.target.value)} value={formik.values.training_company_id}
                        required
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Vyber škol. firmu</option>
                          {state.training_companies.map((training_company) => (
                            <option key={training_company.id} value={training_company.id}>{training_company.name}</option>
                          ))}
                        </select>
                        </div>
                        <div>
                        <label
                            htmlFor="machines"
                            className="block text-md font-semibold text-gray-800"
                        >
                            STROJ*
                        </label>
                        <MultiSelect value={formik.values.machines} onChange={(e) => formik.setFieldValue("machines",e.value)} 
                            options={state.machines.map((machine) => 
                                ({name:machine.name,id:machine.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber stroj/e" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        {formik.errors?.machines ? <span style={{color: "red"}}>{formik.errors?.machines}</span> : ""}
                        </div> 
                        <div>
                        <label
                            htmlFor="employee_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                             ZODPOVEDNÁ OSOBA
                        </label>
                        <Dropdown value={formik.values.employee_id} onChange={(e) => formik.setFieldValue('employee_id',e.value)} 
                             options={state.employees.filter(employee => employee?.date_of_departure=="" || employee?.date_of_departure==null).map((employee) => 
                                ({name:employee.name,id:employee.id})
                            )} 
                            optionLabel="name" showClear 
                            filter placeholder="Vyber zamestnanca" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POPIS
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>
                        <div>
                        <label
                            htmlFor="template"
                            className="block text-md font-semibold text-gray-800"
                        >
                            PREDLOHA
                        </label>
                        <input
                            name='files' 
                            ref={templateListRef}
                            onChange={handleTemplateChange}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                            type='file'
                            multiple="multiple"
                            id='template'/>
                        </div>
                        <div>
                        <label
                            htmlFor="images"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Predloha:
                        </label>
                        {images!=='' && images.map((img) => (<table className='w-full border border-separate border-gray-400 rounded-md bg-white mb-4'>
                            <tr>
                                <td className='text-black h-10'><div className='flex flex-row p-2'>
                                    <div onClick={() => setVisibleFiles(true)}>
                                        {img.filename.includes(".docx") ?<img src={require('./../../assets/img/word.png')} style={{ height: '65px', width: 'auto'}} alt="Word Logo"/> :<embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>}
                                    </div>
                                        <div className='flex flex-col ml-4'>
                                        <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                                        <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                                        </div>
                                    </div></td>
                            </tr>
                        </table>))}
                        </div>
                        <button className='hidden' type='submit' ref={submitRef}></button>    
            </div>
            </div>
            </div> 
             </form>
             <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
             {viewedImage?.filename?.includes(".docx") ? <DocViewer prefetchMethod="GET" pluginRenderers={DocViewerRenderers} documents={[{uri:viewedImage.url}]} style={{width:'100%',height:'70vh'}} /> : <iframe src={viewedImage.url} className='w-full h-full'></iframe>}
            </Dialog>
            </div>
            </> 
            );
} 
