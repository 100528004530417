import { fileTextIcon, paletteIcon, penRulerIcon, settingsIcon, brushIcon,calIcon, TrainingIcon ,PrioIcon, ServiceIcon} from "./menuIcons";

export const menuItems = [
  {
    title: 'Priority',
    id: '1',
    icon: PrioIcon,
    permission: 'access priorities',
    children: [
      {
        title: 'Zoznam priorít',
        id: '1_1',
        page: 'PriorityList',
        mask: 'prio001'
      },
      {
        title: 'Zoznam priorít malý',
        id: '1_2',
        page: 'PriorityListMobile',
        mask: 'prio002'
      },
      {
        title: 'Manuálna priorita',
        id: '1_3',
        page: 'PriorityAdd',
        permission: 'edit priorities',
        mask: 'prio003'
      },
      {
        title: 'Nastavenia',
        id: '1_4',
        permission: 'edit priorities',
        children: [
          {
            title: 'Oddelenia priorít',
            id: '1_4_1',
            page: 'PriorityDepartmentList',
            mask: 'prio004'
            //add - prio007
          },
          {
            title: 'Nast. aut. priorít',
            id: '1_4_2',
            page: 'PriorityLoadAutomaticList',
            mask: 'prio005'
          },
          {
            title: 'Nast. man. priorít',
            id: '1_4_3',
            page: 'PriorityLoadManuallyList',
            mask: 'prio006'
          },
        ],
      }
    ],
  },
  {
    title: 'OTK',
    id: '2',
    icon: penRulerIcon,
    permission: 'access otk-section1',
    children: [
      {
        title: 'Náhľad - OTK',
        id: '2_1',
        page: 'PreviewOTK',
        mask: 'otk001'
      },
      {
        title: 'Interná chyba',
        id: '2_2',
        page: 'ZoznamInt',
        mask: 'otk002'
        //add 03
      },
      {
        title: 'Externá chyba',
        id: '2_3',
        page: 'ZoznamExt',
        mask: 'otk004'
        //add 05
      },
      {
        title: 'Reklamácia',
        id: '2_4',
        page: 'ZoznamRek',
        mask: 'otk006'
        //add 07
      },
      {
        title: 'Fotodokumentácia',
        id: '2_5',
        children: [
          {
            title: 'Dokumentácia',
            id: '2_5_1',
            page: 'Documentation',
            mask: 'otk008'
            //add 09
            //edit 10
          },
          {
            title: 'Merací protokol',
            id: '2_5_2',
            page: 'MeasurementProtocol',
            mask: 'otk011'
          },
          {
            title: 'Sebakontrola',
            id: '2_5_3',
            page: 'SelfControl',
            mask: 'otk012'
          },
          {
            title: 'NDT Protokol',
            id: '2_5_4',
            page: 'NDTProtocol',
            mask: 'otk013'
          },
          {
            title: 'Žíhací protokol',
            id: '2_5_5',
            page: 'AnnealingProtocol',
            mask: 'otk014'
          },
        ],
      },
      {
        title: 'Výstup',
        id: '2_11',
        children: [
          {
            title: 'Štatistika',
            id: '2_11_1',
            page: 'OTKStatistics',
            mask: 'otk015'
          },
        ],
      },
      {
        title: 'Nastavenia',
        id: '2_10',
        permission: 'edit otk-section2',
        children: [
          {
            title: 'Druh chyby',
            id: '2_10_1',
            page: 'ErrorTypeList',
            mask: 'otk016'
            //add,edit 017
          },
          {
            title: 'Skupina',
            id: '2_10_2',
            page: 'GroupList',
            mask: 'otk018'
            //add,edit 019
          },
          {
            title: 'Zabránenie',
            id: '2_10_3',
            page: 'PreventionList',
            mask: 'otk020'
            //add,edit 021
          },
          {
            title: 'Náročnosť dielu',
            id: '2_10_4',
            page: 'MeasureComplexityList',
            mask: 'otk022'
            //add,edit 023
          },
          {
            title: 'Emaily',
            id: '2_10_5',
            permission: 'delete otk-section2',
            page: 'SummaryEmailOTKList',
            mask: 'otk024'
            //add,edit 025
          },
        ],
      }
    ],
  },
  {
    title: 'Lakovňa',
    id: '3',
    icon: brushIcon,
    permission: 'access painting',
    children: [
      {
        title: 'Náhľad - Lak',
        id: '3_1',
        page: 'PreviewLak',
        mask:'lak001'
      },
      {
        title: 'Podmienky lakovania',
        id: '3_2',
        page: 'PaintingConditionList',
        mask:'lak002'
      },
      {
        title: 'Nastavenia',
        id: '3_3',
        permission: 'edit painting',
        children: [
          {
            title: 'Časové podmienky',
            id: '3_3_1',
            permission: 'delete painting',
            page: 'PaintingTimeConditionList',
            mask:'lak004'
          },
          {
            title: 'Upozornenia užívateľom',
            id: '3_3_2',
            permission: 'delete painting',
            page: 'PaintingEmployeeConditionList',
            mask:'lak006'
          },
          {
            title: 'Intervaly',
            id: '3_3_3',
            page: 'PaintingIntervalList',
            mask:'lak008'
          },
          {
            title: 'Počasie',
            id: '3_3_4',
            page: 'WeatherList',
            mask:'lak010'
          },
          {
            title: 'Emaily',
            id: '3_3_5',
            permission: 'delete painting',
            page: 'SummaryEmailPaintingList',
            mask:'lak012'
          },
        ],
      },
    ],
  },
  {
    title: 'Kalibrácia',
    id: '4',
    icon: calIcon,
    permission: 'access calibration',
    children: [
      {
        title: 'Náhľad - Kal',
        id: '4_1',
        page: 'PreviewCal',
        mask:'kal001'
      },
      {
        title: 'Zoznam meradiel',
        id: '4_2',
        page: 'GaugeList',
        mask:'kal002'
      },
      {
        title: 'Zoznam kalibrácií',
        id: '4_3',
        page: 'GaugeCalibrationList',
        mask:'kal004'
      },
      {
        title: 'Kalibračné firmy',
        id: '4_4',
        children: [
          {
            title: 'Certifikáty',
            id: '4_4_1',
            page: 'CalibrationCompanyCertificateList',
            mask:'kal008'
          },
          {
            title: 'Zoznam certifikátov',
            id: '4_4_2',
            children: [
              {
                title: 'Podľa firmy',
                page: 'CalibrationCompanyCertRecordList',
                id: '4_4_2_1',
                mask:'kal010' //detail 11 add12
              },
              {
                title: 'Podľa stavu',
                page: 'CalibrationCompanyCertStateList',
                id: '4_4_2_2',
                mask:'kal013'
              },
              {
                title: 'Zaznamenať certifikát',
                page: 'CalibrationCompanyCertRecordAdd',
                id: '4_4_2_3',
                mask:'kal012'
              },
            ]
          },
        ],
      },
      {
        title: 'Nastavenia',
        id: '4_5',
        permission: 'delete calibration',
        children: [
          {
            title: 'Emaily',
            id: '4_5_1',
            page: 'SummaryEmailCalibrationList',
            mask:'kal014'
          },
        ],
      },
    ],
  },
  {
    title: 'Školenie',
    icon: TrainingIcon,
    id: '5',
    permission: 'access training',
    children: [
      {
        title: 'Náhľad školení',
        page: 'PreviewTraining',
        id: '5_1',
        mask:'skol001'
      },
      {
        title: 'Karty zamestnancov',
        page: 'TrainingCardList',
        id: '5_2',
        mask:'skol002' //add 003 //detail 004
      },
      {
        title: 'Typy školení',
        id: '5_3',
        page: 'TrainingTypeList',
        mask:'skol005' //add 006
      },
      {
        title: 'Šablóny školení',
        id: '5_4',
        children: [
          {
            title: 'Zoznam šablón',
            id: '5_4_1',
            page: 'TrainingTemplateList',
            mask:'skol007' //add 008
          },
          {
            title: 'Priradenie školení',
            id: '5_4_2',
            page: 'TrainingTemplateRecordList',
            mask:'skol009' //add 010
          },
        ],
      },
      {
        title: 'Zoznam školení',
        id: '5_5',
        children: [
          {
            title: 'Podľa zamestnanca',
            page: 'TrainingRecordList',
            id: '5_5_1',
            mask:'skol011' //add 012 nizsie //detail 13
          },
          {
            title: 'Podľa školenia',
            page: 'TrainingEmployeeList',
            id: '5_5_2',
            mask:'skol014' //detail 015
          },
          {
            title: 'Podľa stavu',
            page: 'TrainingStateList',
            id: '5_5_3',
            mask:'skol016'
          },
          {
            title: 'Zaznamenať školenie',
            page: 'TrainingRecordAdd',
            id: '5_5_4',
            mask:'skol012'
          },
        ],
      },
      {
        title: 'Školiace firmy',
        id: '5_7',
        children: [
          {
            title: 'Karty firiem',
            id: '5_7_1',
            children: [
              /*{
                title: 'Zoznam firiem',
                page: 'TrainingCompanyList',
                id: '5_7_1_1',
                mask:'skol0017' //add 18
              },*/
              {
                title: 'Školitelia',
                page: 'TrainingCompanyContactList',
                id: '5_7_1_2',
                mask:'skol0019' //add 20
              },
              {
                title: 'Certifikáty',
                page: 'TrainingCompanyCertificateList',
                id: '5_7_1_3',
                mask:'skol0021' //add 22
              },
            ]
          },
          {
            title: 'Zoznam certifikátov',
            id: '5_7_2',
            children: [
              {
                title: 'Podľa firmy',
                page: 'TrainingCompanyCertRecordList',
                id: '5_7_2_1',
                mask:'skol0023' //detail 25
              },
              {
                title: 'Podľa stavu',
                page: 'TrainingCompanyCertStateList',
                id: '5_7_2_2',
                mask:'skol0026'
              },
              {
                title: 'Zaznamenať certifikát',
                page: 'TrainingCompanyCertRecordAdd',
                id: '5_7_2_3',
                mask:'skol024'
              },
            ]
          },
        ],
      },
      {
        title: 'Pozvánky',
        id: '5_8',
        children: [
          {
            title: 'Zoznam',
            page: 'TrainingInvitationList',
            id: '5_8_1',
            mask:'skol0027' //add 28
          },
          {
            title: 'Vzory',
            page: 'TrainingInvitationTemplateList',
            id: '5_8_2',
            mask:'skol0029' //add 30
          },
        ],
      },
      {
        title: 'Nastavenia',
        id: '5_9',
        permission: 'delete training',
        children: [
          {
            title: 'Emaily',
            id: '5_9_1',
            page: 'SummaryEmailTrainingList',
            mask:'skol0031' //add 32
          },
          {
            title: 'Všeobecné',
            id: '5_9_2',
            page: 'TrainingGeneralSettings',
            mask:'skol0033'
          },
          {
            title: 'Lektori',
            id: '5_9_2',
            page: 'LecturerList',
            mask:'skol0034'
          },
        ],
      },
    ],
  },
  {
    title: 'ServisAPP',
    id: '7',
    icon: ServiceIcon,
    children: [
      /*{
        title: 'Náhľad',
        id: '7_1',
        page: 'DepartmentList',
        //permission: 'access users',
        mask:'serv001'
      },*/
      {
        title: 'Zadanie',
        id: '7_2',
        page: 'ServiceTaskAdd',
        //permission: 'access users',
        mask:'serv002'
      },
      {
        title: 'Zoznam',
        id: '7_3',
        page: 'ServiceTaskList',
        mask:'serv003'
      },
      {
        title: 'Nastavenia',
        id: '7_4',
        permission: 'access purchase-sp',
        children: [
          {
            title: 'Všeobecné',
            id: '7_4_1',
            page: 'ServiceUsersSettings',
            //permission: 'access users',
            mask:'serv005'
          },
        ]
      },
    ],
  },
  {
    title: 'Glob. nastavenia',
    id: '6',
    icon: settingsIcon,
    permission: 'access settings',
    children: [
        {
          title: 'Personálne',
          id: '6_1',
          permission: 'access settings',
          children: [
            {
              title: 'Oddelenie',
              id: '6_1_1',
              page: 'DepartmentList',
              permission: 'add settings',
              mask:'glo001'
            },
            {
              title: 'Stroje',
              id: '6_1_5',
              page: 'MachineList',
              permission: 'access maintenance',
              mask:'glo013'
            },
            {
              title: 'Druh stroja',
              id: '6_1_6',
              page: 'MachineTypeList',
              permission: 'access maintenance',
              mask:'glo015'
            },
            {
              title: 'Zamestnanec',
              id: '6_1_2',
              page: 'EmployeeList',
              permission: 'add settings',
              mask:'glo003'
            },
            {
              title: 'Obchodní partneri',
              id: '6_1_3',
              page: 'BusinessPartnerList',
              permission: 'add settings',
              mask:'glo007'
            },
            /*{
              title: 'Zákazník',
              id: '6_1_4',
              page: 'CustomerList',
              permission: 'add settings',
              mask:'glo009'
            },*/
          ],
        },
        {
          title: 'Používatelia',
          id: '6_2',
          permission: 'access users',
          children: [
            {
              title: 'Používatelia',
              id: '6_2_1',
              page: 'UserList',
              permission: 'access users',
              mask:'glo005'
            },
            {
              title: 'Používateľské role',
              id: '6_2_2',
              page: 'RoleList',
              permission: 'access users',
              mask:'glo011'
            },
          ],
        },
        
      ],
  },
];
