import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { deleteRequest, getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, DeleteIcon, InfoIcon, TaskIcon, ErrorIcon, PermissionIcon, NeedIcon, BorrowItemIcon, EmergencyIcon,ServiceSettingIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { MultiSelect } from 'primereact/multiselect';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import { SelectButton } from 'primereact/selectbutton';
import { Avatar } from 'primereact/avatar';
import { Select } from "antd";
import { confirmDialog } from 'primereact/confirmdialog';
import { OrderList } from 'primereact/orderlist';


export default function ServiceUsersSettings() {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView,getUserColor,getUserInitials,setDialogText,setVisible} = useContext(UtilContext);
    const submitRef = useRef(null);
    const ownToast = useRef(null);

    const [addedUser,setAddedUser] = useState("");
    const [addedLevel,setAddedLevel] = useState("")
    const [showDetail,setShowDetail] = useState(false);
    const [showAddUser,setShowAddUser] = useState(false);
    const [showAddLevel, setShowAddLevel] = useState(false);
    const [levels, setLevels] = useState([
        {id:1,name:"Úroveň 1"},
        {id:2,name:"Úroveň 2"},
        {id:3,name:"Úroveň 3"},
        {id:4,name:"Úroveň 4"},
    ]);
    const [changeOrderOfLevel,setChangeOrderOfLevel] = useState(false);

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
            initialValues: {
                level_id:'',user_id:'',department_id:'',user_department:''
            },
            enableReinitialize:true,
            validate,
            onSubmit: values => {
                setDialogText("Ukladám...");
                setVisible(true);
                let formData = new FormData();
                for (let value in values) {   
                    formData.append(value, values[value] != null ? values[value] : "");
                }
                for (var pair of formData.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]);
                }
                postRequest('/api/service_user_settings', formData,true)
                .then((response) => {
                    setShowAddUser(false);
                    getRequest("/api/service_user_settings").then((response) => {
                        dispatch({type:'load_service_user_settings',newServiceUserSettings:response.data});
                        formik.resetForm();
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Používateľ bol úspešne pridaný!" });
                    });
                    setVisible(false);
                }).catch((reason) => {
                    setShowAddUser(false);
                    setVisible(false);
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                });
          },
        });

        const formikLevel = useFormik({
            initialValues: {
                name:'', type:'',task:'',
            },
            enableReinitialize:true,
            validate,
            onSubmit: values => {
                setDialogText("Ukladám...");
                setVisible(true);
                let formData = new FormData();
                for (let value in values) {   
                    formData.append(value, values[value] != null ? values[value] : "");
                }
                for (var pair of formData.entries()) {
                    console.log(pair[0]+ ', ' + pair[1]);
                }
                postRequest('/api/service_levels', formData,true)
                .then((response) => {
                    getRequest("/api/service_levels").then((response) => {
                        dispatch({type:'load_service_levels',newServiceLevels:response.data});
                        formikLevel.resetForm();
                        toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Používateľ bol úspešne pridaný!" });
                    });
                    setVisible(false);
                    setShowAddLevel(false);
                    changeOrderOfLevel(false);
                }).catch((reason) => {
                    setVisible(false);
                    setShowAddLevel(false);
                    changeOrderOfLevel(false);
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                });
          },
        });


    const reject = () => {
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
        }
        
    
    const accept = (id) => {
        deleteRequest(`/api/service_user_settings/${id}`)
        .then((response) => {
            if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Nastavenie bolo úspešne vymazané!" });
            getRequest("/api/service_user_settings").then((response) => {
                dispatch({type:'load_service_user_settings',newServiceUserSettings:response.data});
            });
        }).catch((reason) => {
            alert(reason);
            if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Nastavenie sa nepodarilo vymazať!', life: 3000 });
        })
    };
    

    function deleteSetting(id){
        confirmDialog({
            message: 'Prajete si vymazať nastavenie?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept:reject,
            reject:() => accept(id)
        });
    }

    const itemTemplate = (item) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                    <span className="font-bold">{item.name}</span>
                </div>
                <span className="font-bold text-900">{item.priority}</span>
            </div>
        );
    };

    function setRatings(levels){
        let formData = new FormData();
        formData.append("levels", levels.map(level => level.id));
        postRequest('/api/service_levels/set_ratings', formData,true)
        .then((response) => {
            getRequest("/api/service_levels").then((response) => {
                dispatch({type:'load_service_levels',newServiceLevels:response.data});
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Poradie nastavené!" });
            });
            setChangeOrderOfLevel(false);
        }).catch((reason) => {
            alert("Daný úkon sa nepodaril!");
            setChangeOrderOfLevel(false);
        });
    }

    function getTask(task){
        if(task=="approval")return "schvaľovanie";
        else if(task=="notification")return "notifikácie";
        else if(task=="management")return "spravovanie";
        else if(task=="creation")return "vytvorenie";
        return "";
    }


    return ( 
        <>
        <Toast ref={ownToast}/>
        <div className="h-[calc(100vh-136px)] bg-gray-100 overflow-y-auto overflow-x-hidden">
        <div className='xl:columns-3 lg:columns-2 gap-4 space-y-4 m-4'>
            <div className="w-auto p-6 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200 break-inside-avoid">
                <div className='flex flex-row justify-between items-center mb-8'>
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900">
                   <InfoIcon width={8} height={8}/>OZNAMY
                </h1>
                <button type="button" onClick={() => {setLevels(state.service_levels.filter(level => level.type=='Info'));setChangeOrderOfLevel(true);}}>
                <ServiceSettingIcon/>
                </button>
                </div>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <div className='text-gray-800 mb-5'>
                            <button type="button" onClick={() => {formikLevel.resetForm();formikLevel.setFieldValue("type","Info");setShowAddLevel(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať úroveň schvaľovania
                            </button>
                        </div>
                    {state.service_levels.filter(setting => setting.type=="Info").map(level => 
                        <><div className='flex'><label className='font-bold mb-1 text-lg mr-1'>{level?.name}</label><label className='font-normal mb-1 text-lg'>{' - '+getTask(level?.task)}</label></div>
                        <div className='h-auto overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                        {state.service_user_settings.filter(setting => setting.level_id==level.id).map(setting => 
                            <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username || setting?.department?.name), color: '#ffffff',marginRight: '5px'}}/>
                                    <div className='flex justify-left flex-col justify-center'>
                                        <span>{setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name}</span>
                                    </div>
                                </div>
                                <div className='px-1'>
                                    <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                </div>
                            </div>
                        )}
                        <div className='text-gray-800'>
                        <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("level_id",level?.id);setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                            <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie/používateľa
                        </button>
                        </div>
                        </div></>
                    )}
                    </div>
                    </div>
                </div>
                <div className="w-auto p-6 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200 break-inside-avoid">
                <div className='flex flex-row justify-between items-center mb-8'>
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900">
                   <TaskIcon width={8} height={8}/>ÚLOHY
                </h1>
                <button type="button" onClick={setChangeOrderOfLevel}>
                <ServiceSettingIcon/>
                </button>
                </div>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <div className='text-gray-800 mb-5'>
                            <button type="button" onClick={() => {formikLevel.resetForm();formikLevel.setFieldValue("type","Task");setShowAddLevel(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať úroveň schvaľovania
                            </button>
                        </div>
                    {state.service_levels.filter(setting => setting.type=="Task").map(level => 
                        <><div className='flex'><label className='font-bold mb-1 text-lg mr-1'>{level?.name}</label><label className='font-normal mb-1 text-lg'>{' - '+getTask(level?.task)}</label></div>
                        <div className='h-auto overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                        {state.service_user_settings.filter(setting => setting.level_id==level.id).map(setting => 
                            <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username || setting?.department?.name), color: '#ffffff',marginRight: '5px'}}/>
                                    <div className='flex justify-left flex-col justify-center'>
                                        <span>{setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name}</span>
                                    </div>
                                </div>
                                <div className='px-1'>
                                    <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                </div>
                            </div>
                        )}
                        <div className='text-gray-800'>
                        <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("level_id",level?.id);setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                            <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie/používateľa
                        </button>
                        </div>
                        </div></>
                    )}
                    </div>
                    </div>
                </div>
                <div className="w-auto p-6 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200 break-inside-avoid">
                <div className='flex flex-row justify-between items-center mb-8'>
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900">
                   <ErrorIcon width={8} height={8}/>PORUCHY
                </h1>
                <button type="button" onClick={setChangeOrderOfLevel}>
                <ServiceSettingIcon/>
                </button>
                </div>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-8">
                    <div className='text-gray-800 mb-5'>
                            <button type="button" onClick={() => {formikLevel.resetForm();formikLevel.setFieldValue("type","Error");setShowAddLevel(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať úroveň schvaľovania
                            </button>
                        </div>
                    {state.service_levels.filter(setting => setting.type=="Error").map(level => 
                        <><div className='flex'><label className='font-bold mb-1 text-lg mr-1'>{level?.name}</label><label className='font-normal mb-1 text-lg'>{' - '+getTask(level?.task)}</label></div>
                        <div className='h-auto overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                        {state.service_user_settings.filter(setting => setting.level_id==level.id).map(setting => 
                            <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username || setting?.department?.name), color: '#ffffff',marginRight: '5px'}}/>
                                    <div className='flex justify-left flex-col justify-center'>
                                        <span>{setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name}</span>
                                    </div>
                                </div>
                                <div className='px-1'>
                                    <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                </div>
                            </div>
                        )}
                        <div className='text-gray-800'>
                        <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("level_id",level?.id);setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                            <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie/používateľa
                        </button>
                        </div>
                        </div></>
                    )}
                    </div>
                    </div>
                </div>
                <div className="w-auto p-6 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200 break-inside-avoid">
                <div className='flex flex-row justify-between items-center mb-8'>
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900">
                   <PermissionIcon width={8} height={8}/>POVOLENIA
                </h1>
                <button type="button" onClick={setChangeOrderOfLevel}>
                <ServiceSettingIcon/>
                </button>
                </div>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <div className='text-gray-800 mb-5'>
                            <button type="button" onClick={() => {formikLevel.resetForm();formikLevel.setFieldValue("type","Permission");setShowAddLevel(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať úroveň schvaľovania
                            </button>
                    </div>
                    {state.service_levels.filter(setting => setting.type=="Permission").map(level => 
                        <><div className='flex'><label className='font-bold mb-1 text-lg mr-1'>{level?.name}</label><label className='font-normal mb-1 text-lg'>{' - '+getTask(level?.task)}</label></div>
                        <div className='h-auto overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                        {state.service_user_settings.filter(setting => setting.level_id==level.id).map(setting => 
                            <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username || setting?.department?.name), color: '#ffffff',marginRight: '5px'}}/>
                                    <div className='flex justify-left flex-col justify-center'>
                                        <span>{setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name}</span>
                                    </div>
                                </div>
                                <div className='px-1'>
                                    <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                </div>
                            </div>
                        )}
                        <div className='text-gray-800'>
                        <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("level_id",level?.id);setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                            <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie/používateľa
                        </button>
                        </div>
                        </div></>
                    )}
                    </div>
                    </div>
                </div>
                <div className="w-auto p-6 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200 break-inside-avoid">
                <div className='flex flex-row justify-between items-center mb-8'>
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900">
                   <NeedIcon width={8} height={8}/>NÁKUPY
                </h1>
                <button type="button" onClick={setChangeOrderOfLevel}>
                <ServiceSettingIcon/>
                </button>
                </div>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <div className='text-gray-800 mb-5'>
                            <button type="button" onClick={() => {formikLevel.resetForm();formikLevel.setFieldValue("type","Need");setShowAddLevel(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať úroveň schvaľovania
                            </button>
                        </div>
                    {state.service_levels.filter(setting => setting.type=="Need").map(level => 
                        <><div className='flex'><label className='font-bold mb-1 text-lg mr-1'>{level?.name}</label><label className='font-normal mb-1 text-lg'>{' - '+getTask(level?.task)}</label></div>
                        <div className='h-auto overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                        {state.service_user_settings.filter(setting => setting.level_id==level.id).map(setting => 
                            <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username || setting?.department?.name), color: '#ffffff',marginRight: '5px'}}/>
                                    <div className='flex justify-left flex-col justify-center'>
                                        <span>{setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name}</span>
                                    </div>
                                </div>
                                <div className='px-1'>
                                    <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                </div>
                            </div>
                        )}
                        <div className='text-gray-800'>
                        <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("level_id",level?.id);setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                            <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie/používateľa
                        </button>
                        </div>
                        </div></>
                    )}
                    </div>
                    </div>
                </div>
                <div className="w-auto p-6 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200 break-inside-avoid">
                <div className='flex flex-row justify-between items-center mb-8'>
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900">
                   <BorrowItemIcon width={8} height={8}/>POŽIČOVŇA
                </h1>
                <button type="button" onClick={setChangeOrderOfLevel}>
                <ServiceSettingIcon/>
                </button>
                </div>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <div className='text-gray-800 mb-5'>
                            <button type="button" onClick={() => {formikLevel.resetForm();formikLevel.setFieldValue("type","Borrow");setShowAddLevel(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať úroveň schvaľovania
                            </button>
                        </div>
                    {state.service_levels.filter(setting => setting.type=="Borrow").map(level => 
                        <><div className='flex'><label className='font-bold mb-1 text-lg mr-1'>{level?.name}</label><label className='font-normal mb-1 text-lg'>{' - '+getTask(level?.task)}</label></div>
                        <div className='h-auto overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                        {state.service_user_settings.filter(setting => setting.level_id==level.id).map(setting => 
                            <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username || setting?.department?.name), color: '#ffffff',marginRight: '5px'}}/>
                                    <div className='flex justify-left flex-col justify-center'>
                                        <span>{setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name}</span>
                                    </div>
                                </div>
                                <div className='px-1'>
                                    <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                </div>
                            </div>
                        )}
                        <div className='text-gray-800'>
                        <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("level_id",level?.id);setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                            <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie/používateľa
                        </button>
                        </div>
                        </div></>
                    )}
                    </div>
                    </div>
                </div>
                <div className="w-auto p-6 bg-white rounded-md shadow-md bg-gradient-to-r from-gray-50 via-gray-100 to-gray-200 break-inside-avoid">
                <div className='flex flex-row justify-between items-center mb-8'>
                <h1 className="flex flex-row items-center text-3xl font-semibold text-left text-zinc-900">
                   <EmergencyIcon width={8} height={8}/>NÚDZOVÉ SPRÁVY
                </h1>
                <button type="button" onClick={setChangeOrderOfLevel}>
                <ServiceSettingIcon/>
                </button>
                </div>
                <div className='flex flex-col'>
                    <div className="flex flex-col mb-2">
                    <div className='text-gray-800 mb-5'>
                            <button type="button" onClick={() => {formikLevel.resetForm();formikLevel.setFieldValue("type","Emergency");setShowAddLevel(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                                <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať uroveň schvaľovania
                            </button>
                        </div>
                    {state.service_levels.filter(setting => setting.type=="Emergency").map(level => 
                        <><div className='flex'><label className='font-bold mb-1 text-lg mr-1'>{level?.name}</label><label className='font-normal mb-1 text-lg'>{' - '+getTask(level?.task)}</label></div>
                        <div className='h-auto overflow-y-auto overflow-x-hidden flex flex-col w-full mb-5'>
                        {state.service_user_settings.filter(setting => setting.level_id==level.id).map(setting => 
                            <div className='flex justify-between items-start gap-10 text-gray-800 mb-2'>
                                <div className='flex flex-row'><Avatar className='p-overlay-badge' title={setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name} label={getUserInitials(setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name)} shape="circle" style={{ backgroundColor: getUserColor(setting?.user?.username || setting?.department?.name), color: '#ffffff',marginRight: '5px'}}/>
                                    <div className='flex justify-left flex-col justify-center'>
                                        <span>{setting?.user?.employee?.name || setting?.user?.username || setting?.department?.name}</span>
                                    </div>
                                </div>
                                <div className='px-1'>
                                    <button type="button" onClick={() => deleteSetting(setting.id)}><i className="pi pi-times-circle cursor-pointer" title='Vymazať' style={{ fontSize: '1.5rem' }}></i></button>
                                </div>
                            </div>
                        )}
                        <div className='text-gray-800'>
                        <button type="button" onClick={() => {formik.resetForm();formik.setFieldValue("level_id",level?.id);setShowAddUser(true)}} className='flex items-center gap-2 disabled:opacity-25'>
                            <i className='pi pi-plus mr-2 px-1' style={{fontSize:"1.5rem"}}></i>Pridať oddelenie/používateľa
                        </button>
                        </div>
                        </div></>
                    )}
                    </div>
                    </div>
                </div>
            </div>
            </div>
            <button className='hidden' type='submit' ref={submitRef}></button>
            <Dialog header="Pridanie oddelenia/používateľa" visible={showAddUser} style={{ width: '50vw' }} onHide={() => {setShowAddUser(false);setAddedUser("");}}>
                <form onSubmit={formik.handleSubmit}>
                <div className='w-auto flex flex-col'>
                <select 
                name='user_department'
                onChange={(e) => {formik.setFieldValue("user_department",e.target.value);formik.setFieldValue("user_id","");formik.setFieldValue("department_id","");}} 
                value={formik.values.user_department}
                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                    <option value="">Vyber možnosť</option>
                    <option value="department">Oddelenie</option>
                    <option value="user">Používateľ</option>
                </select>
                {formik.values.user_department=="user" && <Select showSearch={true} optionFilterProp="children" value={formik.values.user_id} onChange={(value) => formik.setFieldValue("user_id",value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                    <Select.Option disabled selected value="">Vyber používateľa</Select.Option>
                    {state.users?.filter(user => !state.service_user_settings.filter(setting => setting.level_id == formik.values.level_id).map(setting => setting.user_id).includes(user.id)).map((user) => (
                        <Select.Option key={user.id} value={user.id}>{user?.employee?.name || user?.username}</Select.Option>
                    ))}
                </Select>}
                {formik.values.user_department=="department" && <Select showSearch={true} optionFilterProp="children" value={formik.values.department_id} onChange={(value) => formik.setFieldValue("department_id",value)} className='block w-full mb-3 mt-3 text-xl text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40' dropdownStyle={{zIndex:25000}} size="large">
                    <Select.Option disabled selected value="">Vyber oddelenie</Select.Option>
                    {state.departments?.filter(department => !state.service_user_settings.filter(setting => setting.level_id == formik.values.level_id).map(setting => setting.department_id).includes(department.id)).map((department) => (
                        <Select.Option key={department.id} value={department.id}>{department.name}</Select.Option>
                    ))}
                </Select>}
                </div>
                <div>
                <button type="submit" disabled={formik.values.user_id=="" && formik.values.department_id==""} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none disabled:opacity-25'>Uložiť</button>
                </div>
                </form>
            </Dialog>
            <Dialog header="Pridanie úrovne schvaľovania" visible={showAddLevel} style={{ width: '50vw' }} onHide={() => {setShowAddLevel(false);setAddedLevel("");}}>
                <form onSubmit={formikLevel.handleSubmit}>
                    <div className='w-auto flex flex-col'>
                        <label>Názov úrovne</label>
                        <input
                        name='name'
                        onChange={formikLevel.handleChange} value={formikLevel.values.name}
                        className="block w-full mb-2 px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"></input>
                    </div>
                    <div className='w-auto flex flex-col'>
                        <label>Úloha úrovne</label>
                        <select 
                        name='task'
                        onChange={formikLevel.handleChange} value={formikLevel.values.task}
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                            <option value="">Vyber možnosť</option>
                            <option value="approval">Schvaľovanie</option>
                            <option value="notification" hidden={state.service_levels.filter(level => level.type==formikLevel.values?.type && level.task=="notification")?.length}>Notifikácia</option>
                            <option value="management" hidden={state.service_levels.filter(level => level.type==formikLevel.values?.type && level.task=="management")?.length}>Spravovanie</option>
                            <option value="creation" hidden={state.service_levels.filter(level => level.type==formikLevel.values?.type && level.task=="creation")?.length}>Vytvorenie</option>
                        </select>
                    </div>
                    <div>
                    <button type="submit" disabled={formikLevel.values.name=="" || formikLevel.values.task==""} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none disabled:opacity-25'>Uložiť</button>
                    </div>
                </form>
            </Dialog>
            <Dialog header="Zmeniť poradie úrovni" visible={changeOrderOfLevel} style={{ width: '50vw' }} onHide={() => {setChangeOrderOfLevel(false);}}>
                <div className="card flex flex-col justify-content-center">
                        <OrderList className='w-full' dragdrop dataKey="id" value={levels} onChange={(e) => {setLevels(e.value);}} itemTemplate={itemTemplate} header="Úrovne"></OrderList>
                </div>
                <div>
                    <button type="button" onClick={() => setRatings(levels)} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none disabled:opacity-25'>Uložiť</button>
                </div>
            </Dialog>
            </> 
            );

}