import React,{useContext,useEffect, useState,useRef} from 'react';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../api/ApiController';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../assets/css/style.css';
import '../../assets/css/iconsmenu.css'
import { confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon} from "../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import UtilContext from '../../components/UtilContext';
import { OverflowMenuProvider } from '../../components/toolbar/OverflowMenu';
import IntersectionObserverWrapper from '../../components/toolbar/IntersectionObserverWrap';
import { Checkbox } from 'primereact/checkbox';
import { PanelMenu } from 'primereact/panelmenu';

export default function DepartmentList(){

  const [state,dispatch] = useContext(DatabaseContext);
  const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);

  const [stepBackIcon,setStepBackIcon] = useState(true);
  const [editIcon,setEditIcon] = useState(true);
  const [deleteIcon,setDeleteIcon] = useState(true);
  const [favoriteIcon,setFavoriteIcon] = useState(false);
  const [copyIcon,setCopyIcon] = useState(true);

  const [markUnmark, setMarkUnmarked] = useState(true);

  const [visibleSearch,setVisibleSearch] = useState(false);
  const [searchValue,setSearchValue] = useState('');
  const [scrollValue,setScrollValue] = useState(1);

    const [editFormDepartment, setEditFormDepartment] = useState([]);

  const [columnsVisible, setColumnsVisible] = useState({name:true,defined_id:true,'department.name':true,date_of_entry:true,date_of_departure:true,
        description:true,my_state:true
      });
  const [maskVisible, setMaskVisible] = useState(false);

  const [notificationVisible, setNotificationVisible] = useState(false);
  const [chosenNotifications, setChosenNotifications] = useState([]);

    const ref = useRef(null);
    const elementRef = useRef(null);
    const overlayRef = useRef(null);
    const componentRef = useRef(null);
        const ownToast = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });
  
  
    function matchAny(data, filterParams) {
      //data - the data for the row being filtered
      //filterParams - params object passed to the filter
      //RegExp - modifier "i" - case insensitve

      var match = false;
      const regex = RegExp(filterParams.value, 'i');

      for (var key in data) {
          if (regex.test(data[key]) === true) {
              match = true;
          }
      }
      return match;
    }

    function searchTable(){
      ref.current.setFilter(matchAny, { value: searchValue});
      if (searchValue === " ") {
        ref.current.clearFilter()
      }
      setVisibleSearch(false);
    }

    const accept = () => {
      let rows_to_delete = [];
      rows_to_delete = ref.current.getSelectedData();
      rows_to_delete.forEach(element => {
        console.log(element);
        deleteRequest(`/api/departments/${element.id}`)
            .then((response) => {
              toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
              getRequest("/api/departments").then((response) => {
                dispatch({type:'load_departments',newDepartments:response.data});
            });
            }).catch((reason) => {
                alert(reason);
                toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
            })
      });
    }

const reject = () => {
    toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
}

const confirm = () => {
    confirmDialog({
        message: 'Prajete si vymazať tento záznam?',
        header: 'Potvrdenie vymazania',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        acceptLabel: 'Áno',
        rejectLabel: 'Nie',
        accept,
        reject
    });
};

const rowClick = (e, row) => {
console.log(e, row);
};

const onSelectRow = (data, rows) => {
setEditFormDepartment(data);
  if(data.length === 0){
    setEditIcon(true);
    setCopyIcon(true);
    setDeleteIcon(true);
  }
  else{
    setEditIcon(false);
    setCopyIcon(false);
    setDeleteIcon(false);
  }
};

function onEditRow(department){
  dispatch({type:'create_new_tab',name:'Upraviť oddelenie',page:'DepartmentAdd',props:{department: department,type:'edit'},mask:'glo002'})
};

function EditRowFormatter(props) {
  const rowData = props.cell._cell.row.data;
  return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
}

  const columns = [
    { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
    { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
    { title: 'Názov oddelenia', field: 'name',editor: "input",headerFilter:"input"},
    { title: 'Hod. sadzba', field: 'hourly_rate',editor: "input",headerFilter:"input" },
    { title: 'Číslo oddelenia', field: 'defined_id',editor: "input",headerFilter:"input" },
  ];


function addNewDepartment(){
  dispatch({type:'create_new_tab',name:'Nové oddelenie',page:'DepartmentAdd',props:{department: null,type:'add'},mask:'glo002'})
}
function copyDepartment(){
  dispatch({type:'create_new_tab',name:'Nové oddelenie',page:'DepartmentAdd',props:{department: ref.current.getSelectedData()[0],type:'copy'},mask:'glo002'})
}
function editDepartment(){
  dispatch({type:'create_new_tab',name:'Upraviť oddelenie',page:'DepartmentAdd',props:{department: ref.current.getSelectedData()[0],type:'edit'},mask:'glo002'})
}


async function scrollToFirst(){
  let res = state.departments[0].id;
  ref.current.scrollToRow(res, "top", true);
  setScrollValue(1);
}

async function scrollUp(){
  let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
  if((scrollValue-visible_rows+1) >= 0){
    let res = state.departments[scrollValue-visible_rows+1].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(scrollValue-visible_rows);
  }
  else if(scrollValue > 0 && scrollValue <= visible_rows){
    let res = state.departments[0].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(1);
  }
}

async function scrollDown(){
  let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
  let rows = ref.current.getRows(true).length;
  if(scrollValue===1){
    let res = state.departments[scrollValue+visible_rows-1].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(scrollValue+visible_rows);
  }
  else if((scrollValue+visible_rows) <= rows){
    let res = state.departments[scrollValue+visible_rows-1].id;
    let rows = ref.current.getRows(true).length;
    setScrollValue(scrollValue+visible_rows);
    if(res > rows-visible_rows){
      res = state.departments[rows-visible_rows].id;
      setScrollValue(rows-visible_rows);
    }
    ref.current.scrollToRow(res, "top", true);
  }
}

async function scrollToLast(){
  let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
  let rows = ref.current.getRows(true).length;
  let res = state.departments[rows-visible_rows].id;
  ref.current.scrollToRow(res, "top", true);
  setScrollValue(rows-visible_rows);
}

 const itemRenderer = (item, options) => (
    <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
        <span className={`${item.icon} text-primary`} />
        <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
    </a>
  );

  const [expandedKeys, setExpandedKeys] = useState({});

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setExpandedKeys({});
    }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

    const items = [
    {
      key: '0',
      label: 'Akcie',
      icon: 'pi pi-sliders-v',
      template: itemRenderer,
      items: [
            {
              key: '0_1',
              label: 'Nastaviť filter',
              icon: 'pi pi-filter',
              command: () => {setUserMaskFilter("DepartmentList","set_filters",null,ref.current.getHeaderFilters(),null);},
              template: itemRenderer,
          },
            {
              key: '0_2',
              label: 'Zrušiť filter',
              icon: 'pi pi-filter-slash',
              command: () => {setUserMaskFilter("DepartmentList","set_filters",null,[],null);ref.current.clearHeaderFilter();},
              template: itemRenderer,
          },
          {
              key: '0_3',
              label: 'Nast. triedenie',
              icon: 'pi pi-sort-alt',
              command: () => {setUserMaskFilter("DepartmentList","set_sorters",null,null,null,ref.current.getSorters());OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
              template: itemRenderer,
          },
          {
              key: '0_4',
              label: 'Zrušiť triedenie',
              icon: 'pi pi-sort-alt-slash',
              command: () => {setUserMaskFilter("DepartmentList","set_sorters",null,null,null,[]);ref.current.setSort([]);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
              template: itemRenderer,
          },
          {
              key: '0_5',
              label: 'Nastaviť masku',
              icon: 'pi pi-server',
              command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
              template: itemRenderer,
          },
          {
              key: '0_6',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
      ]
    }
  ];

  function showChosenNotifications(){
          let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "TrainingTemplateList"));
          if(db_chosen_notifications?.length>0){
            setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
          }
          else{
            setChosenNotifications([]);
          }
          setNotificationVisible(true);
        }
      
  function saveChosenNotifications(){
      let formData = new FormData();
      formData.append("type","training_templates");
      formData.append("data", JSON.stringify(chosenNotifications));
      postRequest('/api/chosen_notifications', formData,true)
      .then((response) => {
          if(response.status == 201){
            dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
            let mytoast = toast.current ? toast : ownToast;
            mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
          }else{
            let mytoast = toast.current ? toast : ownToast;
            mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
          }
      }).catch((reason) => {
            console.log(reason);
            alert("Daný úkon sa nepodaril!");
      });
      setNotificationVisible(false);
  }

  function changeMarkings(value){
    const columnVisible = {...columnsVisible};
    for(let val in columnVisible) {
      if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
        columnVisible[val]=value;
        ref.current.showColumn(`${val}`);
      }
    };
    setColumnsVisible(columnVisible);
    setTimeout(() => {
      ref.current.redraw(true);
    }, 0);
  }

  function setMask(item,value){
    const columnVisible = {...columnsVisible};
   columnVisible[item] = value;
    for(const val in columnVisible) {
      if(columnVisible[val]==false){
        ref.current.hideColumn(`${val}`);
      }
      else{
        ref.current.showColumn(`${val}`);
      }
    }
    setColumnsVisible(columnVisible);
    setTimeout(() => {
      ref.current.redraw(true);
    }, 0)
  }


    return(
        <>

    <div className='w-full h-10 flex flex-row bg-[#333]'>
      <IntersectionObserverWrapper>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="add"><button onClick={() => addNewDepartment()}><AddIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editDepartment()}><EditIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="copy"><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyDepartment()}><CopyIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="print"><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="search"><button onClick={() => setVisibleSearch(true)}><SearchIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="export"><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="first"><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="back"><button onClick={() => scrollUp()}><BackIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="forward"><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="last"><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="vert"><VerticalLineIcon/></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Oddelenia","DepartmentList")}><FavoriteIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="price"><button onClick={() => setFastView("DepartmentList")}><PriceIcon/></button></div>
        <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center'  data-targetid="vert"><VerticalLineIcon/></div>
        <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
          <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
        </div>
        </IntersectionObserverWrapper>
      </div>
<div className="flex flex-col overflow-hidden min-h-[calc(100vh-177px)]">
  <div className="sm:-mx-4 lg:-mx-6">
    <div className="inline-block w-full py-2 sm:px-6 lg:px-8">
    <div ref={elementRef}>
      <ReactTabulator
      data={state.departments} 
      className='h-[calc(100vh_-_193px)]' //176px
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow,
        tableBuilt: () => {
          let mask = state.user_mask_filters.filter(mask => mask.page=="DepartmentList");
            if(mask.length>0){
              let attributes = [];
              try {
                attributes = JSON.parse(mask[0].attributes)
              } catch (e) {
                attributes = []
              }
              if(attributes?.length>1)ref.current.setColumnLayout(attributes);
              let filters = [];
              try {
                filters = JSON.parse(mask[0].filters);
              } catch (e) {
                filters = []
              }
              filters.forEach(filter => {
                ref.current.setHeaderFilterValue(filter.field,filter.value);
              });
              let sorters = [];
                try {
                  sorters = JSON.parse(mask[0]?.sorters);
                } catch (e) {
                  sorters = []
                }
                if(sorters?.length>0)ref.current.setSort(sorters);
              let json_columns;
              try {
                json_columns = JSON.parse(mask[0].columns);
                setColumnsVisible(json_columns);
              } catch (e) {
                json_columns = []
              }
              for(let column in json_columns){
                if(json_columns[column]==false)ref.current.hideColumn(column);
                else ref.current.showColumn(column);
              }
            }
        },
        columnMoved:() => {
          setUserMaskFilter("TrainingRecordList","column_move",null,null,ref.current.getColumnLayout());
        }
      }}
      options={{layout:"fitColumns",rowHeight:30,movableColumns: true,
      printAsHtml:true,printHeader:"<h1>Zoznam oddelení<h1>",printRowRange:"active",
      autoResize:true}}/> 
    </div> 
      </div>
    </div>
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
          <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
                  <div className="flex flex-col">
                  <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
                  {columns.map(function(item, index){ 
                    if(index<2) return null;
                              return <div 
                                  key={index} 
                                  className="flex flex-row border border-black rounded-md p-2 m-1"
                              > 
          
                                   <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                                    
                                  {/* Use the React icon component */} 
                              </div> 
                          })} 
                      <button onClick={() => {setUserMaskFilter("TrainingRecordList","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
                  </div>
                </Dialog>
                <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
                            <div className="flex flex-col">
                                         <div  
                                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                                        > 
                                            <Checkbox name='Nový záznam' value='Nový záznam' checked={chosenNotifications.includes('Nový záznam')}
                                            onClick={(e) => {
                                                let edit = [...chosenNotifications];
                                                console.log(e,edit);
                                                if (e.checked){
                                                    edit.push(e.value);
                                                }
                                                else
                                                    edit.splice(edit.indexOf(e.value), 1);
                                                setChosenNotifications(edit);
                                            }}></Checkbox>
                                            <label htmlFor='Nový záznam' className="ml-2">Nový záznam</label>
                                        </div> 
                                        <div  
                                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                                        > 
                                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                                            onClick={(e) => {
                                                let edit = [...chosenNotifications];
                                                console.log(e,edit);
                                                if (e.checked){
                                                    edit.push(e.value);
                                                }
                                                else
                                                    edit.splice(edit.indexOf(e.value), 1);
                                                setChosenNotifications(edit);
                                            }}></Checkbox>
                                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                                        </div>
                                    
                              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
                            </div>
                          </Dialog>
  </div>
 </>
    )

}