import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest,deleteRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon,EditRowIcon,PaperClipIcon,EditIcon,DeleteIcon, CollapseTreeIcon, ExpandTreeIcon, BranchTreeIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import { DateTime } from "luxon";
import { OverflowMenuProvider } from '../../../components/toolbar/OverflowMenu';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import ReactDOMServer from 'react-dom/server';


export default function CalibrationCompanyCertRecordDetail({calibration_company, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);
    const {user} = useContext(AuthContext);

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const submitRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const [formCalibrationCompany,setFormCalibrationCompany] = useState(calibration_company);
    const [formType, setFormType] = useState(type);

    const [markUnmark, setMarkUnmarked] = useState(true);
    const [selectedRecord,setSelectedRecord] = useState(null);
    
    const [columnsVisible, setColumnsVisible] = useState({name:true,description:true,calibration_company_id:true,custom_start_of_validity:true,custom_end_of_validity:true,description:true,protocol:true,state:true,});
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    //TODO dorobit contractor id
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit calibration"))?false:true);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
  
    const [visibleRecord,setVisibleRecord] = useState(false);
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [images,setImages] = useState('');
    const [updatefileList, setUpdateFileList] = useState([]);
    const updateFileListRef = useRef(null);
    const [viewedImage,setViewedImage] = useState('');
    const [company,setCompany] = useState("");
    const [certificates,setCertificates] = useState([]);

    const handleUpdateFileChange = (event) => {
        setUpdateFileList(event.target.files);
    };

    const validate = values => {
        const errors = {};
        return errors;
    };

    const formikUpdate = useFormik({
        initialValues: selectedRecord==null?{
            start_of_validity: '',end_of_validity: '',description:'',certificate_id:''}
            :{
              id:selectedRecord?.id,
              start_of_validity:selectedRecord?.start_of_validity,end_of_validity:selectedRecord?.end_of_validity,
              description:selectedRecord?.description,certificate_id:selectedRecord?.certificate_id
            },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value] != null ? values[value] : "");
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }*/
            for(let i = 0;i<updatefileList.length;i++){
              //console.log("IMAGE :"+fileList[i].name);
              formData.append('files[]',updatefileList[i]);
            }
            postRequest(`/api/company_cert_records/update/${formikUpdate.values.id}`, formData,true)
            .then((response) => {
                console.log(response);
                getRequest("/api/companies").then((response) => {
                    dispatch({type:'load_companies',newCompanies:response.data});
                    setVisibleRecord(false);
                    formikUpdate.resetForm();
                    if(updatefileList.length){
                        setUpdateFileList([]);
                        updateFileListRef.current.value = null;
                    }
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                getRequest("/api/company_certificates").then((response) => {
                    dispatch({type:'load_company_certificates',newCompanyCertificates:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            })
        },
    });

    function hideCertRecord(){
      formikUpdate.resetForm();
      setVisibleRecord(false);
    }

    function getIndex(company_certificates_id) {
        return state.calibration_company_certificates.findIndex(obj => obj.id === company_certificates_id);
    }

    async function scrollToFirst(){
        let my_company_certificate = state.calibration_company_certificates[0];
        setFormCalibrationCompany(my_company_certificate);
    }
    
    async function scrollUp(){
        let id = getIndex(formCalibrationCompany.id);
        if(id-1>=0){
            setFormCalibrationCompany(state.calibration_company_certificates[id-1]);
        }
    }
    
    async function scrollDown(){
        let id = getIndex(formCalibrationCompany.id);
        if(id+1<state.calibration_company_certificates.length){
            setFormCalibrationCompany(state.calibration_company_certificates[id+1]);
        }
    }
    
    async function scrollToLast(){
        let last = state.calibration_company_certificates.length-1;
        let my_company = state.calibration_company_certificates[last];
        setFormCalibrationCompany(my_company);
    }

    const onSelectRow =  (data, rows) => {
      setDeleteIcon(true);
      setEditIcon(true);
      formikUpdate.resetForm();
      if(data.length>0){
          let children = 0;
          let parent = 0;
          data.forEach(obj => {
              if(obj?.certificate_id>0){
                  children++;
              }
              else parent++;
          });
          if(children>0 && parent==0){
            console.log(user.permissions);
              if(user.permissions.includes("delete calibration"))setDeleteIcon(false);
              if(data?.length==1 && user.permissions.includes("edit calibration")){
                  setSelectedRecord(data[0]);
                  formikUpdate.setValues(data[0]);
                  setEditIcon(false);
              }
          }
      }
    }

    function getRecordFiles(id){
        getRequest(`/api/company_cert_records/protocols/${id}`).then((response) => {
            console.log(response);
            if(response.data!=="{}")setImages(response.data);
        });
    }
    
    const acceptFile = (id) => {
        getRequest(`/api/company_cert_records/deleteProtocol/${id}`)
        .then((response) => {
            toast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Súbor bol úspešne vymazaný!" });
            getRecordFiles(selectedRecord?.id);
        }).catch((reason) => {
            alert(reason);
            toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Súbor sa nepodarilo vymazať!', life: 3000 });
        })
    };
    

    function deleteFiles(img){
      confirmDialog({
          message: 'Prajete si vymazať tento súbor?',
          header: 'Potvrdenie vymazania',
          icon: 'pi pi-info-circle',
          acceptClassName: 'p-button-danger',
          acceptLabel: 'Nie',
          rejectLabel: 'Áno',
          accept:reject,
          reject:() => acceptFile(img.id)
      });
    }

    function NameFormatter(props) {
      const rowData = props.cell.getRow().getData();
      var parent = props.cell.getRow()?.getTreeParent();
      if(rowData?._children) return <div className='training_name'>{rowData.name}</div>;
      if(parent){
          let parent_array = parent._row.data._children;
          for(let i = 0;i<parent_array.length;i++){
              if(parent_array[i].id == rowData.id){
                  if(i==parent_array.length-1)return <div className='training_name'>Prvotný certifikát</div>;
                  else return <div className='training_name'>{parent_array.length-1-i}. Opakovaný certifikát</div>;
              }
          }
          return <div className='training_name'>{parent._row.data.name}</div>;
      }
      return "";
  }  

    function viewFile(file_id){
        getRequest(`/api/company_cert_records/protocol/${file_id}`).then((response) => {
            console.log(response);
            setVisibleFiles(true);
            setViewedImage(response.data);
        });
    }

      function Protocol(props) {
        const rowData = props.cell.getRow().getData();
        if(rowData?.files) return <div className='flex flex-row'>{rowData.files.map((img) => (<a style={{color:'blue',cursor:'pointer',marginBottom:'2px',width: '24px',display: 'block'}} onClick={() => {viewFile(img.id);}}><PaperClipIcon/></a>))}</div>;
        if(rowData?._children.length > 0 && rowData._children[0]?.files?.length > 0) return <div className='flex flex-row'>{rowData._children[0]?.files.map((img) => (<a style={{color:'blue',cursor:'pointer',marginBottom:'2px',width: '24px',display: 'block'}} onClick={() => {viewFile(img.id);}}><PaperClipIcon/></a>))}</div>;
        return "";
    }

    function EndOfValidity(props){
        const rowData = props.cell.getRow().getData();
        if(rowData?.end_of_validity) return DateTime.fromISO(rowData.end_of_validity).toFormat("dd.MM.yyyy");
        if(rowData?._children.length > 0) return DateTime.fromISO(rowData._children[0].end_of_validity).toFormat("dd.MM.yyyy");
        return <span className='text-red-500'>Necertifikované!</span>;
    }

    function StartOfValidity(props){
        const rowData = props.cell.getRow().getData();
        if(rowData?.start_of_validity) return DateTime.fromISO(rowData.start_of_validity).toFormat("dd.MM.yyyy");
        if(rowData?._children.length > 0) return DateTime.fromISO(rowData._children[0].start_of_validity).toFormat("dd.MM.yyyy");
        return <span className='text-red-500'>Necertifikované!</span>;
    }

    function StateFormatter(props){
      const rowData = props.cell.getRow().getData();
      var parent = props.cell.getRow()?.getTreeParent();
      if(parent){
          let parent_array = parent._row.data._children;
          for(let i = 0;i<parent_array.length;i++){
              if(parent_array[i].id == rowData.id){
                  if(i==parent_array.length-1){
                      if(new Date(parent_array[i]?.end_of_validity) < new Date()){
                          return "NOK";
                      }
                      else return "OK";
                  }
                  else return "OK";
              }
          }
      }
      if(rowData?._children?.length > 0){           
          if(new Date(rowData?._children[0]?.end_of_validity) < new Date()){
              return "NOK";
          }
          return "OK"
      }
      else return "NOK";
    }

    const [columns,setColumns] = useState([
      { title: '', field: 'id',visible:false},
      {
        formatter:"rowSelection", titleFormatter:"rowSelection",titleFormatterParams:{rowRange:"active"},hozAlign:"center",width: 20,headerSort:false,resizable:false,
        cellClick: function(e, cell) {
          cell.getRow().toggleSelect();
          console.log(cell.getRow(), cell.getRow().getTreeParent());
        }
      },
      { title: 'Názov', field: 'name',formatter: reactFormatter(<NameFormatter/>),headerFilter:"input",minWidth: 200, widthGrow:1},
      { title: 'Popis', field: 'description',headerFilter:"input",minWidth: 200, widthGrow:1},
      { title: 'Platnosť od', field: 'custom_start_of_validity',headerFilter:"input",minWidth: 200, widthGrow:1,formatter: reactFormatter(<StartOfValidity/>) },
      { title: 'Platnosť do', field: 'custom_end_of_validity',headerFilter:"input",minWidth: 200, widthGrow:1,formatter: reactFormatter(<EndOfValidity/>)},
      { title: 'Popis certifikátu', field: 'description',headerFilter:"input",minWidth: 200, widthGrow:1},
      { title: 'Stav', field: 'state',formatter: reactFormatter(<StateFormatter/>),headerFilter:"input",minWidth: 200, widthGrow:1},
      { title: 'Protokol', field: 'protocol',headerFilter:"input",minWidth: 200, widthGrow:1,formatter: reactFormatter(<Protocol/>)},
    ]);

    function setMask(item,value){
        const columnVisible = {...columnsVisible};
       columnVisible[item] = value;
       console.log(columnVisible);
        for(const val in columnVisible) {
          if(columnVisible[val]==false){
            ref.current.hideColumn(`${val}`);
          }
          else{
            ref.current.showColumn(`${val}`);
          }
        }
        setColumnsVisible(columnVisible);
      }

      const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
            {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("CalibrationCompanyCertRecordDetail","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("CalibrationCompanyCertRecordDetail","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
             {
                key: '0_3',
                label: 'Nast. triedenie',
                icon: 'pi pi-sort-alt',
                command: () => {setUserMaskFilter("CalibrationCompanyCertRecordDetail","set_sorters",null,null,null,ref.current.getSorters());OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
                key: '0_4',
                label: 'Zrušiť triedenie',
                icon: 'pi pi-sort-alt-slash',
                command: () => {setUserMaskFilter("CalibrationCompanyCertRecordDetail","set_sorters",null,null,null,[]);ref.current.setSort([]);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
                key: '0_5',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_6',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];

    function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","gauge_calibrations");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }
    
      function changeMarkings(value){
        const columnVisible = {...columnsVisible};
          for(let val in columnVisible) {
            if(columnVisible[val]!=="id" || columnVisible[val]!=="editor" || columnVisible[val]!=="end_of_validity"){
              columnVisible[val]=value;
              ref.current.showColumn(`${val}`);
            }
          };
          setColumnsVisible(columnVisible);
      }

      const reject = () => {
          toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
      }

    const acceptCertificate = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
          console.log(element);
          deleteRequest(`/api/company_cert_records/${element.id}`)
              .then((response) => {
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                getRequest("/api/company_certificates").then((response) => {
                  dispatch({type:'load_company_certificates',newCompanyCertificates:response.data});
              });
              }).catch((reason) => {
                  alert(reason);
                  toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              })
        });
    }

      const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: reject,
            reject: acceptCertificate
        });
    };



    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'> 
            <IntersectionObserverWrapper>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => {getRecordFiles(selectedRecord?.id);setVisibleRecord(true);}}><EditIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => ref.current.print(false, true)}><PrintIcon/></button></div>      
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Upraviť certifikáty kal. firiem","CalibrationCompanyCertRecordDetail")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="price"><button onClick={() => setFastView("CalibrationCompanyCertRecordDetail",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
        </div>
        <Splitter className='h-[calc(100vh_-_177px)]' layout="vertical" pt={{ gutter: { className: 'bg-[#333] h-8' },gutterHandler: { className: 'bg-secondary ' } }}>
        <SplitterPanel className="flex align-items-center justify-content-center overflow-y-auto " minSize={10} size={63}>
        <div className="w-full relative flex flex-col h-full overflow-x">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   PLATNOSŤ CERTIFIKÁTOV KALIBRAČNEJ FIRMY
                </h1>
                {formType=="edit" && <label className="block text-md font-semibold text-gray-800">Por.č.: {formCalibrationCompany?.id}</label>}
                      <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV FIRMY
                        </label>
                        <input
                        readOnly={true}
                            name='name'
                            type="text"
                            value={formCalibrationCompany?.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="tel_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TELEFÓNNE ČÍSLO
                        </label>
                        <input
                        readOnly={true}
                            name='tel_number'
                            type="text"
                            value={formCalibrationCompany?.tel_number}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            htmlFor="email"
                            className="block text-md font-semibold text-gray-800"
                        >
                            E-MAIL
                        </label>
                        <input
                        readOnly={true}
                            name='email'
                            type="text"
                            value={formCalibrationCompany?.email}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="contact_person"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            KONTAKTNÁ OSOBA
                        </label>
                        <input
                        readOnly={true}
                            name='contact_person'
                            type="text"
                            value={formCalibrationCompany?.contact_person}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>        
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                    <div>
                        <label
                            htmlFor="address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ADRESA
                        </label>
                        <input
                        readOnly={true}
                            name='address'
                            type="text"
                            value={formCalibrationCompany?.address}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="fax"
                            className="block text-md font-semibold text-gray-800"
                        >
                             FAX
                        </label>
                        <input
                        readOnly={true}
                            name='fax'
                            type="text"
                            value={formCalibrationCompany?.fax}
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="web_address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            WEB. ADRESA
                        </label>
                        <input
                        readOnly={true}
                            name='web_address'
                            type="text"
                            value={formCalibrationCompany?.web_address}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <textarea
                        readOnly={true}
                            name='gauge_name'
                            type="text"
                            value={formCalibrationCompany?.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-gray-200 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>    
            </div> 
            </div>
            </div> 
             </SplitterPanel>
             <SplitterPanel className="flex flex-col align-items-center justify-content-center w-full overflow-y-auto" size={37} style={{minHeight: '110px'}}>
             <ReactTabulator
               data={state.calibration_company_certificates} 
               className='h-[calc(100vh_-_176px)]' //176px
               onRef={(r) => (ref.current = r.current)}
               columns={columns}
               events={{
                 rowSelectionChanged: onSelectRow,
                 tableBuilt: () => {
                    let mask = state.user_mask_filters.filter(mask => mask.page=="CalibrationCompanyCertRecordDetail");
                    if(mask.length>0){
                      let attributes = [];
                      try {
                        attributes = JSON.parse(mask[0].attributes)
                      } catch (e) {
                        attributes = []
                      }
                      if(attributes?.length>1)ref.current.setColumnLayout(attributes);
                      let sorters = [];
                        try {
                          sorters = JSON.parse(mask[0]?.sorters);
                        } catch (e) {
                          sorters = []
                        }
                        if(sorters?.length>0)ref.current.setSort(sorters);
                      let json_columns;
                      try {
                        json_columns = JSON.parse(mask[0].columns);
                        setColumnsVisible(json_columns);
                      } catch (e) {
                        json_columns = []
                      }
                      for(let column in json_columns){
                        if(json_columns[column]==false)ref.current.hideColumn(column);
                        else ref.current.showColumn(column);
                      }
                    }
                  },
                 columnMoved:() => {
                   setUserMaskFilter("CalibrationCompanyCertRecordDetail","column_move",null,null,ref.current.getColumnLayout());
                 }
               }}
               options={{renderHorizontal:"virtual",rowHeight:30,movableColumns: true,
                dataTree:true,dataTreeStartExpanded:false,dataTreeFilter:false,dataTreeElementColumn: "name",
                dataTreeCollapseElement:ReactDOMServer.renderToStaticMarkup(<CollapseTreeIcon/>),
                dataTreeExpandElement:ReactDOMServer.renderToStaticMarkup(<ExpandTreeIcon/>),
                dataTreeBranchElement:ReactDOMServer.renderToStaticMarkup(<BranchTreeIcon/>),
                printAsHtml:true,printRowRange:"active",printCopyStyle:true,printHeader:"<h1>Podľa firmy<h1>",
                initialFilter:[
                    {field:"company_id", type:"=", value:formCalibrationCompany?.id}
                ],
               }}/>
            </SplitterPanel>
            </Splitter>
            </div>
            <Dialog header="Upraviť záznam certifikátu" visible={visibleRecord} style={{ width: '40vw' }} onHide={() => hideCertRecord()}>
              <form onSubmit={formikUpdate.handleSubmit}>
                  <div>
                    <label
                        htmlFor="certificate_id"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Certifikát kal. firmy
                    </label>
                    <select 
                    disabled
                    name="certificate_id"
                    onChange={formikUpdate.handleChange} value={formikUpdate.values.certificate_id} 
                    className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                      <option value="">Vyber certifikát</option>
                      {state.company_certificates.map((certificate) => (
                        <option key={certificate.id} value={certificate.id}>{certificate.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label
                        htmlFor="start_of_validity"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Dátum platnosti od
                    </label>
                    <input
                        name='start_of_validity'
                        type="date" required
                        onChange={formikUpdate.handleChange} value={formikUpdate.values.start_of_validity}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    </div>
                    <div>
                    <label
                        htmlFor="end_of_validity"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Dátum platnosti do
                    </label>
                    <input
                        name='end_of_validity'
                        type="date" required
                        onChange={formikUpdate.handleChange} value={formikUpdate.values.end_of_validity}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    />
                    </div>
                    <div>
                    <label
                        htmlFor="description"
                        className="block text-md font-semibold text-gray-800"
                    >
                        Poznámka
                    </label>
                    <textarea
                        name='description'
                        type="text"
                        onChange={formikUpdate.handleChange} value={formikUpdate.values.description}
                        className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    >
                    </textarea>
                    </div> 
                      <div>
                      <label
                          htmlFor="files"
                          className="block text-md font-semibold text-gray-800"
                      >
                          PROTOKOL
                      </label>
                      <input
                          name='files' 
                          accept='image/*,application/pdf'
                          ref={updateFileListRef}
                          onChange={handleUpdateFileChange}
                          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                          type='file'
                          multiple="multiple"
                          id='file'/>
                      </div> 
                      {images!=='' && images.map((img) => ( <div className='flex flex-row p-2'>
                          <div onClick={() => setVisibleFiles(true)}>
                              <embed src={img.url+'#view=FitH&scrollbar=0'} width='100px' height='65px'></embed>
                          </div>
                              <div className='flex flex-col ml-4'>
                              <a style={{color:'blue',cursor:'pointer',marginBottom:'2px'}} onClick={() => {setVisibleFiles(true);setViewedImage(img)}}>{img.filename}</a>
                              <button type="button" className='w-fit flex flex-row px-2 py-1 border rounded-md bg-red-900' onClick={() => {setViewedImage(img);deleteFiles(img)}} ><DeleteIcon/><span className='text-white ml-1'>Vymazať</span></button>
                              </div>
                          </div>))}
                      <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
                  </form>
              </Dialog>
      <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2 || index>6) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("CalibrationCompanyCertificateAdd","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
            <Dialog header="Náhľad PDF súboru" visible={visibleFiles} onHide={() => setVisibleFiles(false)} style={{ width: '80vw',height:'90vh',backgroundColor: 'transparent',boxShadow: 'none'}}>
        <iframe src={viewedImage.url} className='w-full h-full'></iframe>
        </Dialog>
        <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                          > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
            </> 
            );
} 
