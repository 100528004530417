import React,{ useContext, useEffect, useState,useRef } from "react";
import { useFormik } from 'formik';
import {authPostRequest} from './../../api/ApiController';
import AuthContext from '../../components/AuthContext';
import Background from '../../assets/img/eng_background.jpg';
import {ShowPasswordIcon,HidePasswordIcon} from './../svg/svgIcons';
import { Navigate,useLocation,useNavigate } from "react-router-dom";
import { Message } from 'primereact/message';

export default function ForgotPassword() {

    /* form and logged user values */
    const [user,setUser] = useState(null);
    const [submit,setSubmit] = useState(false);
    const {login,verify}= useContext(AuthContext);

    const [showLogin, setShowLogin] = useState(true);
    const [showAfterReset, setShowAfterReset] = useState(false);
    const [unverifyUser, setUnverifyUser] = useState(null);

    const usernameInput = useRef(null);

    useEffect(() => {
        if (usernameInput.current) {
            usernameInput.current.focus();
        }
    }, []);

    /* navigate after logged in */
    const navigate = useNavigate();

    /* validate form */
    const validate = values => {
        const errors = {};
        if (!values.username) {
            errors.username = '*Povinné';
        }
        return errors;
    };

    /* form for add customer */
    const formik = useFormik({
        initialValues: {
            username: '',
        },
        validate,
        onSubmit: async (values) => {
            authPostRequest('/api/users/forgot_password', JSON.stringify(values))
            .then((response) => {
                console.log(response);
                if(response.status < 400){
                    //console.log("OTP VALID");
                    setShowLogin(false);
                    setShowAfterReset(true);
                    setTimeout(() => {
                        navigate("/login");
                      }, 5000);
                }
                else{
                    //console.log("OTP INVALID");
                    alert("Používateľ sa nenašiel");
                    //setUnverifyUser(null);
                    //setShowLogin(true);
                    //setShowVerifyOTP(false);
                }
            }).catch((reason) => {
                console.log(reason);
                alert("Došlo k chybe");
            })
        }
    });

    
    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-cover"
        style={{backgroundImage: "url(" + Background + ")", height:"400px"}}>
            <div 
            className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                {showLogin && <>
                <h1 className="text-3xl font-semibold text-center text-zinc-900 underline">
                   Zabudnuté heslo
                </h1>
                <form onSubmit={formik.handleSubmit} className="mt-6">
                    <div className='mb-2'>
                        <label htmlFor="username" className="block text-sm font-semibold text-gray-800">Prihlasovacie meno</label>
                        <input className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" id="username" name="username" type="text" onChange={formik.handleChange} value={formik.values.username} ref={usernameInput}/>
                        {formik.errors.username ? <div style={{color:'red'}}>{formik.errors.username}</div> : null}
                    </div>
                        <div className="mt-6">
                            <button type="submit" className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600">
                            Resetovanie hesla
                            </button>
                        </div>
                </form></>}
                {showAfterReset && <>
                    <Message className="w-full" severity="success" text="Heslo bolo úspešne resetované!" />
                </>}
            </div>
        </div>
    );
}