import React,{useContext,useEffect, useState,useRef} from 'react';
import { getRequest, postRequest, putRequest,deleteRequest } from '../../../api/ApiController';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import '../../../assets/css/style.css';
import '../../../assets/css/iconsmenu.css'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { MdSearch } from "react-icons/md";
import { MdSearchOff } from "react-icons/md";
import { AddIcon, EditIcon, CopyIcon, DeleteIcon, StepBackIcon, PrintIcon, SearchIcon, ExportIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon, CalendarIcon} from "../../svg/svgIcons";
import {useReactToPrint} from "react-to-print";
import { InfinitySpin } from  'react-loader-spinner';
import { DateTime } from "luxon";
import AuthContext from "../../../components/AuthContext";
import moment from "moment";
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import UtilContext from "../../../components/UtilContext";
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import { OverflowMenuProvider } from '../../../components/toolbar/OverflowMenu';


export default function PaintingTimeConditionList(){

    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,visible,setUserMaskFilter} = useContext(UtilContext);

    const [stepBackIcon,setStepBackIcon] = useState(true);
    const [insertIcon,setInsertIcon] = useState(!user.permissions.includes("add painting"));
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [favoriteIcon,setFavoriteIcon] = useState(false);
    const [copyIcon,setCopyIcon] = useState(true);
    const [searchIconColor,setSearchIconColor] = useState("");

    const [visibleSearch,setVisibleSearch] = useState(false);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [searchValue,setSearchValue] = useState('');
    const [scrollValue,setScrollValue] = useState(1);
    const [printVisible,setPrintVisible] = useState(false);
    const [printValue,setPrintValue] = useState("Zoznam");
    const [visibleCalendar,setVisibleCalendar] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const [columnsVisible, setColumnsVisible] = useState({name: true,from: true,to:true,
    });
    const [maskVisible, setMaskVisible] = useState(false);

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [editFormPaintingTimeCondition, setEditFormPaintingTimeCondition] = useState([]);

    const ref = useRef(null);
    const elementRef = useRef(null);
    const componentRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {componentRef.current.style.display = "block";},
        onAfterPrint: () => {componentRef.current.style.display = "none";},
        content: () => componentRef.current,
    });


    useEffect(() => {
      // Update the document title using the browser API
      const isFound = state.favorites.some(favorite => {
        if (favorite.page === 'PaintingConditionList') {
          return true;
        }
        return false;
      });
      
      if (isFound) {
        setFavoriteIcon(true);
      }
    });
  
  
    function matchAny(data, filterParams) {
      var match = false;
      const regex = RegExp(filterParams.value, 'i');
  
      for (var key in data) {
          if (regex.test(data[key]) === true) {
              match = true;
          }
      }
      return match;
    }

    function dateRange(data, filterParams) {
      console.log(data.created_at,filterParams);
      return moment(filterParams.fromDate).isSameOrBefore(data.created_at.substring(0,10)) && moment(filterParams.toDate).isSameOrAfter(data.created_at.substring(0,10));
    }

  function searchTable(){
    ref.current.setFilter(matchAny, { value: searchValue});
    setSearchIconColor("active");
    if (searchValue === " ") {
      ref.current.clearFilter()
    }
    setVisibleSearch(false);
  }

  function searchByDate(){
    ref.current.setFilter(dateRange, {fromDate:fromDate,toDate:toDate });
    setVisibleCalendar(false);
  }

    const accept = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
          console.log(element);
          deleteRequest(`/api/painting_time_conditions/${element.id}`)
              .then((response) => {
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                getRequest("/api/painting_time_conditions").then((response) => {
                  dispatch({type:'load_painting_time_conditions',newPaintingTimeConditions:response.data});
              });
              }).catch((reason) => {
                  alert(reason);
                  toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              })
        });
    }

    const reject = () => {
        toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: reject,
            reject: accept
        });
    };

  const rowClick = (e, row) => {
    console.log(e, row);
 };

    const onSelectRow = (data, rows) => {
        setEditFormPaintingTimeCondition(data);
        setEditIcon(true);
        setCopyIcon(true);
        setDeleteIcon(true);
        if(data.length !== 0){
            //TODO dorobit aj contractorov
            if(user.permissions.includes("add painting"))setCopyIcon(false);
            if(user.permissions.includes("edit painting"))setEditIcon(false);
            if(user.permissions.includes("delete painting"))setDeleteIcon(false);
        }
    };

    function PaintingConditionDate(props) {
      const rowData = props.cell.getRow().getData();
      return DateTime.fromISO(rowData.created_at).toFormat("dd.MM.yyyy HH:mm");
    }

    function onEditRow(painting_time_condition){
      dispatch({type:'create_new_tab',name:'Upraviť časové podmienky',page:'PaintingTimeConditionAdd',props:{painting_time_condition: painting_time_condition,type:'edit'},mask:'lak005'})
    };
    
    function EditRowFormatter(props) {
      const rowData = props.cell.getRow().getData();
      return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
    }


    const [draggingItem,setDraggingItem] = useState(null); 
    const [columns,setColumns] = useState([
    { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
    { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
    { title: 'Názov podmienky', field: 'name',headerFilter:"input",widthGrow:1},
    { title: 'Od', field: 'from',headerFilter:"input",widthGrow:1 },
    { title: 'Do', field: 'to',headerFilter:"input",widthGrow:1 },
  ]);

  async function scrollToFirst(){
    let res = state.painting_time_conditions[0].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(1);
  }

  async function scrollUp(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    if((scrollValue-visible_rows+1) >= 0){
      let res = state.painting_time_conditions[scrollValue-visible_rows+1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue-visible_rows);
    }
    else if(scrollValue > 0 && scrollValue <= visible_rows){
      let res = state.painting_time_conditions[0].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(1);
    }
  }

  async function scrollDown(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    if(scrollValue===1){
      let res = state.painting_time_conditions[scrollValue+visible_rows-1].id;
      ref.current.scrollToRow(res, "top", true);
      setScrollValue(scrollValue+visible_rows);
    }
    else if((scrollValue+visible_rows) <= rows){
      let res = state.painting_time_conditions[scrollValue+visible_rows-1].id;
      let rows = ref.current.getRows(true).length;
      setScrollValue(scrollValue+visible_rows);
      if(res > rows-visible_rows){
        res = state.painting_time_conditions[rows-visible_rows].id;
        setScrollValue(rows-visible_rows);
      }
      ref.current.scrollToRow(res, "top", true);
    }
  }

  async function scrollToLast(){
    let visible_rows = Math.floor((elementRef.current.clientHeight-68)/30-1);
    let rows = ref.current.getRows(true).length;
    let res = state.painting_time_conditions[rows-visible_rows].id;
    ref.current.scrollToRow(res, "top", true);
    setScrollValue(rows-visible_rows);
  }
  function addNewPaintingCondition(){
    dispatch({type:'create_new_tab',name:'Nové časové podmienky',page:'PaintingTimeConditionAdd',props:{painting_time_condition: null,type:'add'},mask:'lak005'})
  }
  function copyPaintingCondition(){
    dispatch({type:'create_new_tab',name:'Nové časové podmienky',page:'PaintingTimeConditionAdd',props:{painting_time_condition: ref.current.getSelectedData()[0],type:'copy'},mask:'lak005'})
  }
  function editPaintingCondition(){
    dispatch({type:'create_new_tab',name:'Upraviť časové podmienky',page:'PaintingTimeConditionAdd',props:{painting_time_condition: ref.current.getSelectedData()[0],type:'edit'},mask:'lak005'})
  }

  function confirmPrintDialog(){
    if(printValue=="Zoznam"){
      ref.current.print(false, true)
    }
    else{
      handlePrint();
    }
  }

    function handleDragStart(e, item){ 
      setDraggingItem(item);
      e.dataTransfer.setData('text/plain', ''); 
    }; 

    function handleDragEnd(){ 
      setDraggingItem(null);
    }; 

    function handleDragOver(e){ 
        e.preventDefault(); 
    }; 

    function handleDrop(e, targetItem){ 
        if (!draggingItem) return; 

        const currentIndex = columns.indexOf(draggingItem); 
        const targetIndex = columns.indexOf(targetItem); 

        if (currentIndex !== -1 && targetIndex !== -1) { 
          columns.splice(currentIndex, 1); 
          columns.splice(targetIndex, 0, draggingItem); 
           
        } 
        ref.current.setColumns(columns);
    }; 
  
  function setMask(item,value){
    const columnVisible = {...columnsVisible};
   columnVisible[item] = value;
   console.log(columnVisible);
    for(const val in columnVisible) {
      if(columnVisible[val]==false){
        ref.current.hideColumn(`${val}`);
      }
      else{
        ref.current.showColumn(`${val}`);
      }
    }
    setColumnsVisible(columnVisible);
  }

  function changeMarkings(value){
    if(value){
      const columnVisible = {...columnsVisible};
      for(let val in columnVisible) {
        if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
          columnVisible[val]=true;
          ref.current.showColumn(`${val}`);
        }
      };
      setColumnsVisible(columnVisible);
    }
    else{
      const columnVisible = {...columnsVisible};
      for(let val in columnVisible) {
        if(columnVisible[val]!=="id" || columnVisible[val]!=="editor"){
          columnVisible[val]=false;
          ref.current.hideColumn(`${val}`);
        }
      };
      setColumnsVisible(columnVisible);
    }
  }

  const itemRenderer = (item, options) => (
    <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
        <span className={`${item.icon} text-primary`} />
        <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
        {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
    </a>
  );

  const [expandedKeys, setExpandedKeys] = useState({});

  const handleClickOutside = (event) => {
    if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setExpandedKeys({});
    }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

    const items = [
    {
      key: '0',
      label: 'Akcie',
      icon: 'pi pi-sliders-v',
      template: itemRenderer,
      items: [
        {
          key: '0_1',
          label: 'Nastaviť filter',
          icon: 'pi pi-filter',
          command: () => {setUserMaskFilter("PaintingTimeConditionList","set_filters",null,ref.current.getHeaderFilters(),null);},
          template: itemRenderer,
      },
        {
          key: '0_2',
          label: 'Zrušiť filter',
          icon: 'pi pi-filter-slash',
          command: () => {setUserMaskFilter("PaintingTimeConditionList","set_filters",null,[],null);ref.current.clearHeaderFilter();},
          template: itemRenderer,
      },
      {
        key: '0_3',
        label: 'Nast. triedenie',
        icon: 'pi pi-sort-alt',
        command: () => {setUserMaskFilter("PaintingTimeConditionList","set_sorters",null,null,null,ref.current.getSorters());OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
        template: itemRenderer,
    },
    {
        key: '0_4',
        label: 'Zrušiť triedenie',
        icon: 'pi pi-sort-alt-slash',
        command: () => {setUserMaskFilter("PaintingTimeConditionList","set_sorters",null,null,null,[]);ref.current.setSort([]);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
        template: itemRenderer,
    },
    {
        key: '0_5',
        label: 'Nastaviť masku',
        icon: 'pi pi-server',
        command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
        template: itemRenderer,
    },
    {
      key: '0_6',
      label: 'Notifikácie',
      icon: 'pi pi-envelope',
      command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
      template: itemRenderer,
  },
      ]
    }
  ];

  function showChosenNotifications(){
    let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
    if(db_chosen_notifications?.length>0){
      setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
    }
    else{
      setChosenNotifications([]);
    }
    setNotificationVisible(true);
  }

  function saveChosenNotifications(){
      let formData = new FormData();
      formData.append("type","otk_int");
      formData.append("data", JSON.stringify(chosenNotifications));
      postRequest('/api/chosen_notifications', formData,true)
      .then((response) => {
        if(response.status == 201){
          dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
          if(toast.current)toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
          else ownToast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });  
        }else{
          if(toast.current)toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
          else ownToast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });  
        }
      }).catch((reason) => {
            console.log(reason);
            alert("Daný úkon sa nepodaril!");
      });
      setNotificationVisible(false);
  }


    return(
      <>
      <div className='w-full h-10 flex flex-row bg-[#333]'>
        <IntersectionObserverWrapper>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="add"><button className="disabled:opacity-25" disabled={insertIcon} onClick={() => addNewPaintingCondition()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editPaintingCondition()}><EditIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="copy"><button className="disabled:opacity-25" disabled={copyIcon} onClick={() => copyPaintingCondition()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="delete"><button className="disabled:opacity-25" onClick={confirm} disabled={deleteIcon}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="print"><button onClick={() => setPrintVisible(true)}><PrintIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="search"><button onClick={searchIconColor!="active"? () => {setVisibleSearch(true);}:() => {setSearchIconColor("");setSearchValue("");ref.current.clearFilter();}}>{searchIconColor == "" ?  <MdSearch fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Hľadať'/> : <MdSearchOff fill='#fff' strokeWidth="0" className='w-[28px] h-[28px]' aria-hidden="true" title='Zrušiť hľadanie'/>}</button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="export"><button onClick={() => ref.current.download("xlsx", "data.xlsx", {sheetName:"MyData"})}><ExportIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center align-center ' data-targetid="calendar"><button onClick={() => setVisibleCalendar(true)}><CalendarIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="first"><button onClick={() => scrollToFirst()}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="back"><button onClick={() => scrollUp()}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="forward"><button onClick={() => scrollDown()}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="last"><button onClick={() => scrollToLast()}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="favorite"><button className="disabled:opacity-25" disabled={favoriteIcon} onClick={() => openFavoriteDialog("Podmienky lakovania","PaintingConditionList")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center ' data-targetid="fast"><button onClick={() => setFastView("PaintingConditionList",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '  data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100] " data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
    </IntersectionObserverWrapper>
      </div>
<div className='px-1 bg-white rounded-md shadow-md'>
<div className="flex flex-col overflow-x-auto no-scrollbar min-h-[calc(100vh-177px)]">
<div className="sm:-mx-6 lg:-mx-8 overflow-hidden">
  <div className="inline-block w-full py-2 sm:px-6 lg:px-8" >
    <div ref={elementRef}>
      <ReactTabulator
      data={state.painting_time_conditions} 
      className='h-[calc(100vh_-_193px)]' //176px
      onRef={(r) => (ref.current = r.current)}
      columns={columns}
      events={{
        rowClick: rowClick,
        rowSelectionChanged: onSelectRow,
        tableBuilt: () => {
          let mask = state.user_mask_filters.filter(mask => mask.page=="PaintingTimeConditionList");
          if(mask.length>0){
            let attributes = [];
            try {
              attributes = JSON.parse(mask[0].attributes)
            } catch (e) {
              attributes = []
            }
            if(attributes?.length>1)ref.current.setColumnLayout(attributes);
            let filters = [];
            try {
              filters = JSON.parse(mask[0].filters);
            } catch (e) {
              filters = []
            }
            filters.forEach(filter => {
              ref.current.setHeaderFilterValue(filter.field,filter.value);
            });
            let sorters = [];
                try {
                  sorters = JSON.parse(mask[0]?.sorters);
                } catch (e) {
                  sorters = []
                }
                if(sorters?.length>0)ref.current.setSort(sorters);
            let json_columns;
            try {
              json_columns = JSON.parse(mask[0].columns);
              setColumnsVisible(json_columns);
            } catch (e) {
              json_columns = []
            }
            for(let column in json_columns){
              if(json_columns[column]==false)ref.current.hideColumn(column);
              else ref.current.showColumn(column);
            }
          }
        },
        columnMoved:() => {
          setUserMaskFilter("PaintingTimeConditionList","column_move",null,null,ref.current.getColumnLayout());
        },
      }}
      options={{
      renderHorizontal:"virtual",movableColumns: true,
      printAsHtml:true,printHeader:"<h1>Zoznam intervalov lakovne<h1>",printRowRange:"active", movableColumns:true,}}/> 
    </div> 
  </div> 
</div> 
</div>
          <Dialog header="Hľadaj v zozname:" visible={visibleSearch} style={{ width: '40vw' }} onHide={() => setVisibleSearch(false)}>
            <div className="flex flex-columns">
              
              <input
                  value={searchValue} onChange={e => setSearchValue(e.target.value)}
                  type="text"
                  className="block w-full px-4 py-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
              />
              <button onClick={() => searchTable()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
            </div>
          </Dialog>
          <Dialog header="Hľadaj v zozname podľa dátumu:" visible={visibleCalendar} style={{ width: '40vw' }} onHide={() => setVisibleCalendar(false)}>
              <div className="flex flex-columns justify-between">
                <label>
                  Od:
                <input
                    value={fromDate} onChange={e => setFromDate(e.target.value)}
                    type="date"
                    className="block w-full px-1 py-1 mr-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                /> 
                </label>
                <label>
                  Do:
                <input
                    value={toDate} onChange={e => setToDate(e.target.value)}
                    type="date"
                    className="block w-full px-1 py-1 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                />
                </label>
                <button onClick={() => searchByDate()} className='w-16 ml-8 px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'><SearchIcon/></button>
              </div>
            </Dialog>
            <Dialog header="Tlačiť ako" visible={printVisible} style={{ width: '40vw' }} onHide={() => setPrintVisible(false)}>
        <div className="flex flex-columns">
          <select 
          value={printValue} onChange={e => setPrintValue(e.target.value)}
          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
            <option value="Zoznam">Zoznam</option>                      
          </select>
          <button onClick={() => confirmPrintDialog()} className='w-20 ml-8 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>TLAČ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })}
                 <button onClick={() => {setUserMaskFilter("ZoznamInt","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
      </div> 
 </>
    )

}