import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest ,deleteRequest} from '../../../api/ApiController';
import { AddIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon,EditRowIcon,DeleteIcon, EditIcon, PaperClipIcon, ActivateIcon, DeactivateIcon} from "../../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { confirmDialog } from 'primereact/confirmdialog';
import AuthContext from '../../../components/AuthContext';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { ReactTabulator,reactFormatter } from 'react-tabulator';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css"
import '../../../assets/css/style.css';
import '../../../assets/css/iconsmenu.css'
import UtilContext from '../../../components/UtilContext';
import { PanelMenu } from 'primereact/panelmenu';
import { Checkbox } from 'primereact/checkbox';
import { OverflowMenuProvider } from '../../../components/toolbar/OverflowMenu';
import IntersectionObserverWrapper from '../../../components/toolbar/IntersectionObserverWrap';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';

export default function TrainingCompanyContactAdd({training_company, type}) {

    const [state,dispatch] = useContext(DatabaseContext);
    const {setDialogText,setVisible,openFavoriteDialog,toast,setFastView,setUserMaskFilter} = useContext(UtilContext);
    const {user} = useContext(AuthContext);

    const [closeTab,setCloseTab] = useState(false);
    const ref = useRef(null);
    const submitRef = useRef(null);
    const overlayRef = useRef(null);
    const ownToast = useRef(null);

    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState([]);

    const [markUnmark, setMarkUnmarked] = useState(true);

    const [columnsVisible, setColumnsVisible] = useState({name:true,tel_number:true,email:true,description:true,'lecturer.name':true,'machine.name':true});
    const [maskVisible, setMaskVisible] = useState(false);

    const [notificationVisible, setNotificationVisible] = useState(false);
    const [chosenNotifications, setChosenNotifications] = useState([]);

    const [formCompany,setFormCompany] = useState(training_company);
    const [formType, setFormType] = useState(type);

    //TODO dorobit contractor id
    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);
    const [scrollIcons,setScrollIcons] = useState((user.permissions.includes("edit training"))?false:true);
    const [visibleLoading,setVisibleLoading] = useState(false);
    const [visibleCard,setVisibleCard] = useState(false);
    const [editIcon,setEditIcon] = useState(true);
    const [deleteIcon,setDeleteIcon] = useState(true);
    const [copyIcon,setCopyIcon] = useState(true);

    const [selectedCard,setSelectedCard] = useState(null);

    const [showTemplateRecordAdd, setShowTemplateRecordAdd] = useState(false);
    const [showIntervalCalibration, setShowIntervalCalibration] = useState(false);
    const [visibleFiles,setVisibleFiles] = useState(false);
    const [images,setImages] = useState('');
    const [templates,setTemplates] = useState('');
    const [viewedImage,setViewedImage] = useState('');

    const [fileList, setFileList] = useState([]);
    const fileListRef = useRef(null);

    const [updatefileList, setUpdateFileList] = useState([]);
    const updateFileListRef = useRef(null);

    function addNewTrainingCard(){
        setTrainingDepartment([]);
        setTrainings([]);
        setSelectedCard(null);
        setShowTemplateRecordAdd(true);
    }

    function editNewTrainingCard(){
        formikUpdate.setValues(ref.current.getSelectedData()[0]);
        setVisibleCard(true);
    }

    function hideEditCard(){
        formikUpdate.resetForm();
        setVisibleCard(false);
    }

    const onSelectRow = () => {
        setEditIcon(true);
        setCopyIcon(true);
        setDeleteIcon(true);
        if(ref.current.getSelectedData().length === 1){
            if(user.permissions.includes("edit training"))setEditIcon(false);
            if(user.permissions.includes("delete training"))setDeleteIcon(false);
            if(user.permissions.includes("add training"))setCopyIcon(false);
            setSelectedCard(ref.current.getSelectedData()[0]);
        }
    }

    const validate = values => {
        const errors = {};
        if (values?.machines?.length<1) {
            errors.machines = '*Povinné';
        }
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        return errors;
    };

    const formik = useFormik({
        initialValues:{
            employee_option:false,employee_id:'',name:'',tel_number:'',email:'',description:'',lecturer_id:'',machines:[]
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                if(value=="employee_id"){
                    formData.append("employee_id", values.employee_id?.id ? values.employee_id?.id : "");
                }
                else if(value=="machines"){
                    formData.append("machines", values.machines.map(machine => machine.id));
                }
                else formData.append(value, values[value] != null ? values[value] : "");
            }
            formData.append("company_id",formCompany.id);
            postRequest('/api/company_contacts', formData,true)
            .then((response) => {
                getRequest("/api/companies").then((response) => {
                    dispatch({type:'load_companies',newCompanies:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    formik.resetForm();
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    setShowTemplateRecordAdd(false);
                });
                getRequest("/api/company_contacts").then((response) => {
                    dispatch({type:'load_company_contacts',newCompanyContacts:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    const formikUpdate = useFormik({
        initialValues: selectedCard == null ? {
            employee_option:false,employee_id:'',name:'',tel_number:'',email:'',description:'',lecturer_id:'',machines:[]
        } : {
            id:selectedCard?.id,employee_option:selectedCard?.employee_id ? true : false,employee_id:selectedCard?.employee_id ? state.employees?.filter(employee => employee.id == selectedCard?.employee_id)?.map(employee => ({name:employee?.name,id:employee?.id}))[0] : '',
            name:selectedCard?.employee_id ? selectedCard?.employee?.name : selectedCard?.name,
            tel_number:selectedCard?.employee_id ? selectedCard?.employee?.tel_number : selectedCard?.tel_number,
            email:selectedCard?.employee_id ? selectedCard?.employee?.email : selectedCard?.email,
            description:selectedCard?.description,lecturer_id:selectedCard?.lecturer_id,
            machines:selectedCard.machines ? selectedCard.machines.map((machine) => ({name:machine?.name,id:machine?.id})) : [],
        },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                if(value=="employee_id"){
                    formData.append("employee_id", values.employee_id?.id ? values.employee_id?.id : "");
                }
                else if(value=="machines"){
                    formData.append("machines", values.machines.map(machine => machine.id));
                }
                else formData.append(value, values[value] != null ? values[value] : "");
            }
            formData.append("company_id",formCompany.id);
            postRequest(`/api/company_contacts/update/${formikUpdate.values.id}`, formData,true)
            .then((response) => {
                getRequest("/api/companies").then((response) => {
                    dispatch({type:'load_companies',newCompanies:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    setVisibleCard(false);
                    formikUpdate.resetForm();
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                });
                getRequest("/api/company_contacts").then((response) => {
                    dispatch({type:'load_company_contacts',newCompanyContacts:response.data});
                });
            }).catch((reason) => {
                if(!navigator.online){
                    alert("Ste offline, dáta budu odoslané po znovupripojení!");
                }
                else{
                    alert("Daný úkon sa nepodaril!");
                }
            });
        },
    });

    function getIndex(employee_id) {
        return state.training_cards.findIndex(obj => obj.id === employee_id);
    }

    async function scrollToFirst(){
        /*let my_training_card = state.training_cards[0];
        setFormGaugeCalibration(my_training_card);*/
    }
    
    async function scrollUp(){
        /*let id = getIndex(formik.values.id);
        if(id-1>=0){
            setFormGaugeCalibration(state.training_cards[id-1]);
        }*/
    }
    
    async function scrollDown(){
        /*let id = getIndex(formik.values.id);
        if(id+1<state.training_cards.length){
            setFormGaugeCalibration(state.training_cards[id+1]);
        }*/
    }
    
    async function scrollToLast(){
        /*let last = state.training_cards.length-1;
        let my_training_card = state.training_cards[last];
        setFormGaugeCalibration(my_training_card);*/
    }

      function onEditRow(template_record){
        formikUpdate.setValues(template_record);
        setSelectedCard(template_record);
        setVisibleCard(true);
      };
      
      function EditRowFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return <div className='flex items-center justify-center w-full h-full'><button type='button' onClick={() => onEditRow(rowData)}><EditRowIcon/></button></div>;
      }

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavka bola zrušená!', life: 3000 });
    }

    const acceptCalibration = () => {
        let rows_to_delete = [];
        rows_to_delete = ref.current.getSelectedData();
        rows_to_delete.forEach(element => {
          console.log(element);
          deleteRequest(`/api/company_contacts/${element.id}`)
              .then((response) => {
                toast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne vymazaný!" });
                getRequest("/api/companies").then((response) => {
                    dispatch({type:'load_companies',newCompanies:response.data});
                });
                getRequest("/api/company_contacts").then((response) => {
                    dispatch({type:'load_company_contacts',newCompanyContacts:response.data});
                });
              }).catch((reason) => {
                  alert(reason);
                  toast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Záznam sa nepodarilo vymazať!', life: 3000 });
              })
        });
    }

    const confirm = () => {
        confirmDialog({
            message: 'Prajete si vymazať tento záznam?',
            header: 'Potvrdenie vymazania',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Nie',
            rejectLabel: 'Áno',
            accept: reject,
            reject: acceptCalibration
        });
    };
    
    function NameFormatter(props) {
        const rowData = props.cell.getRow().getData();
        console.log(rowData);
        if(rowData?.employee_id){
            return rowData?.employee?.name;
        }
        else{
            return rowData?.name;
        }
    }

    function TelNumberFormatter(props) {
        const rowData = props.cell.getRow().getData();
        console.log(rowData);
        if(rowData?.employee_id){
            return rowData?.employee?.tel_number;
        }
        else{
            return rowData?.tel_number;
        }
    }

    function EmailFormatter(props) {
        const rowData = props.cell.getRow().getData();
        console.log(rowData);
        if(rowData?.employee_id){
            return rowData?.employee?.email;
        }
        else{
            return rowData?.email;
        }
    }

    function machineHeaderFilter(headerValue, rowValue, rowData, filterParams){
        if(rowData.machines?.length<0)return false;
        else return rowData.machines.map(machine => machine?.name).join('/').toLowerCase().includes(headerValue.toLowerCase());
    }

    function MachineFormatter(props) {
        const rowData = props.cell.getRow().getData();
        return rowData.machines.map((item) => item.name).join('/');
    }

    const [columns,setColumns] = useState([
        { formatter:"rowSelection", titleFormatter:"rowSelection", hozAlign:"center",width: 20,headerSort:false},
        { hozAlign:"center",width: 20,headerSort:false,formatter: reactFormatter(<EditRowFormatter/>),resizable:false},
        { title: 'Meno', field: 'name',headerFilter:"input",minWidth: 200,widthGrow:2,resizable:false,formatter: reactFormatter(<NameFormatter/>)},
        { title: 'Tel. číslo', field: 'tel_number',headerFilter:"input",minWidth: 200,widthGrow:2,resizable:false,formatter: reactFormatter(<TelNumberFormatter/>)},
        { title: 'Email', field: 'email',minWidth: 200,widthGrow:2,headerFilter:"input",resizable:false,formatter: reactFormatter(<EmailFormatter/>)},
        { title: 'Popis', field: 'description',minWidth: 200,widthGrow:2,headerFilter:"input",resizable:false},
        { title: 'Lektor', field: 'lecturer.name',minWidth: 200,widthGrow:2,headerFilter:"input",resizable:false},
        { title: 'Stroj', field: 'machine.name',minWidth: 200,widthGrow:2,headerFilter:"input",resizable:false,formatter: reactFormatter(<MachineFormatter/>),headerFilterFunc:machineHeaderFilter},
    ]);

    function setMask(item,value){
        const columnVisible = {...columnsVisible};
       columnVisible[item] = value;
       console.log(columnVisible);
        for(const val in columnVisible) {
          if(columnVisible[val]==false){
            ref.current.hideColumn(`${val}`);
          }
          else{
            ref.current.showColumn(`${val}`);
          }
        }
        setColumnsVisible(columnVisible);
      }

      const itemRenderer = (item, options) => (
        <a className="flex align-items-center px-3 py-2 cursor-pointer items-center bg-[#333] text-white w-[180px]" onClick={options.onClick}>
            <span className={`${item.icon} text-primary`} />
            <span className={`mx-2 ${item.items && 'font-semibold'}`}>{item.label}</span>
            {item.shortcut && <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{item.shortcut}</span>}
        </a>
      );
    
      const [expandedKeys, setExpandedKeys] = useState({});
    
      const handleClickOutside = (event) => {
        if (overlayRef.current && !overlayRef.current.contains(event.target)) {
            setExpandedKeys({});
        }
      };
    
      useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
  
      const items = [
      {
        key: '0',
        label: 'Akcie',
        icon: 'pi pi-sliders-v',
        template: itemRenderer,
        items: [
            {
                key: '0_1',
                label: 'Nastaviť filter',
                icon: 'pi pi-filter',
                command: () => {setUserMaskFilter("TrainingCompanyContactAdd","set_filters",null,ref.current.getHeaderFilters(),null);},
                template: itemRenderer,
            },
              {
                key: '0_2',
                label: 'Zrušiť filter',
                icon: 'pi pi-filter-slash',
                command: () => {setUserMaskFilter("TrainingCompanyContactAdd","set_filters",null,[],null);ref.current.clearHeaderFilter();},
                template: itemRenderer,
            },
            {
                key: '0_3',
                label: 'Nast. triedenie',
                icon: 'pi pi-sort-alt',
                command: () => {setUserMaskFilter("TrainingCompanyContactAdd","set_sorters",null,null,null,ref.current.getSorters());OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
                key: '0_4',
                label: 'Zrušiť triedenie',
                icon: 'pi pi-sort-alt-slash',
                command: () => {setUserMaskFilter("TrainingCompanyContactAdd","set_sorters",null,null,null,[]);ref.current.setSort([]);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
                key: '0_5',
                label: 'Nastaviť masku',
                icon: 'pi pi-server',
                command: () => {setMaskVisible(true);OverflowMenuProvider.setVisible(null);setExpandedKeys({});},
                template: itemRenderer,
            },
            {
              key: '0_6',
              label: 'Notifikácie',
              icon: 'pi pi-envelope',
              command: () => {showChosenNotifications();OverflowMenuProvider.setVisible(null);},
              template: itemRenderer,
          },
        ]
      }
    ];

    function showChosenNotifications(){
        let db_chosen_notifications = state.chosen_notifications?.filter((item => item.type == "otk_int"));
        if(db_chosen_notifications?.length>0){
          setChosenNotifications(JSON.parse(db_chosen_notifications[0].data));
        }
        else{
          setChosenNotifications([]);
        }
        setNotificationVisible(true);
      }
    
      function saveChosenNotifications(){
          let formData = new FormData();
          formData.append("type","training_cards");
          formData.append("data", JSON.stringify(chosenNotifications));
          postRequest('/api/chosen_notifications', formData,true)
          .then((response) => {
              if(response.status == 201){
                dispatch({type:'load_chosen_notifications',newChosenNotifications:response.data});
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Stav aktualizovaný!" });
              }else{
                let mytoast = toast.current ? toast : ownToast;
                mytoast.current?.show({ severity: 'warn', summary: 'Odmietnuté', detail: 'Požiadavku sa nepodarilo vykonať!', life: 3000 });
              }
          }).catch((reason) => {
                console.log(reason);
                alert("Daný úkon sa nepodaril!");
          });
          setNotificationVisible(false);
      }
    
      function changeMarkings(value){
        if(value){
          const columnVisible = {...columnsVisible};
          for(let val in columnVisible) {
            if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor" || columnVisible[val]!=="end_of_validity"){
              columnVisible[val]=true;
              ref.current.showColumn(`${val}`);
            }
          };
          setColumnsVisible(columnVisible);
        }
        else{
          const columnVisible = {...columnsVisible};
          for(let val in columnVisible) {
            if(columnVisible[val]!=="specific_id" || columnVisible[val]!=="editor"|| columnVisible[val]!=="end_of_validity"){
              columnVisible[val]=false;
              ref.current.hideColumn(`${val}`);
            }
          };
          setColumnsVisible(columnVisible);
        }
      }

      function changeEmployee(select_employee){
        if(showTemplateRecordAdd){
            formik.setFieldValue("employee_id",select_employee);
            formik.setFieldValue("tel_number",'');
            formik.setFieldValue("email",'');
            if(select_employee?.id){
                let chosen_employee = state.employees.filter(employee => employee.id == select_employee.id)[0];
                if(chosen_employee){
                    formik.setFieldValue("tel_number",chosen_employee?.tel_number ? chosen_employee?.tel_number : '');
                    formik.setFieldValue("email",chosen_employee?.email ? chosen_employee?.email : '');
                }
            }
        }
        else{
            formikUpdate.setFieldValue("employee_id",select_employee);
            formikUpdate.setFieldValue("tel_number",'');
            formikUpdate.setFieldValue("email",'');
            if(select_employee?.id){
                let chosen_employee = state.employees.filter(employee => employee.id == select_employee.id)[0];
                if(chosen_employee){
                    formikUpdate.setFieldValue("tel_number",chosen_employee?.tel_number ? chosen_employee?.tel_number : '');
                    formikUpdate.setFieldValue("email",chosen_employee?.email ? chosen_employee?.email : '');
                }
            }
        }
      }


    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <IntersectionObserverWrapper>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="add"><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTrainingCard()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="edit"><button className="disabled:opacity-25" disabled={editIcon} onClick={() => editNewTrainingCard()}><EditIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="delete"><button className="disabled:opacity-25" disabled={deleteIcon} onClick={confirm}><DeleteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="first"><button className="disabled:opacity-25" onClick={() => scrollToFirst()} disabled={scrollIcons}><BackToFirstIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="back"><button className="disabled:opacity-25" onClick={() => scrollUp()} disabled={scrollIcons}><BackIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="forward"><button className="disabled:opacity-25" onClick={() => scrollDown()} disabled={scrollIcons}><ForwardIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="last"><button className="disabled:opacity-25" onClick={() => scrollToLast()} disabled={scrollIcons}><ForwardToLastIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="favorite"><button className="disabled:opacity-25" disabled onClick={() => openFavoriteDialog("Upraviť školiteľov škol. firmy","TrainingCompanyContactAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="price"><button className="disabled:opacity-25" disabled  onClick={() => setFastView("TrainingCompanyContactAdd",columnsVisible,columns)}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center' data-targetid="vert"><VerticalLineIcon/></div>
          <div className="card flex justify-content-center z-[100]" data-targetid="actions" ref={overlayRef}>
            <PanelMenu model={items} expandedKeys={expandedKeys} onExpandedKeysChange={setExpandedKeys} className="w-full md:w-20rem"/>
          </div>
          </IntersectionObserverWrapper>
        </div>
        <Splitter className='h-[calc(100vh_-_177px)]' layout="vertical" pt={{ gutter: { className: 'bg-[#333] h-8' },gutterHandler: { className: 'bg-secondary ' } }}>
        <SplitterPanel className="flex align-items-center justify-content-center overflow-y-auto " minSize={20} size={50}>
        <div className="w-full relative flex flex-col overflow-x">
          <div className='xl:grid grid-cols-2 gap-1 mb-2'>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ŠKOLITELIA ŠKOLIACEJ FIRMY
                </h1>
                <label className="block text-md font-semibold text-gray-800">Por.č.: {formCompany.id}</label>
                        <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            NÁZOV firmy
                        </label>
                        <input
                            name='name'
                            type="text"
                            value={formCompany.name} readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="tel_number"
                            className="block text-md font-semibold text-gray-800"
                        >
                            TELEFÓNNE ČÍSLO
                        </label>
                        <input
                            name='tel_number'
                            type="text"
                            value={formCompany.tel_number} readOnly
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    
                      <div>
                        <label
                            htmlFor="email"
                            className="block text-md font-semibold text-gray-800"
                        >
                            E-MAIL
                        </label>
                        <input
                            name='email'
                            type="email"
                            value={formCompany.email}  readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="contact_person"
                            className="block text-md font-semibold text-gray-800 mt-2"
                        >
                            KONTAKTNÁ OSOBA
                        </label>
                        <input
                            name='contact_person'
                            type="text"
                            value={formCompany.contact_person}  readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>        
            </div>
            <div className="w-auto p-6 m-4 bg-gray-100 rounded-md shadow-md">
                    <div>
                        <label
                            htmlFor="address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            ADRESA
                        </label>
                        <input
                            name='address'
                            type="text"
                            value={formCompany.address}  readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="fax"
                            className="block text-md font-semibold text-gray-800"
                        >
                             FAX
                        </label>
                        <input
                            name='fax'
                            type="text"
                            value={formCompany.fax}  readOnly
                            className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="web_address"
                            className="block text-md font-semibold text-gray-800"
                        >
                            WEB. ADRESA
                        </label>
                        <input
                            name='web_address'
                            type="text"
                            value={formCompany.web_address}  readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            value={formCompany.description}  readOnly
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>  
                        <button className='hidden' type='submit' ref={submitRef}></button>    
            </div>
            </div> 
            </div>
             </SplitterPanel>
    <SplitterPanel className="flex flex-col align-items-center justify-content-center w-full overflow-y-auto" size={40} style={{minHeight: '110px'}}>
    <ReactTabulator
      data={state.companies.filter((card) => card.id == formCompany.id)[0].contacts} 
      onRef={(r) => (ref.current = r.current)}
      className='h-full w-full' //176px h-[calc(100vh_-_176px)]
      columns={columns}
      events={{
        rowSelectionChanged: onSelectRow,
        tableBuilt: () => {
            let mask = state.user_mask_filters.filter(mask => mask.page=="TrainingCompanyContactAdd");
              if(mask.length>0){
                let attributes = [];
                try {
                  attributes = JSON.parse(mask[0].attributes)
                } catch (e) {
                  attributes = []
                }
                if(attributes?.length>1)ref.current.setColumnLayout(attributes);
                let filters = [];
                try {
                  filters = JSON.parse(mask[0].filters);
                } catch (e) {
                  filters = []
                }
                filters.forEach(filter => {
                  ref.current.setHeaderFilterValue(filter.field,filter.value);
                });
                let sorters = [];
                try {
                  sorters = JSON.parse(mask[0]?.sorters);
                } catch (e) {
                  sorters = []
                }
                if(sorters?.length>0)ref.current.setSort(sorters);
                let json_columns;
                try {
                  json_columns = JSON.parse(mask[0].columns);
                  setColumnsVisible(json_columns);
                } catch (e) {
                  json_columns = []
                }
                for(let column in json_columns){
                  if(json_columns[column]==false)ref.current.hideColumn(column);
                  else ref.current.showColumn(column);
                }
              }
          },
          columnMoved:() => {
            setUserMaskFilter("TrainingCompanyContactAdd","column_move",null,null,ref.current.getColumnLayout());
          }
      }}
      options={{renderHorizontal:"virtual",
      printAsHtml:true,printHeader:"<h1>Karta zamestnanca<h1>",printRowRange:"active",
      }}/>
    </SplitterPanel>
    </Splitter> 
    <Dialog header="Upraviť školiteľa" visible={visibleCard} style={{ width: '40vw' }} onHide={() => hideEditCard()}>
        <form onSubmit={formikUpdate.handleSubmit}>
        <div className="flex align-items-center mt-1">
                <Checkbox name="employee_option" value={"employee_option"} onChange={(e) => formikUpdate.setFieldValue("employee_option",e.checked)} checked={formikUpdate.values.employee_option} className='p-button-sm p-button-text'/>
                <label htmlFor={"employee_option"} className="ml-2">Nastaviť zamestnanca</label>
            </div>
            {!formikUpdate.values.employee_option && <div>
            <label
                htmlFor="name"
                className="block text-md font-semibold text-gray-800"
            >
                Meno
            </label>
            <input
                name='name'
                type="text"
                onChange={formikUpdate.handleChange} value={formikUpdate.values.name}
                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            </div>}
            {formikUpdate.values.employee_option && <div>
            <label
                htmlFor="employee_id"
                className="block text-md font-semibold text-gray-800"
            >
                Zamestnanec
            </label>
            <Dropdown value={formikUpdate.values.employee_id} onChange={(e) => changeEmployee(e.value)} 
                options={state.employees.filter(employee => employee?.date_of_departure=="" || employee?.date_of_departure==null).map((employee) => 
                    ({name:employee.name,id:employee.id})
                )} 
                optionLabel="name" required
                filter placeholder="Vyber zamestnanca" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
            </div>}
            <div>
            <label
                htmlFor="tel_number"
                className="block text-md font-semibold text-gray-800"
            >
                Telefónne číslo
            </label>
            <input
                name='tel_number'
                type="text"
                readOnly={formikUpdate.values.employee_option}
                onChange={formikUpdate.handleChange} value={formikUpdate.values.tel_number}
                className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            </div>
        
            <div>
            <label
                htmlFor="email"
                className="block text-md font-semibold text-gray-800"
            >
                E-mail
            </label>
            <input
                name='email'
                type="email"
                readOnly={formikUpdate.values.employee_option}
                onChange={formikUpdate.handleChange} value={formikUpdate.values.email}  
                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            </div>  
            <div>
            <label
                htmlFor="description"
                className="block text-md font-semibold text-gray-800"
            >
                Popis
            </label>
            <textarea
                name='description'
                type="text"
                onChange={formikUpdate.handleChange} value={formikUpdate.values.description}
                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
            </textarea>
            </div>  
            <div>
            <label
                htmlFor="lecturer_id"
                className="block text-md font-semibold text-gray-800"
            >
                Lektor
            </label>
            <select 
                name="lecturer_id" required
                onChange={formikUpdate.handleChange} value={formikUpdate.values.lecturer_id} 
                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                    <option value="">Vyber lektora</option>
                    {state.lecturers.map((lecturer) => (
                    <option key={lecturer.id} value={lecturer.id}>{lecturer.name}</option>
                ))}
            </select>
            </div>
            <div>
            <label
                htmlFor="machines"
                className="block text-md font-semibold text-gray-800"
            >
                Stroj
            </label>
            <MultiSelect value={formikUpdate.values.machines} onChange={(e) => formikUpdate.setFieldValue("machines",e.value)} 
                options={state.machines.map((machine) => 
                    ({name:machine.name,id:machine.id})
                )} 
                optionLabel="name"
                filter placeholder="Vyber stroj/e" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
            {formikUpdate.errors?.machines ? <span style={{color: "red"}}>{formikUpdate.errors?.machines}</span> : ""}     
            </div>
            <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
        </form>
    </Dialog>
        <Dialog header="Pridať školiteľa" visible={showTemplateRecordAdd} style={{ width: '40vw' }} onHide={() => setShowTemplateRecordAdd(false)}>
        <form onSubmit={formik.handleSubmit}>
            <div className="flex align-items-center mt-1">
                <Checkbox name="employee_option" value={"employee_option"} onChange={(e) => formik.setFieldValue("employee_option",e.checked)} checked={formik.values.employee_option} className='p-button-sm p-button-text'/>
                <label htmlFor={"employee_option"} className="ml-2">Nastaviť zamestnanca</label>
            </div>
            {!formik.values.employee_option && <div>
            <label
                htmlFor="name"
                className="block text-md font-semibold text-gray-800"
            >
                Meno
            </label>
            <input
                name='name'
                type="text"
                onChange={formik.handleChange} value={formik.values.name}
                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            </div>}
            {formik.values.employee_option && <div>
            <label
                htmlFor="employee_id"
                className="block text-md font-semibold text-gray-800"
            >
                Zamestnanec
            </label>
            <Dropdown value={formik.values.employee_id} onChange={(e) => changeEmployee(e.value)} 
                options={state.employees.filter(employee => employee?.date_of_departure=="" || employee?.date_of_departure==null).map((employee) => 
                    ({name:employee.name,id:employee.id})
                )} 
                optionLabel="name" required
                filter placeholder="Vyber zamestnanca" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
            </div>}
            <div>
            <label
                htmlFor="tel_number"
                className="block text-md font-semibold text-gray-800"
            >
                Telefónne číslo
            </label>
            <input
                name='tel_number'
                type="text"
                readOnly={formik.values.employee_option}
                onChange={formik.handleChange} value={formik.values.tel_number}
                className="block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            </div>
        
            <div>
            <label
                htmlFor="email"
                className="block text-md font-semibold text-gray-800"
            >
                E-mail
            </label>
            <input
                name='email'
                type="email"
                readOnly={formik.values.employee_option}
                onChange={formik.handleChange} value={formik.values.email}  
                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
            </div>  
            <div>
            <label
                htmlFor="description"
                className="block text-md font-semibold text-gray-800"
            >
                Popis
            </label>
            <textarea
                name='description'
                type="text"
                onChange={formik.handleChange} value={formik.values.description}
                className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
            >
            </textarea>
            </div> 
            <div>
            <label
                htmlFor="lecturer_id"
                className="block text-md font-semibold text-gray-800"
            >
                Lektor
            </label>
            <select 
                name="lecturer_id" required
                onChange={formik.handleChange} value={formik.values.lecturer_id} 
                className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                    <option value="">Vyber lektora</option>
                    {state.lecturers.map((lecturer) => (
                    <option key={lecturer.id} value={lecturer.id}>{lecturer.name}</option>
                ))}
            </select>
            </div>
            <div>
            <label
                htmlFor="lecturer_id"
                className="block text-md font-semibold text-gray-800"
            >
                Stroj
            </label>
            <MultiSelect value={formik.values.machines} onChange={(e) => formik.setFieldValue("machines",e.value)} 
                options={state.machines.map((machine) => 
                    ({name:machine.name,id:machine.id})
                )} 
                optionLabel="name"
                filter placeholder="Vyber stroj/e" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
            {formik.errors?.machines ? <span style={{color: "red"}}>{formik.errors?.machines}</span> : ""} 
            </div>   
            <button type="submit" className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>         
        </form>
        </Dialog>
        <Dialog header="Nastaviť masku" visible={maskVisible} style={{ width: '70vw' }} onHide={() => setMaskVisible(false)}>
        <div className="flex flex-col">
        <button type="button" onClick={() => {changeMarkings(!markUnmark);setMarkUnmarked(!markUnmark);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-700 rounded-md hover:bg-zinc-500'>{markUnmark ? "ODZNAČ VŠETKO" : "OZNAČ VŠETKO"}</button>
        {columns.map(function(item, index){ 
          if(index<2) return null;
                    return <div 
                        key={index} 
                        className="flex flex-row border border-black rounded-md p-2 m-1"
                    > 

                         <Checkbox onChange={(e) => setMask(item.field,e.checked)} checked={columnsVisible[item.field]}></Checkbox><span className="ml-2">{item.title}</span>
                          
                        {/* Use the React icon component */} 
                    </div> 
                })} 
            <button onClick={() => {setUserMaskFilter("TrainingCompanyContactAdd","set_columns",columnsVisible,null,null);setMaskVisible(false);}} className='m-1 px-4 py-2 text-center tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600'>ULOŽIŤ</button>
        </div>
      </Dialog>
      <Dialog header="Nastaviť notifikácie" visible={notificationVisible} style={{ width: '70vw' }} onHide={() => setNotificationVisible(false)}>
            <div className="flex flex-col">
                         <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                          > 
                            <Checkbox name='Nová' value='Nová' checked={chosenNotifications.includes('Nová')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Nová' className="ml-2">Nový záznam</label>
                        </div> 
                        <div  
                            className="flex flex-row border border-black rounded-md p-2 m-1" 
                        > 
                            <Checkbox name='Zmena stavu' value='Zmena stavu' checked={chosenNotifications.includes('Zmena stavu')}
                            onClick={(e) => {
                                let edit = [...chosenNotifications];
                                console.log(e,edit);
                                if (e.checked){
                                    edit.push(e.value);
                                }
                                else
                                    edit.splice(edit.indexOf(e.value), 1);
                                setChosenNotifications(edit);
                            }}></Checkbox>
                            <label htmlFor='Zmena stavu' className="ml-2">Zmena stavu</label>
                        </div>
                    
              <button type='button' onClick={() => saveChosenNotifications()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Uložiť</button>
            </div>
          </Dialog>
            </div>
            </> 
            );
} 
