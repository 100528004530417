import React,{useContext,useEffect, useState, useRef,useReducer} from 'react';
import { DatabaseContext } from '../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon} from "../svg/svgIcons";
import { Dialog } from 'primereact/dialog';
import { InfinitySpin } from  'react-loader-spinner';
import AuthContext from '../../components/AuthContext';
import UtilContext from '../../components/UtilContext';
import { MultiSelect } from 'primereact/multiselect';

export default function TrainingRecordAdd({training}) {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView,setDialogText,setVisible} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const [formTraining,setFormTraining] = useState(training);
    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState(state.training_types);

    const [lecturers, setLecturers] = useState(state.lecturers);
    const [companyContacts, setCompanyContacts] = useState(state.company_contacts);
    const [machines, setMachines] = useState(state.machines);

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);

    const [fileList, setFileList] = useState([]);

    function addNewTraining(){
        dispatch({type:'create_new_tab',name:'Nové školenie',page:'TrainingRecordAdd',props:{training: null,type:'add'},mask:'skol012'})
      }
      function copyTraining(){
        dispatch({type:'create_new_tab',name:'Nové školenie',page:'TrainingRecordAdd',props:{training: formik.values,type:'add'},mask:'skol012'})
      }

    const validate = values => {
        const errors = {};
        if(!values.training_id){
            errors.training_id = '*Povinné';
        }
        else{
            let my_employees = values.employees.map(employee => employee.id);
            for (var i = 0; i < my_employees.length; i++) {
                let card = state.training_cards.filter(emp => emp.id == my_employees[i]);
                if(card.length<1){
                    if(!errors?.employees)errors.employees = '';
                    errors.employees += 'Zamestnanec '+ state.employees.filter(emp => emp.id == my_employees[i])[0]?.name +' nemá založenú kartu!\n';
                }
                    else{                
                    let training = card[0].training_card.filter(card => card.employee_id == my_employees[i] && card.training_id==values.training_id);
                    if(training.length<1){
                        if(!errors?.employees)errors.employees = '';
                        errors.employees += 'Zamestnanec '+ state.employees.filter(emp => emp.id == my_employees[i])[0]?.name +' nemá evidované dané školenie!\n';
                    }
                }
            }
        }
        if (values?.machines?.length<1) {
            errors.machines = '*Povinné';
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: formTraining==null?{
            training_id: '',last_training_date: '',employees:[],description:'',company_id:'',machines:[],lecturer_id:'',company_contact_id:''}
            :formTraining,
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                if(value=="machines"){
                    formData.append("machines", values.machines.map(machine => machine.id));
                }
                else if(value=="employees"){
                    let my_employees = values.employees.map(employee => employee.id);
                    for (var i = 0; i < my_employees.length; i++) {
                        formData.append('employees[]', my_employees[i]);
                    }
                }
                else formData.append(value, values[value] != null ? values[value] : "");
            }
            /*for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }*/
            for(let i = 0;i<fileList.length;i++){
                //console.log("IMAGE :"+fileList[i].name);
                formData.append('files[]',fileList[i]);
            }
            setDialogText("Ukladám");
            setVisible(true);
            postRequest('/api/training_records', formData,true)
            .then((response) => {
                console.log(response);
                getRequest("/api/training_cards").then((response) => {
                    dispatch({type:'load_training_cards',newTrainingCards:response.data});
                    if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                    //if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                    formik.resetForm();
                    setTrainingDepartment("");
                    setTrainings([]);
                    let mytoast = toast.current ? toast : ownToast;
                    mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    setVisible(false);
                });
                getRequest("/api/training_employees").then((response) => {
                    dispatch({type:'load_training_employees',newTrainingEmployees:response.data});
                });
            }).catch((reason) => {
                setVisible(false);
                alert("Daný úkon sa nepodaril!");
            })
        },
    });

    function filterTrainings(departmentId){
        setTrainingDepartment(departmentId);
        if(departmentId=="")setTrainings(state.training_types);
        else setTrainings(state.training_types.filter(training_type => training_type.department.id === parseInt(departmentId)));
    }

    const handleFileChange = (event) => {
        setFileList(event.target.files);
    };

    function changeTraining(training_id){
        formik.setFieldValue('training_id',training_id);
        let company_id = state.training_types.filter(training => training.id == training_id)[0]?.training_company_id;
        if(company_id){
            formik.setFieldValue('company_id',company_id);
            const machines = [];
            state.company_contacts.filter(contact => contact.company_id == company_id)?.forEach(contact => {
                contact?.machines?.map(machine => machines.push(machine.id));
            });
            setMachines(state.machines.filter(machine => machines.includes(machine.id)));
            formik.setFieldValue('machines',[]);
            formik.setFieldValue('lecturer_id','');
            formik.setFieldValue('company_contact_id','');
        }
        let machines = state.training_types.filter(training => training.id == training_id)[0]?.machines;
        if(machines?.length>0){
            formik.setFieldValue('machines',machines);
            let machines_ids = machines.map(mach => mach.id);
            let contacts = state.company_contacts.filter(contact => contact.company_id == formik.values?.company_id && machines_ids.every(obj => contact.machines?.map(machin => machin.id).includes(obj)))?.map(contact => contact.lecturer_id);
            setLecturers(state.lecturers.filter(lecturer => contacts.includes(lecturer.id)));
            formik.setFieldValue('lecturer_id','');
            formik.setFieldValue('company_contact_id','');
        }
        formik.setFieldValue("employees",[]);
    }

    function changeCompany(company_id){
        formik.setFieldValue('company_id',company_id);
        const machines = [];
        state.company_contacts.filter(contact => contact.company_id == company_id)?.forEach(contact => {
            contact?.machines?.map(machine => machines.push(machine.id));
        });
        setMachines(state.machines.filter(machine => machines.includes(machine.id)));
        formik.setFieldValue('machines',[]);
        formik.setFieldValue('lecturer_id','');
        formik.setFieldValue('company_contact_id','');
        formik.setFieldValue("employees",[]);
      }

      function changeMachine(machines){
        formik.setFieldValue("machines",machines);
        let machines_ids = machines.map(mach => mach.id);
        let contacts = state.company_contacts.filter(contact => contact.company_id == formik.values?.company_id && machines_ids.every(obj => contact.machines?.map(machin => machin.id).includes(obj)))?.map(contact => contact.lecturer_id);
        setLecturers(state.lecturers.filter(lecturer => contacts.includes(lecturer.id)));
        formik.setFieldValue('lecturer_id','');
        formik.setFieldValue('company_contact_id','');
        formik.setFieldValue("employees",[]);
      }
        

      function changeLecturer(lecturer_id){
        formik.setFieldValue('lecturer_id',lecturer_id);
        setCompanyContacts(state.company_contacts.filter(contact => contact.company_id == formik.values?.company_id && formik.values?.machines.map(machin => machin.id).every(obj => contact.machines?.map(machin => machin.id).includes(obj)) && contact.lecturer_id == lecturer_id));
        formik.setFieldValue('company_contact_id','');
        formik.setFieldValue("employees",[]);
      }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            {/*<div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>*/}
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewTraining()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyTraining()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová škol. firma","TrainingRecordAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingRecordAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <div className='overflow-y-auto h-[calc(100vh-177px)] bg-gray-100'>
        <form onSubmit={formik.handleSubmit}>
        <div className="relative flex flex-col">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   ZAZNAMENAŤ NOVÉ ŠKOLENIE
                </h1>
                    <div>
                        <label
                            htmlFor="trainingDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie školenia
                        </label>
                        <select 
                        name='trainingDepartment'
                        onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber oddelenie</option>
                          {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </div>
                        <div>
                        <label
                            htmlFor="training_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov školenia
                        </label>
                        <select 
                        required
                        name="training_id"
                        onChange={(e) => changeTraining(e.target.value)} value={formik.values.training_id} 
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                          <option value="">Vyber školenie</option>
                          {trainings.map((training) => (
                            <option key={training.id} value={training.id}>{training.name}</option>
                            ))}
                        </select>
                        </div>
                      <div>
                        <label
                            htmlFor="last_training_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum školenia
                        </label>
                        <input
                            name='last_training_date'
                            type="date" required
                            onChange={formik.handleChange} value={formik.values.last_training_date}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div> 
                        <div>
                        <label
                            htmlFor="description"
                            className="block text-md font-semibold text-gray-800"
                        >
                            POZNÁMKA
                        </label>
                        <textarea
                            name='description'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.description}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        >
                        </textarea>
                        </div>
                        <div>
                            <label
                                htmlFor="company_id"
                                className="block text-md font-semibold text-gray-800"
                            >
                            Škol. firma
                            </label>
                            <select required
                            name='company_id'
                            onChange={(e) => changeCompany(e.target.value)} value={formik.values?.company_id}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="">Vyber škol.firmu</option>
                            {state.training_companies.map((training_company) => (
                                <option key={training_company.id} value={training_company.id}>{training_company.name}</option>
                            ))}
                            </select>
                        </div> 
                        {(formik.values?.company_id!="" && formik.values?.company_id!=null) && <><div>
                            <label
                                htmlFor="machines"
                                className="block text-md font-semibold text-gray-800"
                            >
                                Stroj*
                            </label>
                            <MultiSelect value={formik.values.machines} onChange={(e) => changeMachine(e.value)}
                                options={machines.map((machine) => 
                                    ({name:machine.name,id:machine.id})
                                )} 
                                optionLabel="name"
                                filter placeholder="Vyber stroj/e" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                            {formik.errors?.machines ? <span style={{color: "red"}}>{formik.errors?.machines}</span> : ""}
                        </div>
                        {formik.values?.machines?.length > 0 && <><div>
                            <label
                                htmlFor="lecturer_id"
                                className="block text-md font-semibold text-gray-800"
                            >
                            Lektor
                            </label>
                            <select required
                            name='lecturer_id'
                            onChange={(e) => changeLecturer(e.target.value)} value={formik.values?.lecturer_id}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="">Vyber lektora</option>
                            {lecturers.map((lecturer) => (
                                <option key={lecturer.id} value={lecturer.id}>{lecturer.name}</option>
                            ))}
                            </select>
                        </div>
                        {(formik.values?.lecturer_id!="" && formik.values?.lecturer_id!=null) && <><div>
                            <label
                                htmlFor="company_contact_id"
                                className="block text-md font-semibold text-gray-800"
                            >
                            Školiteľ
                            </label>
                            <select required
                            name='company_contact_id'
                            onChange={formik.handleChange} value={formik.values?.company_contact_id}
                            className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                            <option value="">Vyber školiteľa</option>
                            {companyContacts.map((contact) => (
                                <option key={contact.id} value={contact.id}>{contact?.employee?.name || contact?.name}</option>
                            ))}
                            </select>
                        </div>
                        {(formik.values?.company_contact_id!="" && formik.values?.company_contact_id!=null) &&<div>
                        <label
                            for="employees"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zamestnanci
                        </label>
                        <MultiSelect required value={formik.values.employees} onChange={(e) => formik.setFieldValue("employees",e.value)} 
                        options={state.training_cards.filter(card => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)?.length > 0)
                            .filter(card => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.company_id == formik.values?.company_id && formik.values?.machines.map(machin => machin.id).every(obj => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.machines.map(machin => machin.id).includes(obj)) && formik.values?.lecturer_id == card?.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.lecturer_id ? card?.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.lecturer_id : card?.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.company_contact?.lecturer_id)
                            //.filter(card => card?.date_of_departure=="" || card?.date_of_departure==null)
                            .map((employee) => 
                            ({name:employee.name,id:employee.id})
                        )} 
                        optionLabel="name" 
                        filter placeholder="Vyber zamestnancov" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        {formik.errors.employees ? <div className="whitespace-pre-line" style={{color:'red'}}>{formik.errors.employees}</div> : null}
                        <div>
                            <label className="block text-md my-2 font-semibold text-gray-800">POČET: {formik.values.employees.length}</label>
                        </div>
                        </div>}</>}</>} </>}
                        <div>
                        <label
                            htmlFor="files"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Protokol
                        </label>
                          <input required
                          accept='image/*,application/pdf'
                          name='files'
                          ref={fileListRef}
                          onChange={handleFileChange}
                          className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'
                          type='file'
                          multiple="multiple"
                          id='file'/>
                        </div>
                        <button type='submit' ref={submitRef}></button>
            </div>
            </div>
            </div> 
             </form>
            </div>
            </> 
            );

}