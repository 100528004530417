import React,{useContext, useState, useRef,useEffect} from 'react';
import { DatabaseContext } from '../../../redux/DatabaseStore';
import { useFormik } from 'formik';
import { Toast } from 'primereact/toast';
import { getRequest, postRequest } from '../../../api/ApiController';
import { SaveIcon, AddIcon, CopyIcon, PrintIcon, VerticalLineIcon, BackToFirstIcon, BackIcon, ForwardIcon, ForwardToLastIcon, FavoriteIcon, PriceIcon, SaveAndCloseIcon, InviteDate, InviteTime, InviteLocation, TrainingTheme} from "../../svg/svgIcons";
import AuthContext from '../../../components/AuthContext';
import UtilContext from '../../../components/UtilContext';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { InputSwitch } from 'primereact/inputswitch';
import { DateTime } from "luxon";

export default function TrainingInvitationAdd({invitation,type}) {
    
    const [state,dispatch] = useContext(DatabaseContext);
    const {user} = useContext(AuthContext);
    const {toast,openFavoriteDialog,setFastView} = useContext(UtilContext);
    const [closeTab,setCloseTab] = useState(false);
    const submitRef = useRef(null);
    const fileListRef = useRef(null);
    const ownToast = useRef(null);

    const ref = useRef(null);
    const emailContentRef = useRef(null);
    const smsContentRef = useRef(null);
    const [formType,setFormType] = useState(type ? type : "add");

    const [showInvitationPreview,setShowInvitationPreview] = useState(false);
    const [procesedText,setProcesedText] = useState("");

    const [formInvitation,setFormInvitation] = useState(invitation);
    const [trainingDepartment,setTrainingDepartment] = useState([]);
    const [trainings,setTrainings] = useState(state.training_types);
    
    const [lecturers, setLecturers] = useState(state.lecturers);
    const [companyContacts, setCompanyContacts] = useState(state.company_contacts);
    const [machines, setMachines] = useState(state.machines);

    const [emailTemplate,setEmailTemplate] = useState('');
    const [smsTemplate,setSmsTemplate] = useState('');

    const [insertCopyIcon,setInsertCopyIcon] = useState((user.permissions.includes("add training"))?false:true);


    useEffect(() => {
        // Update the document title using the browser API
        if(formInvitation!=null){
            filterTrainings(formInvitation.training?.department_id);
            const my_machines = [];
            state.company_contacts.filter(contact => contact.company_id == formInvitation?.company_id)?.forEach(contact => {
                contact?.machines?.map(machine => my_machines.push(machine.id));
            });        
            setMachines(state.machines.filter(machine => my_machines.includes(machine.id)));
            let my_contacts = state.company_contacts.filter(contact => contact.company_id == formInvitation?.company_id && contact.machines.filter(machine => formInvitation?.machines.map(mach => mach.id).includes(machine.id))?.length > 0)?.map(contact => contact.lecturer_id);
            setLecturers(state.lecturers.filter(lecturer => my_contacts.includes(lecturer.id)));
            setCompanyContacts(state.company_contacts.filter(contact => contact.company_id == formInvitation?.company_id && contact.machines.filter(machine => formInvitation?.machines.map(mach => mach.id).includes(machine.id))?.length > 0 && (contact.lecturer_id == formInvitation?.lecturer_id || contact.lecturer_id == formInvitation?.company_contact?.lecturer_id)));
            setTimeout(() => formik.setFieldTouched('machines',formInvitation?.machines?.map((machine) => ({name:machine.name,id:machine.id}))));
            setTimeout(() => formik.setFieldTouched('company_contact_id',formInvitation?.company_contact_id));
        }
    },[formInvitation]);

    function addNewInvitation(){
        dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationAdd',props:{invitation: null,type:'add'},mask:'skol028'})
    }
    function copyInvitation(){
        dispatch({type:'create_new_tab',name:'Nová pozvánka',page:'TrainingInvitationAdd',props:{invitation: formik.values,type:'add'},mask:'skol028'})
    }

    const validate = values => {
        const errors = {};
        /*if (!values.type) {
            errors.type = '*Povinné';
        }*/
        if (values?.machines?.length<1) {
            errors.machines = '*Povinné';
        }
        if (values?.company_contact_id) {
            let company_contact = state.company_contacts.filter(contact => contact.id == values?.company_contact_id)[0];
            if(company_contact?.employee_id){
                let card = state.training_employees.filter(card => card.training_id == values?.training_id && card.employee_id == company_contact?.employee_id);
                if(card?.length==0)errors.company_contact_id = '*Školiteľ nemá evidované toto školenie';
                else if(card?._children?.length == 0 || (card?._children?.length > 0 && new Date(card._children[0].next_training_date) < new Date()))errors.company_contact_id = '*Školiteľ má školenie po platnosti';
            }
        }
        return errors;
    };

    const formik = useFormik({
        initialValues: formInvitation==null?{
            name: '',invitation_date: '',invitation_time:'',location:'',training_id:'',company_id:'',lecturer_id:'',company_contact_id:'',
            email:true,sms:false,contact_notify:true,email_content:'',sms_content:'',
            notifications:[],employees:[],machines:[],}
            :{
                id:formInvitation.id,name:formInvitation.name,invitation_date:formInvitation.invitation_date,invitation_time:formInvitation.invitation_time,location:formInvitation.location,training_id:formInvitation.training_id,
                company_id:formInvitation.company_id,company_contact_id:formInvitation.company_contact_id,lecturer_id:formInvitation?.company_contact?.lecturer_id || formInvitation?.lecturer_id,
                email:formInvitation.email==1?true:false,sms:formInvitation.sms==1?true:false,contact_notify:formInvitation.contact_notify==1?true:false,
                email_content:formInvitation?.email_content,sms_content:formInvitation?.sms_content,
                notifications:(formInvitation?.notifications && formInvitation?.notifications?.length>1) ? formInvitation?.notifications?.split(',')?.map((notification) => ({name:notification})) : [],
                employees:formInvitation.employees.map((employee) => ({name:employee.name,id:employee.id})),
                machines:formInvitation.machines.map((machine) => ({name:machine.name,id:machine.id}))
            },
        enableReinitialize:true,
        validate,
        onSubmit: values => {
            let formData = new FormData();
            for (let value in values) {
                if(value=="notifications"){
                    formData.append("notifications", values.notifications.map(condition => condition.name));
                }
                else if(value=="employees"){
                    formData.append("employees", values.employees.map(employee => employee.id));
                }
                else if(value=="machines"){
                    formData.append("machines", values.machines.map(machine => machine.id));
                }
                else  formData.append(value, values[value] != null ? values[value] : "");
            }
            if(formType=="add"){
                postRequest('/api/training_invitations', formData,true)
                .then((response) => {
                    setFormType("edit");
                    getRequest("/api/training_invitations").then((response) => {
                        dispatch({type:'load_training_invitations',newTrainingInvitations:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        if(response.data.length-1>=0)formik.setValues(response.data[response.data.length-1]);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne uložený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
            else{
                postRequest(`/api/training_invitations/update/${formik.values.id}`, formData,true)
                .then((response) => {
                    getRequest("/api/training_invitations").then((response) => {
                        dispatch({type:'load_training_invitations',newTrainingInvitations:response.data});
                        if(closeTab)dispatch({type:'close_tab',id:state.selectedTab});
                        formik.setValues(values);
                        let mytoast = toast.current ? toast : ownToast;
                        mytoast.current?.show({ severity: 'info', summary: 'Potvrdené', life: 3000, detail: "Záznam bol úspešne upravený!" });
                    });
                }).catch((reason) => {
                    if(!navigator.online){
                        alert("Ste offline, dáta budu odoslané po znovupripojení!");
                    }
                    else{
                        alert("Daný úkon sa nepodaril!");
                    }
                })
            }
        },
    });

    function insertMyText(type,textToInsert){
        if(type=="email"){
            let cursorPosition = emailContentRef.current.selectionStart;
            let textBeforeCursorPosition = emailContentRef.current.value.substring(0, cursorPosition);
            let textAfterCursorPosition = emailContentRef.current.value.substring(cursorPosition, emailContentRef.current.value.length);
            emailContentRef.current.value = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
            emailContentRef.current.selectionStart = emailContentRef.current.selectionStart + textToInsert.length;
            emailContentRef.current.focus();
            formik.setFieldValue("email_content", emailContentRef.current.value);
        }
        else{
            let cursorPosition = smsContentRef.current.selectionStart;
            let textBeforeCursorPosition = smsContentRef.current.value.substring(0, cursorPosition);
            let textAfterCursorPosition = smsContentRef.current.value.substring(cursorPosition, smsContentRef.current.value.length);
            smsContentRef.current.value = textBeforeCursorPosition + textToInsert + textAfterCursorPosition;
            smsContentRef.current.selectionStart = smsContentRef.current.selectionStart + textToInsert.length;
            smsContentRef.current.focus();
            formik.setFieldValue("sms_content", smsContentRef.current.value);
        }
      }

      const dayTimePeriod = [
        {name: '1 deň pred'},
        {name: '2 dní pred'},
        {name: '3 dní pred'},
        {name: '4 dní pred'},
        {name: '5 dní pred'},
        {name: '6 dní pred'},
        {name: '7 dní pred'},
    ];

    function previewInvitation(){
        setShowInvitationPreview(true);
        setProcesedText(formik.values.content.replace("[Dátum]",DateTime.fromISO(formik.values.invitation_date).toFormat("dd.MM.yyyy")).replace("[Čas]",DateTime.fromISO(formik.values.invitation_time).toFormat("HH:MM")).replace("[Miesto]",formik.values.location).replace("[Názov školenia]",state.training_types.filter((training) => training.id == formik.values.training_id)[0]?.name));
    }

    function filterTrainings(departmentId){
        setTrainingDepartment(departmentId);
        if(departmentId=="")setTrainings(state.training_types);
        else setTrainings(state.training_types
        .filter(training_type => training_type.department.id === parseInt(departmentId)));
    }

    function changeTraining(training_id){
        formik.setFieldValue('training_id',training_id);
        formik.setFieldValue('company_id','');
        formik.setFieldValue('machines',[]);
        formik.setFieldValue('lecturer_id','');
        formik.setFieldValue('company_contact_id','');
        formik.setFieldValue("employees",[]);
        let company_id = state.training_types.filter(training => training.id == training_id)[0]?.training_company_id;
        if(company_id){
            formik.setFieldValue('company_id',company_id);
            let machines = [];
            state.company_contacts.filter(contact => contact.company_id == company_id)?.forEach(contact => {
                contact?.machines?.map(machine => machines.push(machine.id));
            });
            machines = machines.filter(function (value, index, array) { 
                return array.indexOf(value) === index;
            });
            setMachines(state.machines.filter(machine => machines.includes(machine.id)));
            let training_machines = state.training_types.filter(training => training.id == training_id)[0]?.machines;
            let common_machine_ids = training_machines?.map(mach => mach.id).filter(value => machines.includes(value));
            if(common_machine_ids?.length>0){
                let common_machines = state.machines.filter(machine => common_machine_ids.includes(machine.id));
                formik.setFieldValue('machines',common_machines.map((machine) => ({name:machine.name,id:machine.id})));
                setTimeout(() => formik.setFieldTouched('machines',common_machines.map((machine) => ({name:machine.name,id:machine.id}))));
                let machines_ids = common_machines.map(mach => mach.id);
                let contacts = state.company_contacts.filter(contact => contact.company_id == company_id && machines_ids.every(obj => contact.machines?.map(machin => machin.id).includes(obj)))?.map(contact => contact.lecturer_id);
                setLecturers(state.lecturers.filter(lecturer => contacts.includes(lecturer.id)));
            }
        }
        
    }

    function changeCompany(company_id){
        formik.setFieldValue('company_id',company_id);
        const machines = [];
        state.company_contacts.filter(contact => contact.company_id == company_id)?.forEach(contact => {
            contact?.machines?.map(machine => machines.push(machine.id));
        });
        setMachines(state.machines.filter(machine => machines.includes(machine.id)));
        formik.setFieldValue('machines',[]);
        formik.setFieldValue('lecturer_id','');
        formik.setFieldValue('company_contact_id','');
        formik.setFieldValue("employees",[]);
      }

      function changeMachine(machines){
        formik.setFieldValue("machines",machines);
        let machines_ids = machines.map(mach => mach.id);
        let contacts = state.company_contacts.filter(contact => contact.company_id == formik.values?.company_id && machines_ids.every(obj => contact.machines?.map(machin => machin.id).includes(obj)))?.map(contact => contact.lecturer_id);
        formik.setFieldValue('lecturer_id','');
        formik.setFieldValue('company_contact_id','');
        formik.setFieldValue("employees",[]);
        setTimeout(() => formik.setFieldTouched('machines',machines));
        setTimeout(() => setLecturers(state.lecturers.filter(lecturer => contacts.includes(lecturer.id))));
      }
        

      function changeLecturer(lecturer_id){
        formik.setFieldValue('lecturer_id',lecturer_id);
        setCompanyContacts(state.company_contacts.filter(contact => contact.company_id == formik.values?.company_id && formik.values?.machines.map(machin => machin.id).every(obj => contact.machines?.map(machin => machin.id).includes(obj)) && contact.lecturer_id == lecturer_id));
        formik.setFieldValue('company_contact_id','');
        formik.setFieldValue("employees",[]);
      }

    function changeEmailTemplate(template_id){
        setEmailTemplate(template_id);
        if(template_id){
            let my_template = state.training_invitation_templates.filter((inv_template) => inv_template.id == template_id)[0];
            if(my_template){
                formik.setFieldValue('email_content',my_template?.content);
                emailContentRef.current.value = my_template.content;
                emailContentRef?.current?.focus();
            }
        }
        else{
            formik.setFieldValue('email_content',"");
            emailContentRef.current.value = "";
            emailContentRef?.current?.focus();
        }
    }

    function changeSmsTemplate(template_id){
        setSmsTemplate(template_id);
        if(template_id){
            let my_template = state.training_invitation_templates.filter((inv_template) => inv_template.id == template_id)[0];
            if(my_template){
                formik.setFieldValue('sms_content',my_template?.content);
                smsContentRef.current.value = my_template.content;
                smsContentRef?.current?.focus();
            }
        }
        else{
            formik.setFieldValue('sms_content',"");
            smsContentRef.current.value = "";
            smsContentRef?.current?.focus();
        }
    }

    return ( 
        <>
        <Toast ref={ownToast}/>
            <div>
            <div className='w-full h-10 flex flex-row bg-[#333]'>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {setCloseTab(true);submitRef.current.click();}}><SaveAndCloseIcon/></button></div>
            <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} type='submit' onClick={() => {submitRef.current.click();}}><SaveIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => addNewInvitation()}><AddIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" disabled={insertCopyIcon} onClick={() => copyInvitation()}><CopyIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button className="disabled:opacity-25" onClick={() => openFavoriteDialog("Nová pozvánka","TrainingInvitationAdd")}><FavoriteIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><button onClick={() => setFastView("TrainingInvitationAdd")}><PriceIcon/></button></div>
          <div className='flex flex-col w-10 h-10 px-2 py-1 justify-center '><VerticalLineIcon/></div>
        </div>
        <div className="relative flex flex-col overflow-y-auto h-[calc(100vh-177px)] bg-gray-100">
          <div className='xl:grid grid-cols-2 gap-1 mb-2 '>
            <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <h1 className="text-3xl font-semibold text-left text-zinc-900">
                   NOVÁ POZVÁNKA
                </h1>
                <form onSubmit={formik.handleSubmit}>
                <div className="md:grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="name"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Názov
                        </label>
                        <input
                            name='name'
                            type="text" required
                            onChange={formik.handleChange} value={formik.values.name}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="invitation_date"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Dátum 
                        </label>
                        <input
                            name='invitation_date'
                            type="date"
                            onChange={formik.handleChange} value={formik.values.invitation_date}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                        <div>
                        <label
                            htmlFor="invitation_time"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Čas
                        </label>
                        <input
                            name='invitation_time'
                            type="time" required
                            onChange={formik.handleChange} value={formik.values.invitation_time}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                        <div>
                        <label
                            htmlFor="location"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Miesto 
                        </label>
                        <input
                            name='location'
                            type="text"
                            onChange={formik.handleChange} value={formik.values.location}
                            className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                        </div>
                    </div>
                    <div className="md:grid grid-cols-2 gap-4 mb-2">
                            <div>
                        <label
                            htmlFor="trainingDepartment"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Oddelenie školenia
                        </label>
                        <select 
                        required
                        name='trainingDepartment'
                        onChange={(e) => filterTrainings(e.target.value)} value={trainingDepartment}
                        className="bg-white block w-full  px-4 py-2 mt-2 text-grey-700 border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40">
                        <option value="">Vyber oddelenie</option>
                        {state.departments.map((department) => (
                            <option key={department.id} value={department.id}>{department.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor="training_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Názov školenia
                        </label>
                        <select required
                        name='training_id'
                        onChange={(e) => changeTraining(e.target.value)} value={formik.values.training_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber školenie</option>
                        {trainings.map((training) => (
                            <option key={training.id} value={training.id}>{training.name}</option>
                        ))}
                        </select>
                    </div>
                    </div>
                    <div>
                        <label
                            htmlFor="company_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Škol. firma
                        </label>
                        <select required
                        name='company_id'
                        onChange={(e) => changeCompany(e.target.value)} value={formik.values?.company_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber škol.firmu</option>
                        {state.training_companies.map((training_company) => (
                            <option key={training_company.id} value={training_company.id}>{training_company.name}</option>
                        ))}
                        </select>
                    </div> 
                    {(formik.values?.company_id!="" && formik.values?.company_id!=null) && <><div>
                        <label
                            htmlFor="machines"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Stroj*
                        </label>
                        <MultiSelect value={formik.values.machines} onChange={(e) => changeMachine(e.value)}
                            options={machines.map((machine) => 
                                ({name:machine.name,id:machine.id})
                            )} 
                            optionLabel="name"
                            filter placeholder="Vyber stroj/e" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        {formik.errors?.machines ? <span style={{color: "red"}}>{formik.errors?.machines}</span> : ""}
                    </div>
                    {formik.values?.machines?.length > 0 && <><div>
                        <label
                            htmlFor="lecturer_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Lektor
                        </label>
                        <select required
                        name='lecturer_id'
                        onChange={(e) => changeLecturer(e.target.value)} value={formik.values?.lecturer_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber lektora</option>
                        {lecturers.map((lecturer) => (
                            <option key={lecturer.id} value={lecturer.id}>{lecturer.name}</option>
                        ))}
                        </select>
                    </div>
                    {(formik.values?.lecturer_id!="" && formik.values?.lecturer_id!=null) && <><div>
                        <label
                            htmlFor="company_contact_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                        Školiteľ
                        </label>
                        <select required
                        name='company_contact_id'
                        onChange={formik.handleChange} value={formik.values?.company_contact_id}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                        <option value="">Vyber školiteľa</option>
                        {companyContacts.map((contact) => (
                            <option key={contact.id} value={contact.id}>{contact?.employee?.name || contact?.name}</option>
                        ))}
                        </select>
                        {formik.errors.company_contact_id ? <div className="whitespace-pre-line" style={{color:'red'}}>{formik.errors.company_contact_id}</div> : null}
                    </div>
                    {(formik.values?.company_contact_id!="" && formik.values?.company_contact_id!=null) &&<div>
                        <div className="flex justify-content-center mt-2">
                            <label className='block text-md font-semibold text-gray-800 mr-2'>Poslať notifikáciu školiteľovi:</label>
                            <InputSwitch checked={formik.values.contact_notify} onChange={(e) => formik.setFieldValue("contact_notify",e.value)} />
                        </div>
                        <label
                            for="employees"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Zamestnanci
                        </label>
                        <MultiSelect required value={formik.values.employees} onChange={(e) => formik.setFieldValue("employees",e.value)} 
                        options={state.training_cards.filter(card => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)?.length > 0)
                            .filter(card => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.company_id == formik.values?.company_id && formik.values?.machines.map(machin => machin.id).every(obj => card.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.machines.map(machin => machin.id).includes(obj)))
                            .filter(card => (formik.values?.lecturer_id == card?.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.lecturer_id || formik.values?.lecturer_id == card?.training_card.filter(tr_card => tr_card.training_id == formik.values?.training_id)[0]?.company_contact?.lecturer_id))
                            .filter(card => card?.date_of_departure=="" || card?.date_of_departure==null)
                            .map((employee) => 
                            ({name:employee.name,id:employee.id})
                        )} 
                        optionLabel="name" 
                        filter placeholder="Vyber zamestnancov" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        {formik.errors.employees ? <div className="whitespace-pre-line" style={{color:'red'}}>{formik.errors.employees}</div> : null}
                        <div>
                            <label className="block text-md my-2 font-semibold text-gray-800">POČET: {formik.values.employees.length}</label>
                        </div>
                    </div>}</>}</>} </>}
                    <div className="flex justify-content-center mb-2 mt-2">
                        <label className='block text-md font-semibold text-gray-800 mr-2'>Email:</label>
                        <InputSwitch checked={formik.values.email} onChange={(e) => formik.setFieldValue("email",e.value)} />
                    </div>
                    {formik.values.email && <><div>
                        <label
                            htmlFor="email_template_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Vzor pozvánky pre email
                        </label>
                        <select 
                        name='email_template_id'
                        onChange={(e) => changeEmailTemplate(e.target.value)} value={emailTemplate}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Bez vzoru</option>
                          {state.training_invitation_templates.map((template) => (
                            <option key={template.id} value={template.id}>{template.name}</option>
                          ))}
                        </select>
                        </div>
                        <div className='mb-2'>
                        <label
                            htmlFor="email_content"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Obsah pozvánky pre email
                        </label>
                        <div className='w-full h-12 flex flex-row justify-start gap-1 px-1 py-1 text-white'>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("email","[Dátum]")}><InviteDate/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("email","[Čas]")}><InviteTime/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("email","[Miesto]")}><InviteLocation/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("email","[Názov školenia]")}><TrainingTheme/></button></div>
                        </div>
                        <InputTextarea ref={emailContentRef} autoResize name='email_content' required onChange={formik.handleChange} value={formik.values.email_content} rows={5} cols={30} className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                    </>}
                    <div className="flex justify-content-center">
                        <label className='block text-md font-semibold text-gray-800 mr-2'>SMS:</label>
                        <InputSwitch checked={formik.values.sms} onChange={(e) => formik.setFieldValue("sms",e.value)} />
                    </div>
                    {formik.values.sms && <><div>
                        <label
                            htmlFor="email_template_id"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Vzor pozvánky pre SMS:
                        </label>
                        <select 
                        name='sms_template_id'
                        onChange={(e) => changeSmsTemplate(e.target.value)} value={smsTemplate}
                        className='block w-full px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40'>
                          <option value="">Bez vzoru</option>
                          {state.training_invitation_templates.map((template) => (
                            <option key={template.id} value={template.id}>{template.name}</option>
                          ))}
                        </select>
                        </div>
                        <div className='mb-2'>
                        <label
                            htmlFor="sms_content"
                            className="block text-md font-semibold text-gray-800"
                        >
                            Obsah pozvánky pre SMS
                        </label>
                        <div className='w-full h-12 flex flex-row justify-start gap-1 px-1 py-1 text-white'>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("sms","[Dátum]")}><InviteDate/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("sms","[Čas]")}><InviteTime/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("sms","[Miesto]")}><InviteLocation/></button></div>
                            <div><button type="button" className='px-2 py-2 rounded-md bg-[#333] hover:bg-gray-600' onClick={() => insertMyText("sms","[Názov školenia]")}><TrainingTheme/></button></div>
                        </div>
                        <InputTextarea ref={smsContentRef} autoResize name='sms_content' required onChange={formik.handleChange} value={formik.values.sms_content} rows={5} cols={30} className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                    </>}
                        <div className='mb-4'>
                            <label className='block text-md font-semibold text-gray-800 mr-2'>Odoslanie upozornenia:</label>
                            <MultiSelect required value={formik.values.notifications} onChange={(e) => formik.setFieldValue("notifications",e.value)} options={dayTimePeriod} optionLabel="name"
                            filter placeholder="Vyber pravidelnosť" className="w-full px-4 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
                        </div>
                        <div>
                            <button type='button' onClick={() => previewInvitation()} className='flex justify-center items-center mt-4 w-full h-10 font-medium mb-2 px-2 py-1 tracking-wide text-white transition-colors duration-200 transform bg-zinc-900 rounded-md hover:bg-zinc-700 focus:outline-none focus:bg-zinc-600 disabled:opacity-25'>Zobraziť náhľad</button>
                        </div>
                    
                    <button type='submit' ref={submitRef}></button>
                    </form>
            </div>
            {showInvitationPreview && <div className="w-auto p-6 m-4 bg-white rounded-md shadow-md">
                <label className='block text-md font-semibold text-gray-800 mr-2'>Náhľad pozvánky:</label>
                <InputTextarea autoResize name='content' required value={procesedText} rows={5} cols={30} className="block w-full  px-4 py-2 mt-2 text-grey-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40"/>
            </div>}
            </div>
            </div>
            </div>
            </> 
            );

}